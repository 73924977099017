import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateGroupMutation($groupId: ID!, $name: String!) {
    updateGroup(groupId: $groupId, name: $name) {
      ok
      error
    }
  }
`;

export default ({ groupId, name }, done) => {
  const variables = {
    groupId,
    name
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateGroup }) => {
        const { ok, error, id } = updateGroup;
        done(ok, error, id);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
