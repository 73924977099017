import { defaultTo, isNil } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation AddGroupMemberMutation($groupId: ID!, $memberId: ID!) {
    addGroupMember(groupId: $groupId, memberId: $memberId) {
      ok
      error
      newMember {
        userId
      }
    }
  }
`;

export default ({ groupId, memberId }, done) => {
  const variables = {
    groupId,
    memberId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { addGroupMember: { ok, error } }) => {
        if (ok && !error) {
          const rootField = proxyStore.getRootField('addGroupMember');
          const group = proxyStore.get(groupId);

          if (!isNil(group)) {
            let members = group.getLinkedRecords('members');
            members = defaultTo([], members);

            members.push(rootField.getLinkedRecord('newMember'));

            group.setLinkedRecords(members, 'members');
          }
        }
      },
      onCompleted: ({ addGroupMember }) => basicMutationOnCompleted(addGroupMember, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
