import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QGroupsQuery = graphql`
  query QGroupsQuery {
    groups {
      id
      members {
        userId
      }
      name
      dates {
        creation
        lastUpdate
      }
      roles {
        itemId
        itemType
        roles
      }
    }
  }
`;

const QGroups = ({
  children,
  args,
  childDataProp
}) => (
  <Query
    query={QGroupsQuery}
    args={args}
    caching={false}
  >
    {populateChildren('groups')(children, childDataProp)}
  </Query>
);

export default QGroups;
