import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation RemoveAllByTeamIdMutation {
    removeAllByTeamId {
      ok
      error
    }
  }
`;

export default (_, done) => {
  commitMutation(
    environment(),
    {
      mutation,
      variables: {},
      onCompleted: ({ removeAllByTeamId }) => basicMutationOnCompleted(removeAllByTeamId, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
