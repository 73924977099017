import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QWidgetQuery = graphql`
  query QWidgetQuery($widgetId: ID!) {
    widget(widgetId: $widgetId) {
      id
      teamId
      name
      nbColsGrid
      heightRowsGrid
      contents
      scope
      offerId
      isUsed
      isUsedBy {
        id
        name
      }
      version {
        versionId
        status
        itemScope
        itemType
        itemId
        major
        minor
        reviewComments {
          comments
          rejectionReason
          status
        }
      }
      orientation
      frameStyle {
        backgroundColor
        borderColor {
          top
          left
          right
          bottom
        }
        borderWidth {
          top
          left
          right
          bottom
        }
        borderStyle {
          top
          left
          right
          bottom
        }
        borderRadius {
          topLeft
          topRight
          bottomLeft
          bottomRight
        }
      }
    }
  }
`;

const QWidget = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QWidgetQuery}
    caching={false}
  >
    {populateChildren('widget')(children, childDataProp)}
  </Query>
);

export default QWidget;
