import React, { useState } from 'react';
import { PlusCircleOutlined, SlidersOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, InputNumber, Select, Switch, Tabs, Tooltip } from 'antd';
import { add, all, always, and, anyPass, compose, cond, dec, dropWhile, equals, filter, gt, inc, join, length, lte, map, or, path, pathOr, prop, propEq, propOr, reduce, replace, subtract, T, takeWhile } from 'ramda';
import { isNotEmpty, isTrue, notEqual } from 'ramda-adjunct';
import { useDocumentContext } from '../../../../context/Document';
import { BASE_PADDING_DOCUMENT } from '../../../../_CONST';
import { getPagesHeight, getPageWidth, HEIGHT_MAX_A4_WITHOUT_MARGINS } from '../helpers/pages';
import { editorWidth, WIDTH_A4_WITHOUT_MARGINS, WIDTH_LANDSCAPE_WITHOUT_MARGINS } from '../helpers/widget';
import DocumentRules from '../rules/DocumentRules';
import { mapIndexed } from '../../../../utils/mapIndexed';
import { successNotification } from '../../../../utils/messageMutation';
import DocumentContents from './DocumentContents';
import PaddingInfo from './documentFormTabs/PaddingInfo';
import DocumentAlerts from './DocumentAlerts';

const DocumentSections = (props) => {
  const { activeKey, setActiveKey, userIsAdmin } = props;
  const {
    addWidget,
    addRowWidget,
    removeWidget,
    updateWidget,
    addWidgetRule,
    removeWidgetRule,
    updateWidgetRule,
    getRulesAreValid,
    getConditionsAreValid,
    moveRowWidget,
    editSections,
    addManualBreakPageRule,
    removeManualBreakPageRule,
    updateManualBreakPageRule,
    setIsOpenCreateWidgetModal,
    updatePaddingSection,
    updateBreakPage,
    values,
    widgets,
    globalVariables,
    addRuleSection,
    updateRuleSection,
    removeRuleSection,
    setIsOpenCreateGlobalVariableModal
  } = useDocumentContext();
  const [showSectionOptions, setShowSectionOptions] = useState(false);
  const [showRulesSection, setShowRulesSection] = useState(true);
  const [newSectionIndexAfterSwap, setNewSectionIndexAfterSwap] = useState(1);
  const [selectedValue, setSelectedValue] = useState('');
  const [isRemoveSection, setIsRemoveSection] = useState(false);
  const [sectionIndexToRemove, setSectionIndexToRemove] = useState('');

  const globalVariablesForRules = map((v) => ({ type: prop('type', v), key: prop('name', v) }), globalVariables);
  const paddingDocument = propOr(BASE_PADDING_DOCUMENT, 'padding', values);
  const pageHeightWithMargins = subtract(
    HEIGHT_MAX_A4_WITHOUT_MARGINS,
    add(prop('top', paddingDocument), prop('bottom', paddingDocument))
  );

  const pages = propOr([], 'pages', values);

  const handleSection = (idx, action, oldIndex) => {
    if (action === 'remove') {
      setSectionIndexToRemove(idx);
      setIsRemoveSection(!isRemoveSection);
    } else {
      if (action === 'add') setActiveKey(`${length(pages)}`);
      editSections(idx, action, oldIndex);
    }
  };

  return (
    <div>
      <Tabs
        type="editable-card"
        onEdit={handleSection}
        className="tabs-doc-pages"
        addIcon={<PlusCircleOutlined />}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        {mapIndexed((section, sectionIndex) => {
          const { Option } = Select;
          const paddingSection = pathOr(BASE_PADDING_DOCUMENT, ['pages', sectionIndex, 'padding'], values);

          const widgetsHeader = pathOr([], ['pages', sectionIndex, 'header'], values);
          const widgetsSection = pathOr([], ['pages', sectionIndex, 'widgets'], values);
          const widgetsFooter = pathOr([], ['pages', sectionIndex, 'footer'], values);
          const firstPage = takeWhile(propEq('breakPage', false))(widgetsSection);
          const nextPagesWidgets = dropWhile(propEq('breakPage', false))(widgetsSection);

          const nextPages = reduce(
            (acc, b) => {
              const newIndex = cond([
                [equals(true), always(acc.length)],
                [() => equals(acc.length, 0), always(0)],
                [T, always(acc.length - 1)]
              ])(prop('breakPage', b));
              if (prop('breakPage', b) || !acc[newIndex]) acc[newIndex] = [];
              acc[newIndex].push(b);
              return acc;
            },
            [],
            nextPagesWidgets
          );
          const pagesOnSection = [firstPage, ...nextPages];
          const pagesHeight = getPagesHeight(
            widgetsHeader,
            pagesOnSection,
            widgetsFooter,
            widgets,
            paddingSection
          );

          const underMaxPageHeight = map(gt(pageHeightWithMargins))(
            pagesHeight
          );
          const isUnderMaxPageHeight = all(equals(true))(underMaxPageHeight);
          const pagesNumberGoesBeyonds = compose(filter(lte(0)), (v) =>
            v.map((e, i) => (equals(false, e) ? i + 1 : undefined)))(underMaxPageHeight);

          const pageWidth = getPageWidth(widgetsSection, widgets, paddingSection);
          const pageWidthWithMargins = subtract(
            editorWidth(propOr('portrait', 'orientation', values)),
            add(prop('right', paddingDocument), prop('left', paddingDocument))
          );

          const documentWidthByOrientation = equals(propOr('portrait', 'orientation', values), 'portrait') ? WIDTH_A4_WITHOUT_MARGINS : WIDTH_LANDSCAPE_WITHOUT_MARGINS;
          const widthRowGoesBeyondsPage = gt(pageWidth, documentWidthByOrientation) || gt(pageWidthWithMargins, documentWidthByOrientation);

          const hasBreakPage = path(['pages', sectionIndex, 'breakPage'], values);
          const canRemoveSection = and(userIsAdmin, or(
            notEqual(sectionIndex, 0),
            gt(pages.length, 1)
          ));
          const showWarningBeyondsPage = or(
            and(!isUnderMaxPageHeight, !hasBreakPage),
            isTrue(widthRowGoesBeyondsPage)
          );
          const textPagesGoesBeyonds = compose(
            replace(/, ([^,]*)$/, ' et $1'),
            join(', ')
          )(pagesNumberGoesBeyonds);

          const isMovingOrSwapingSection = or(equals(selectedValue, 'moveSectionBefore'), equals(selectedValue, 'swapSections'));

          const editSection = (sectionIndex) => {
            const addOrRemoveSection = (sectionIndex, selectedValue) => {
              editSections(sectionIndex, selectedValue);
              setShowSectionOptions(false);
            };
            const newSection = newSectionIndexAfterSwap - 1;

            cond([
              [anyPass([equals('addSectionAfter'), equals('addSectionBefore')]), () => addOrRemoveSection(sectionIndex, selectedValue)],
              [equals('duplicateSection'), () => editSections(sectionIndex, 'duplicateSection')],
              [equals('swapSections'), () => editSections(sectionIndex, 'swapSections', newSection)],
              [equals('moveSectionBefore'), () => editSections(sectionIndex, 'moveSectionBefore', newSection)]
            ])(selectedValue);

            const currentSectionIndex = sectionIndex + 1;
            const message = cond([
              [equals('addSectionBefore'), always(`Une section a été ajoutée avant la section n°${currentSectionIndex}`)],
              [equals('addSectionAfter'), always(`Une section a été ajoutée après la section n°${currentSectionIndex}`)],
              [equals('duplicateSection'), always(`La section n°${currentSectionIndex} a bien été dupliquée`)],
              [equals('swapSections'), always(`La section n°${currentSectionIndex} a bien été échangée avec la section n°${newSectionIndexAfterSwap}`)],
              [equals('moveSectionBefore'), always(`La section n°${currentSectionIndex} a bien été déplacée avant la section n°${newSectionIndexAfterSwap}`)]
            ])(selectedValue);

            successNotification(message);
            setSelectedValue('');
          };

          const rulesSection = path(['pages', sectionIndex, 'rules'], values);

          return (
            <Tabs.TabPane
              key={sectionIndex}
              className={`section-${sectionIndex}`}
              tab={(
                <span className="text-flexibranche-blue font-semibold text-lg">
                  {`Section ${sectionIndex + 1}`}
                </span>
              )}
              closable={canRemoveSection}
            >
              <Tooltip title="Options de la section" color="#0197DC">
                <button
                  className={`show-section-options-${sectionIndex} float-right text-flexibranche-lightblue font-semibold mr-2 mt-[-1.2rem]`}
                  onClick={() => setShowSectionOptions(!showSectionOptions)}
                >
                  {showSectionOptions ? '-' : '+'} Options
                </button>
              </Tooltip>

              {(isRemoveSection) && (
                <Alert
                  type="error"
                  showIcon
                  message={`Suppression de la section ${inc(sectionIndexToRemove)}`}
                  description={(
                    <div>
                      Êtes-vous sûr de vouloir la supprimer ?
                      <div className="inline-flex space-x-2 mt-1 ml-3">
                        <Button
                          type="primary"
                          onClick={() => {
                            setSectionIndexToRemove('');
                            editSections(sectionIndexToRemove, 'remove');
                            const updatedKey = equals('0', sectionIndexToRemove) ? sectionIndexToRemove : dec(sectionIndexToRemove);
                            setActiveKey(`${updatedKey}`);
                            setIsRemoveSection(false);
                          }}
                          className="float-right"
                        >
                          Supprimer
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => {
                            setSectionIndexToRemove('');
                            setIsRemoveSection(false);
                          }}
                          className="float-right mr-2"
                        >
                          Annuler
                        </Button>
                      </div>
                    </div>
                  )}
                />
              )}

              {showSectionOptions && (
                <div className="flex flex-col">
                  <div className="inline-flex mb-4 space-x-4">
                    <Tooltip title="Modifications de la section" color="#0197DC">
                      <Select
                        placeholder="Sélectionnez une action"
                        className={`w-2/3 select-options-section-${sectionIndex}`}
                        onChange={setSelectedValue}
                        value={selectedValue}
                        size="small"
                      >
                        <Option value="swapSections">Échanger avec la section</Option>
                        <Option value="moveSectionBefore">Déplacer avant la section</Option>
                        <Option value="duplicateSection">Dupliquer cette section</Option>
                        <Option value="addSectionBefore">Ajouter une section avant</Option>
                        <Option value="addSectionAfter">Ajouter une section après</Option>
                      </Select>
                    </Tooltip>

                    {isMovingOrSwapingSection && (
                      <Tooltip title="Section n°" color="#0197DC">
                        <InputNumber
                          className="section-opt-input-nb" min={1} max={pages.length}
                          size="small" defaultValue={1} onChange={setNewSectionIndexAfterSwap}
                        />
                      </Tooltip>
                    )}

                    <Button
                      onClick={() => editSection(sectionIndex)}
                      size="small"
                      className={`btn-options-section-${sectionIndex}`}
                    >
                      Ok
                    </Button>
                  </div>
                  <div className={`mb-2 flex ${isNotEmpty(rulesSection) ? 'items-start' : 'items-center'}`}>
                    <Badge
                      dot={isNotEmpty(rulesSection)}
                      color={isNotEmpty(rulesSection) ? '#0197DC' : undefined}
                      className="!mr-2"
                    >
                      <SlidersOutlined
                        rotate={90}
                        onClick={() => isNotEmpty(rulesSection) && setShowRulesSection(!showRulesSection)}
                      />
                    </Badge>
                    {showRulesSection ? (
                      <DocumentRules
                        variables={globalVariablesForRules}
                        rules={rulesSection}
                        rulesAreValid={getRulesAreValid(rulesSection)}
                        getConditionsAreValid={getConditionsAreValid}
                        onAdd={() => addRuleSection(sectionIndex)}
                        onRemove={removeRuleSection(sectionIndex)}
                        onUpdate={updateRuleSection(sectionIndex)}
                        classNameButton="!mt-0"
                        userIsAdmin={userIsAdmin}
                        setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
                      />
                    ) : <p className="italic">{'L\'affichage de la section est conditionnée'}</p>}
                  </div>
                  <div className="mb-2">
                    <PaddingInfo
                      updatePadding={updatePaddingSection(sectionIndex)}
                      element={section}
                    />
                  </div>
                  <div className="mb-2">
                    <Switch
                      size="small"
                      checked={hasBreakPage}
                      onChange={(v) => updateBreakPage(sectionIndex, v)}
                    />
                    <span
                      className="inline-block align-middle ml-2 cursor-pointer"
                      onClick={() => updateBreakPage(sectionIndex, !hasBreakPage)}
                    >
                      Saut de page automatique
                    </span>
                  </div>
                </div>
              )}

              <div className="mb-2">
                {showWarningBeyondsPage && (
                  <DocumentAlerts
                    pagesNumberGoesBeyonds={pagesNumberGoesBeyonds}
                    textPagesGoesBeyonds={textPagesGoesBeyonds}
                    widthRowGoesBeyondsPage={widthRowGoesBeyondsPage}
                    hasBreakPage={hasBreakPage}
                  />
                )}
              </div>
              <DocumentContents
                title="En-tête"
                type="header"
                content={widgetsHeader}
                sectionIndex={sectionIndex}
                hasSectionBreakPage={hasBreakPage}
                addWidget={addWidget}
                addRowWidget={addRowWidget}
                removeWidget={removeWidget}
                updateWidget={updateWidget}
                addWidgetRule={addWidgetRule}
                removeWidgetRule={removeWidgetRule}
                updateWidgetRule={updateWidgetRule}
                getRulesAreValid={getRulesAreValid}
                getConditionsAreValid={getConditionsAreValid}
                moveRowWidget={moveRowWidget}
                addManualBreakPageRule={addManualBreakPageRule}
                removeManualBreakPageRule={removeManualBreakPageRule}
                updateManualBreakPageRule={updateManualBreakPageRule}
                setIsOpenCreateWidgetModal={setIsOpenCreateWidgetModal}
                {...props}
              />
              <DocumentContents
                title="Contenu"
                type="widgets"
                content={widgetsSection}
                sectionIndex={sectionIndex}
                hasSectionBreakPage={hasBreakPage}
                addWidget={addWidget}
                addRowWidget={addRowWidget}
                removeWidget={removeWidget}
                updateWidget={updateWidget}
                addWidgetRule={addWidgetRule}
                removeWidgetRule={removeWidgetRule}
                updateWidgetRule={updateWidgetRule}
                getRulesAreValid={getRulesAreValid}
                getConditionsAreValid={getConditionsAreValid}
                moveRowWidget={moveRowWidget}
                addManualBreakPageRule={addManualBreakPageRule}
                removeManualBreakPageRule={removeManualBreakPageRule}
                updateManualBreakPageRule={updateManualBreakPageRule}
                setIsOpenCreateWidgetModal={setIsOpenCreateWidgetModal}
                {...props}
              />
              <DocumentContents
                title="Pied de page"
                type="footer"
                content={widgetsFooter}
                sectionIndex={sectionIndex}
                hasSectionBreakPage={hasBreakPage}
                addWidget={addWidget}
                addRowWidget={addRowWidget}
                removeWidget={removeWidget}
                updateWidget={updateWidget}
                addWidgetRule={addWidgetRule}
                removeWidgetRule={removeWidgetRule}
                updateWidgetRule={updateWidgetRule}
                getRulesAreValid={getRulesAreValid}
                getConditionsAreValid={getConditionsAreValid}
                moveRowWidget={moveRowWidget}
                addManualBreakPageRule={addManualBreakPageRule}
                removeManualBreakPageRule={removeManualBreakPageRule}
                updateManualBreakPageRule={updateManualBreakPageRule}
                setIsOpenCreateWidgetModal={setIsOpenCreateWidgetModal}
                {...props}
              />
            </Tabs.TabPane>
          );
        })(pages)}
      </Tabs>
    </div>
  );
};

export default DocumentSections;
