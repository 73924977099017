import validator from 'validator';
import { isNil, is, propOr } from 'ramda';

const emailIsValid = (email) => {
  if (isNil(email)) return false;
  if (is(Object, email)) email = propOr('', 'email', email);
  return validator.isEmail(email);
};

const passwordIsValid = (password) => {
  if (isNil(password)) return false;
  if (is(Object, password)) password = propOr('', 'password', password);

  const tests = [];

  const hasRequiredLength = /^.{8,99}$/i;
  const hasAtLeastACharacter = /[a-z]+/i;
  const hasAtLeastANumber = /[0-9]+/;
  const hasAtLeastASymbol = /[-+=_)(*&^%$#@!~/?.>,<|\\]+/;
  tests.push(hasRequiredLength);
  tests.push(hasAtLeastACharacter);
  tests.push(hasAtLeastANumber);
  tests.push(hasAtLeastASymbol);
  return tests.reduce((sum, regex) => sum && regex.test(password), true);
};

export {
  emailIsValid,
  passwordIsValid
};
