import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { isNil } from 'ramda';

const ActionButton = ({
  icon,
  children,
  confirmText,
  onClick,
  withConfirm = false,
  style = {}
}) => {
  if (!withConfirm) {
    return (
      <span
        className="action-button"
        onClick={onClick}
        // eslint-disable-next-line no-restricted-syntax
        style={style}
      >
        {!isNil(icon) && (
          <img src={icon} alt="icon"/>
        )}
        {children}
      </span>
    );
  }

  return (
    <Popconfirm
      title={confirmText}
      onConfirm={onClick}
      okText="Oui"
      cancelText="Non"
    >
      <span
        className="action-button"
        // eslint-disable-next-line no-restricted-syntax
        style={style}
      >
        {!isNil(icon) && (
          <img src={icon} alt="icon"/>
        )}
        {children}
      </span>
    </Popconfirm>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node
  ]).isRequired,
  withConfirm: PropTypes.bool,
  style: PropTypes.object
};

export default ActionButton;
