import React from 'react';
import { withRouter,
  Switch, Route } from 'react-router-dom';
import { compose, nth, split, propOr, prop, defaultTo } from 'ramda';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import Offers from '../offer/Offers';
import CreateOffer from '../offer/CreateOffer';
import EditOffer from '../offer/EditOffer';
import CreateOfferDocument from '../docs/CreateOfferDocument';
import withUser from '../../withUser';
import CreateGroup from '../groups/CreateGroup';
import EditGroup from '../groups/EditGroup';
import CreateOfferWidget from '../docs/CreateOfferWidget';
import CreateTagDocument from '../docs/CreateOfferTag';
import CreateGlobalVariableDocument from '../docs/CreateOfferGlobalVariable';
import QOfferRights from '../../_graphql/queries/offers/QOfferRights';
import TagDocument from '../docs/TagDocument';
import EditOfferDocument from '../docs/EditOfferDocument';
import GlobalVariableDocument from '../docs/GlobalVariableDocument';
import { home, renderWithBreadcrumb, renderWithoutBreadcrumb } from '../helpers/breadCrumb';
import PreviewTagDocument from '../docs/PreviewTagDocument';
import PreviewGlobalVariableDocument from '../docs/PreviewGlobalVariableDocument';
import EditDocument from '../docs/EditDocument';
import EditWidget from '../docs/EditWidget';
import PreviewWidget from '../docs/PreviewWidget';
import WidgetHistory from '../docs/WidgetHistory';
import DocumentHistory from '../docs/DocumentHistory';
import { OfferRightsContext } from './withOfferRights';
import OfferHistory from './OfferHistory';

const offer = ['/offre', 'Mes offres'];
const create = ['/offre/creer', 'Créer une offre'];
const edit = ({ offerId }) => [`/offre/${offerId}/edition`, 'Éditer'];
const editWidget = ({ widgetId, offerId }) => [`/offre/${offerId}/widgets/${widgetId}/editer`, 'Modifier le widget'];
const createTag = ({ offerId }) => [`/offre/${offerId}/edition/tags/creer`, 'Créer un tag'];
const createWidget = ({ offerId }) => [`/offre/${offerId}/edition/widgets/creer`, 'Créer un widget'];
const previewWidget = ({ offerId, widgetId }) => [`/offre/${offerId}/widgets/${widgetId}/preview`, 'Prévisualiser un widget'];
const previewTag = ({ offerId, tagId }) => [`/offre/${offerId}/tags/${tagId}/preview`, 'Prévisualiser un tag'];
const previewGlobalVariable = ({ offerId, globalVariableId }) => [`/offre/${offerId}/tags/${globalVariableId}/preview`, 'Prévisualiser une variable globale'];
const createGlobalVariable = ({ offerId }) => [`/offre/${offerId}/edition/variables-globales/creer`, 'Créer une variable globale'];
const manageTeam = ({ offerId, memberId }) => [`/offre/${offerId}/equipe/${memberId}`, 'Gestion de l\'équipe'];
const createGroup = ({ offerId }) => [`/offre/${offerId}/groupes/creer`, 'Créer un groupe'];
const editGroup = ({ offerId, groupId }) => [`/offre/${offerId}/groupes/${groupId}`, 'Gestion du groupe'];
const history = ({ offerId }) => [`/offre/${offerId}/historique`, 'Historique de l\'offre'];
const historyWidget = ({ offerId, widgetId }) => [`/offre/${offerId}/widgets/${widgetId}/historique`, 'Historique du widget'];
const historyDocument = ({ offerId, documentId }) => [`/offre/${offerId}/documents/${documentId}/historique`, 'Historique du document'];
const editTag = ({ offerId, tagId }) => [`/offre/${offerId}/tags/${tagId}/edition`, 'Modifier le tag'];

const routes = [
  ['/offre/creer', [home, offer, create]],
  ['/offre/:offerId/edition/tags/creer', [home, offer, edit, createTag]],
  ['/offre/:offerId/edition/widgets/creer', [home, offer, edit, createWidget]],
  ['/offre/:offerId/widgets/:widgetId/editer', [home, offer, edit, editWidget]],
  ['/offre/:offerId/widgets/:widgetId/preview', [home, offer, edit, previewWidget]],
  ['/offre/:offerId/widgets/:widgetId/historique', [home, offer, edit, historyWidget]],
  ['/offre/:offerId/documents/:documentId/historique', [home, offer, edit, historyDocument]],
  ['/offre/:offerId/tags/:tagId/preview', [home, offer, edit, previewTag]],
  ['/offre/:offerId/variables-globales/:globalVariableId/preview', [home, offer, edit, previewGlobalVariable]],
  ['/offre/:offerId/edition/variables-globales/creer', [home, offer, edit, createGlobalVariable]],
  ['/offre/:offerId/edition', [home, offer, edit]],
  ['/offre/:offerId/equipe/:memberId', [home, offer, edit, manageTeam]],
  ['/offre/:offerId/groupes/creer', [home, offer, edit, createGroup]],
  ['/offre/:offerId/groupes/:groupId', [home, offer, edit, editGroup]],
  ['/offre/:offerId/historique', [home, offer, edit, history]],
  ['/offre/:offerId/tags/:tagId/edition', [home, offer, edit, editTag]],
  ['/offre', [home, offer]]
];

const Routes = ({
  match,
  location,
  userIsAdmin
}) => {
  const breadcrumb = useBreadcrumb(routes, home);

  const offerId = compose(
    nth(2),
    split('/'),
    propOr('', 'pathname')
  )(location);
  const matchUrl = prop('url', match);
  return (
    <QOfferRights args={{ offerId }}>
      {offerRights => {
        offerRights = defaultTo({}, offerRights);
        return (
          <OfferRightsContext.Provider
            value={{
              userIsAdmin,
              offerRights
            }}
          >
            <Switch>
              <Route exact path={matchUrl} component={renderWithBreadcrumb(Offers, breadcrumb)} />
              <Route path={`${matchUrl}/creer`} component={renderWithBreadcrumb(CreateOffer, breadcrumb)} />

              <Route path={`${matchUrl}/:documentId/editer`} render={renderWithoutBreadcrumb(EditDocument, breadcrumb)}/>
              <Route path={`${matchUrl}/:offerId/widgets/:widgetId/editer`} render={renderWithBreadcrumb(EditWidget, breadcrumb)}/>

              <Route path={`${matchUrl}/:offerId/groupes/creer`} component={renderWithBreadcrumb(CreateGroup, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/groupes/:groupId`} component={renderWithBreadcrumb(EditGroup, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/edition/widgets/creer`} component={renderWithBreadcrumb(CreateOfferWidget, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/widgets/:widgetId/preview`} component={renderWithBreadcrumb(PreviewWidget, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/widgets/:widgetId/historique`} component={renderWithBreadcrumb(WidgetHistory, breadcrumb)} />

              <Route path={`${matchUrl}/:offerId/edition/documents/creer`} component={renderWithoutBreadcrumb(CreateOfferDocument, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/documents/:documentId/edition`} component={renderWithoutBreadcrumb(EditOfferDocument, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/documents/:documentId/historique`} component={renderWithBreadcrumb(DocumentHistory, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/documents-importes/:documentId/edition`} component={renderWithBreadcrumb(EditOfferDocument, breadcrumb)} />

              <Route path={`${matchUrl}/:offerId/edition/tags/creer`} component={renderWithBreadcrumb(CreateTagDocument, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/tags/:tagId/edition`} component={renderWithBreadcrumb(TagDocument, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/tags/:tagId/preview`} component={renderWithBreadcrumb(PreviewTagDocument, breadcrumb)} />

              <Route path={`${matchUrl}/:offerId/edition/variables-globales/creer`} component={renderWithBreadcrumb(CreateGlobalVariableDocument, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/variables-globales/:globalVariableId/edition`} component={renderWithBreadcrumb(GlobalVariableDocument, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/variables-globales/:globalVariableId/preview`} component={renderWithBreadcrumb(PreviewGlobalVariableDocument, breadcrumb)} />

              <Route path={`${matchUrl}/:offerId/edition`} component={renderWithBreadcrumb(EditOffer, breadcrumb)} />
              <Route path={`${matchUrl}/:offerId/historique`} component={renderWithBreadcrumb(OfferHistory, breadcrumb)} />
            </Switch>
          </OfferRightsContext.Provider>
        );
      }}
    </QOfferRights>
  );
};

export default withRouter(withUser(Routes));
