import { equals, has, is } from 'ramda';
import React, { useEffect, useRef } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import Loading from '../components/Loading';

const QueryRun = ({
  children,
  query,
  queryReference
}) => {
  const data = usePreloadedQuery(query, queryReference);

  if (is(Function, children)) return children(data);
  return React.cloneElement(
    children,
    data
  );
};

const Query = ({
  query,
  args,
  children,
  fetchPolicy,
  isCompareFrame
}) => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);
  const argsRef = useRef();

  useEffect(() => {
    return disposeQuery;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCompareFrame) {
      if (equals(argsRef.current, args)) return;
      argsRef.current = args;
      loadQuery(args, { fetchPolicy });
    } else {
      loadQuery(args, { fetchPolicy });
    }
  }, [args, fetchPolicy, loadQuery, isCompareFrame]);

  if (has('refetch', args) && has('setRefetch', args) && args.refetch) {
    args.setRefetch(false);
  }

  if (queryReference === null) return null;

  return (
    <React.Suspense fallback={<Loading />}>
      <QueryRun query={query} queryReference={queryReference}>
        {children}
      </QueryRun>
    </React.Suspense>
  );
};

Query.defaultProps = { fetchPolicy: 'store-and-network' };

export default Query;
