import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="h-screen flex">
      <div className="m-auto">
        <h1>Oups...</h1>
        <p>{'la page que vous avez demandé n\'existe pas'} !</p>
        <div>
          <Link to="/">{'retour à la page d\'accueil'}</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
