import React from 'react';
import VersionHistory from './VersionHistory';
import { Section } from './';

const History = ({ id, type }) => (
  <Section title={`Historique du ${type}`} >
    <VersionHistory itemId={id} />
  </Section>
);

export default History;
