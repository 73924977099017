import { Cascader } from 'antd';
import React, { useEffect, useState } from 'react';
import { ifElse, is, last, prop, propEq, find } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { useDocumentContext } from '../../../../context/Document';
import widgetCascaderFormatter from '../../../../lib/widgetCascaderFormatter';
import ModalChangeWidgetRules from './ModalChangeWidgetRules';

const SelectWidget = ({
  updateWidget,
  rowIndex,
  index,
  widgetsList,
  widgetId,
  itemPathPrefix,
  type,
  canShowRules,
  setIsDynamicTableInSection,
  userIsAdmin
}) => {
  const [showModalWhenNeedValidationRules, setShowModalWhenNeedValidationRules] = useState(false);
  const [widgetToChangeWithRules, setWidgetToChangeWithRules] = useState(null);

  const {
    values
  } = useDocumentContext();

  const updateShowModalAndWidgetToUpdate = (v) => {
    setWidgetToChangeWithRules(v);
    setShowModalWhenNeedValidationRules(!showModalWhenNeedValidationRules);
  };

  const widget = find(propEq('id', widgetId))(widgetsList);
  useEffect(() => {
    if (isNotNilOrEmpty(widget)) {
      const regex = /"type":"dynamicTable"/gi;
      const isWidgetDynamicTable = regex.test(prop('contents', widget));

      setIsDynamicTableInSection(isWidgetDynamicTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget]);

  const currentDocumentOrientation = prop('orientation', values);
  const offerWidgets = widgetCascaderFormatter(widgetsList, currentDocumentOrientation);

  const changeWidget = (v) => ifElse(
    is(Array),
    () => updateWidget(rowIndex, index, last(v), type),
    () => updateWidget(rowIndex, index, undefined, type)
  )(v);

  const filter = (input, path) => path.some((option) => prop('label', option).toLowerCase().indexOf(input.toLowerCase()) > -1);
  return (
    <>
      <ModalChangeWidgetRules
        visible={showModalWhenNeedValidationRules}
        onHide={() => updateShowModalAndWidgetToUpdate(null)}
        onClick={() => {
          changeWidget(widgetToChangeWithRules);
          updateShowModalAndWidgetToUpdate(null);
        }}
      />
      <Cascader
        options={offerWidgets}
        placeholder="Sélectionner un widget"
        onChange={v => {
          setIsDynamicTableInSection(false);
          if (canShowRules) {
            updateShowModalAndWidgetToUpdate(v);
          } else {
            changeWidget(v);
          }
        }}
        className="!w-80 cascader-widgets"
        expandTrigger="hover"
        data-item-path={[...itemPathPrefix, rowIndex, index]}
        data-item-key="selected"
        value={widget ? prop('name', widget) : null}
        showSearch={{ filter }}
        disabled={!userIsAdmin}
      />
    </>
  );
};

export default SelectWidget;
