import React from 'react';
const { compose, propEq, propOr } = require('ramda');
const OfferRightsContext = React.createContext();

export default function withOfferRights (Component) {
  return function OfferRightsComponent (props) {
    return (
      <OfferRightsContext.Consumer>
        {(consumerProps) => {
          const userIs = prop => compose(
            propEq(prop, true),
            propOr({}, 'offerRights')
          )(consumerProps);

          const userIsAdmin = propOr(false, 'userIsAdmin', consumerProps);

          return (
            <Component
              {...props}
              userIsAdmin={userIsAdmin}
              userIsOfferValidator={userIsAdmin || userIs('userIsOfferValidator')}
            />
          );
        }}
      </OfferRightsContext.Consumer>
    );
  };
}

export {
  OfferRightsContext
};
