import React from 'react';
import { prop } from 'ramda';
import { Button } from 'antd';
import useStatusActions from '../hooks/useStatusActions';
import { Glasses, Edit } from './icons';

const StatusActions = ({ item, draftLabel, reviewLabel, userCanValidate }) => {
  const version = prop('version', item);
  const {
    canSendToDraft,
    canSendToReview,
    updateStatus,
    isLoading
  } = useStatusActions({ version });

  if (!item || !userCanValidate) {
    return null;
  }

  if (canSendToReview) {
    return (
      <Button
        size="large"
        className="block"
        type="primary"
        loading={isLoading}
        onClick={updateStatus('review')}
        icon={<Glasses />}
      >
        {reviewLabel}
      </Button>
    );
  }

  if (canSendToDraft) {
    return (
      <Button
        size="large"
        type="primary"
        loading={isLoading}
        onClick={updateStatus('draft')}
        icon={<Edit />}
      >
        {draftLabel}
      </Button>
    );
  }

  return null;
};

const WrapperStatusAction = (props) => {
  const { item, userCanValidate } = props;
  if (!item || !userCanValidate) {
    return null;
  }

  return <StatusActions {...props}/>;
};

export default WrapperStatusAction;
