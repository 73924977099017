import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input, Button, Spin } from 'antd';
import { prop, isNil } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import withUser from '../../withUser';
import { passwordIsValid } from '../../lib/validation';
import ResetPasswordMutation from '../../_graphql/mutations/ResetPasswordMutation';
import SignOutMutation from '../../_graphql/mutations/SignOutMutation';

const useNewPasswordRequested = (history, form, user) => {
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const hasErrors = () => {
    const fieldsError = form.getFieldsError();
    return Object.keys(fieldsError).some(field => {
      return fieldsError[field] || isNilOrEmpty(form.getFieldValue(field));
    });
  };

  const onLostCurrentPassword = () => {
    SignOutMutation(() => {
      history.replace('/account/resetpasswordrequest');
    });
  };
  const onUpdatePassword = (e) => {
    e.preventDefault();
    const { currentPassword, password } = form.getFieldsValue();
    setProcessing(true);
    ResetPasswordMutation({
      email: prop('email', user), currentPassword, password
    }, (ok, error) => {
      setProcessing(false);
      if (ok) return setIsDone(true);
      setError(error);
    });
  };

  const onResetForm = () => {
    setIsDone(false);
    setError(null);
  };

  return {
    hasErrors,
    onLostCurrentPassword,
    onResetForm,
    onUpdatePassword,
    passwordIsUpdated: isDone,
    processing,
    error
  };
};

const render = (content) => (
  <div className="connection-box">
    {content}
  </div>
);

const NewPasswordRequired = ({
  history,
  form,
  user
}) => {
  const {
    getFieldDecorator
  } = form;

  const {
    processing,
    onResetForm,
    onLostCurrentPassword,
    onUpdatePassword,
    passwordIsUpdated,
    error,
    hasErrors
  } = useNewPasswordRequested(history, form, user);

  if (processing) {
    return render(
      <React.Fragment>
        <Spin/>
        &nbsp;
        <span>Nous mettons à jour votre mot de passe...</span>
      </React.Fragment>

    );
  } else if (!processing && isNil(error) && !passwordIsUpdated) {
    return render(
      <React.Fragment>
        Notre politique de sécurité évolue, nous vous demandons de redéfinir votre mot de passe afin de respecter nos nouvelles règles
        <br/>
        <br/>
        <Form onSubmit={onUpdatePassword}>
          <Form.Item
            label="Votre mot de passe actuel"
          >
            {getFieldDecorator('currentPassword', {
              rules: [{
                required: true,
                message: 'Mot de passe actuel'
              }]
            })(
              <Input
                type="password"
                placeholder="Votre email"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Votre nouveau mot de passe"
          >
            {getFieldDecorator('password', {
              rules: [{
                required: true,
                message: 'Mon nouveau mot de passe',
                validator: (rule, value, callback) => {
                  if (passwordIsValid(value)) return callback();
                  // eslint-disable-next-line standard/no-callback-literal
                  callback('8 caractères dont au moins un caractère spécial et un chiffre');
                }
              }]
            })(
              <Input
                type="password"
                placeholder="Votre email"
                size="large"
              />
            )}
          </Form.Item>
          <Button
            size="large"
            htmlType="submit"
            disabled={hasErrors()}
          >
            Mettre à jour
          </Button>
          &nbsp;
          &nbsp;
          <a
            href="javascript:void(0)"
            onClick={onLostCurrentPassword}
          >
            {'J\'ai perdu mon mot de passe courant'}
          </a>
        </Form>
      </React.Fragment>
    );
  } else if (passwordIsUpdated) {
    return render(
      <React.Fragment>
        <Alert
          type="success"
          message="Votre mot de passe a bien été mis à jour"
        />
        <br/>
        <Button
          size="large"
          onClick={() => {
            window.location = '/';
          }}
        >
          Accéder au Menu
        </Button>
      </React.Fragment>
    );
  } else {
    return render(
      <React.Fragment>
        <Alert
          type="error"
          message={error === 'password_ko' ? 'Votre mot de passe n\'est pas correct' : 'Nous avons rencontré un problème'}
        />
        <br/>
        <Button
          size="large"
          onClick={onResetForm}
        >
          Recommencer
        </Button>
      </React.Fragment>
    );
  }
};

const WrappedNewPasswordRequired = Form.create({ name: 'newPasswordRequired' })(NewPasswordRequired);

export default withUser(WrappedNewPasswordRequired);

// <div className="password-hint">{'Votre mot de passe doit contenir au moins 8 caractères (dont au moins un de type lettre, de type chiffre, de type symbole)'}</div>
