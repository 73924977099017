import React from 'react';
import { useParams } from 'react-router-dom';
import { SCOPE_OFFER } from '../../_CONST';
import QGlobalVariable from '../../_graphql/queries/documents/QGlobalVariable';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';
import GlobalVariableForm from './components/globalVariable/GlobalVariableForm';

const PreviewGlobalVariableDocument = ({ userTeam }) => {
  const { offerId, globalVariableId } = useParams();
  return (
    <QGlobalVariable args={{ globalVariableId }}>
      {globalVariable => {
        const accessDenied = verifyRouteAccess(userTeam, globalVariable, 'cette variable globale');
        if (accessDenied) return accessDenied;
        return (
          <GlobalVariableForm
            scope={SCOPE_OFFER}
            offerId={offerId}
            globalVariableId={globalVariableId}
            globalVariable={globalVariable}
            preview
          />
        );
      }}
    </QGlobalVariable>
  )
  ;
};

export default withUser(PreviewGlobalVariableDocument);
