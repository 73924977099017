import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateTagMutation($tag: TagInput!) {
    createTag(tag: $tag) {
      ok
      error
    }
  }
`;

export default ({
  tag
}, done) => {
  const variables = { tag };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createTag }) => basicMutationOnCompleted(createTag, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
