import React from 'react';

const Layout = ({
  children
}) => (
  <div className="min-h-full flex">
    {children}
  </div>
);

export default Layout;
