import { equals, prop } from 'ramda';
import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import colors from '../../theme';

const StatusComparison = ({ status, tooltip }) => {
  const cls = classNames('h-3 w-3 rounded-full', {
    'bg-lime-400': equals('passed', status),
    'bg-red-600': equals('failed', status),
    'bg-orange-400': equals('waiting', status),
    'bg-gray-600': equals('error', status)
  });

  return (
    <Tooltip title={tooltip} color={prop('primaryColorLight', colors)}>
      <div className={cls} />
    </Tooltip>
  );
};

export default StatusComparison;
