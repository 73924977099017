import React, { useContext } from 'react';
import { append, prop, propOr } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { isUserAdmin } from './pages/helpers/isUserAdmin';
import SignOutMutation from './_graphql/mutations/SignOutMutation';
const UserContext = React.createContext();

export default function withUser (Component) {
  return function UserComponent (props) {
    return (
      <UserContext.Consumer>
        {({ user, userTeam, teamInvitationsCount, userRefetch, teams }) => {
          const flexigetsLib = append('flexigetDefault', propOr([], 'flexigets', userTeam));
          return (
            <Component
              {...props}
              user={user}
              userTeam={userTeam}
              flexigets={flexigetsLib}
              userIsSignedIn={isNotNil(prop('email', user))}
              userIsAdmin={isUserAdmin(user, userTeam)}
              onSignOut={() => SignOutMutation()}
              teamInvitationsCount={teamInvitationsCount}
              userRefetch={userRefetch}
              teams={teams}
            />
          );
        }}
      </UserContext.Consumer>
    );
  };
}

const useUserContext = () => {
  return useContext(UserContext);
};

export {
  UserContext,
  useUserContext
};
