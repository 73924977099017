import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { cond, always, prop, T } from 'ramda';
import { useHistory } from 'react-router-dom';
import { isTrue } from 'ramda-adjunct';
import { Collapse } from '../../../components';
import QTags from '../../../_graphql/queries/documents/QTags';
import TagsTable from '../../docs/components/tag/TagsTable';
import { deleteTag } from '../../helpers/deleteTag';
import { SCOPE_OFFER } from '../../../_CONST';

const TagsSection = ({
  offerId,
  tags,
  userCanEditOffer,
  version
}) => {
  const history = useHistory();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const baseUrlOffer = `/offre/${offerId}`;
  const onEdit = (tagId) => history.push(`${baseUrlOffer}/tags/${tagId}/edition`);
  const onPreview = (tagId) => history.push(`${baseUrlOffer}/tags/${tagId}/preview`);
  const onCreate = () => history.push(`${baseUrlOffer}/edition/tags/creer`);
  const getLink = (tag) => cond([
    [isTrue, always(`/offre/${offerId}/tags/${prop('id', tag)}/edition`)],
    [T, always(`/offre/${offerId}/tags/${prop('id', tag)}/preview`)]
  ])(userCanEditOffer);

  return (
    <Collapse
      className="section-tag"
      collapseIsOpen={collapseIsOpen}
      setCollapseIsOpen={setCollapseIsOpen}
      title="Tags de l'offre"
      actions={userCanEditOffer && (
        <Button
          onClick={onCreate}
          icon={<PlusOutlined />}
        >
          Créer un tag
        </Button>
      )}
    >
      <QTags
        args={{
          offerId,
          tags,
          scope: SCOPE_OFFER
        }}
      >
        {(tagsByRootOfferId) => {
          return (
            <TagsTable
              tags={tagsByRootOfferId}
              userCanEditOffer={userCanEditOffer}
              version={version}
              offerId={offerId}
              onClickEdit={onEdit}
              onClickPreview={onPreview}
              onClickDelete={deleteTag}
              getLink={getLink}
            />
          );
        }}
      </QTags>
    </Collapse>

  );
};

export default TagsSection;
