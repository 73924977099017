import { find, prop } from 'ramda';
import attributesNames from '../data/attributesNames';
import { getElement } from './utils';

const getComment = (element, comments) => {
  const itemPath = prop('attributes', element).getNamedItem(prop('itemPath', attributesNames));
  const itemKey = prop('attributes', element).getNamedItem(prop('itemKey', attributesNames));

  return find((comment) => getElement(itemKey, itemPath, comment))(comments);
};

export default getComment;
