import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateOfferStatusMutation($offerId: ID!, $status: String!) {
    updateOfferStatus(offerId: $offerId, status: $status) {
      ok
      error
    }
  }
`;

export default ({ offerId, status }, done) => {
  const variables = {
    offerId,
    status
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { updateOfferStatus }) => {
        const { ok, error } = updateOfferStatus;

        if (ok && !error) {
          const offer = proxyStore.get(offerId);

          const version = offer.getLinkedRecord('version');
          version.setValue(status, 'status');
          offer.setLinkedRecord(version, 'version');
        }
      },
      onCompleted: ({ updateOfferStatus }) => basicMutationOnCompleted(updateOfferStatus, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
