import React from 'react';
import { useParams } from 'react-router-dom';
import WidgetForm from './components/widget/WidgetForm';

const CreateOfferWidget = () => {
  const { offerId } = useParams();

  return (
    <WidgetForm
      scope="offer"
      offerId={offerId}
    />
  );
};

export default CreateOfferWidget;
