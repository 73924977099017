import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QTagsQuery = graphql`
  query QTagsQuery($scope: DocumentScopeEnum!, $offerId: ID, $itemType: String) {
    tags(scope: $scope, offerId: $offerId, itemType: $itemType) {
      id
      name
      itemType
      itemsIds
      color
      dates {
        creation
        lastUpdate
      }
    }
  }
`;

const QTags = ({
  children,
  childDataProp,
  args
}) => (
  <Query
    query={QTagsQuery}
    caching={false}
    args={args}
  >
    {populateChildren('tags')(children, childDataProp)}
  </Query>
);

export default QTags;
