import { find, prop } from 'ramda';
import attributesNames from '../data/attributesNames';
import getCommentsElements from './getCommentsElements';
import { getElement } from './utils';

const getCommentElement = (comment) => {
  const commentsElements = getCommentsElements();

  return find(commentElement => {
    const commentPath = prop('attributes', commentElement).getNamedItem(prop('commentPath', attributesNames));
    const commentKey = prop('attributes', commentElement).getNamedItem(prop('commentKey', attributesNames));

    return getElement(commentKey, commentPath, comment);
  })(commentsElements);
};

export default getCommentElement;
