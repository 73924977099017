import React from 'react';

const Sider = ({
  children
}) => (
  <div className="min-h-full">
    {children}
  </div>
);

export default Sider;
