import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { Link } from 'react-router-dom';
import { prop, assoc, isEmpty } from 'ramda';
import SignInMutation from '../../_graphql/mutations/SignInMutation';
import AccountLayout from '../../layout/account/AccountLayout';
import { emailIsValid, passwordIsValid } from '../../lib/validation';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import EmailPasswordForm from './components/EmailPasswordForm';

const useSignIn = () => {
  const [values, setValues] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const setValue = (k, v) => setValues(assoc(k, v));

  const fieldIsDefined = k => !isEmpty(prop(k, values));

  const onSignIn = () => {
    setLoading(true);
    SignInMutation(prop('email', values), prop('password', values), (ok, error) => {
      if (!ok || error) {
        setLoading(false);
        notification.error({ message: 'La connexion a échoué, veuillez vérifier vos identifiants.' });
      }
    });
  };

  return {
    emailIsValid: emailIsValid(prop('email', values)),
    passwordIsValid: passwordIsValid(prop('password', values)),
    fieldIsDefined,
    loading,
    setValue,
    onSignIn
  };
};

const SignIn = () => {
  const {
    emailIsValid,
    passwordIsValid,
    loading,
    fieldIsDefined,
    setValue,
    onSignIn
  } = useSignIn();

  const buttonDisabled = !passwordIsValid || !emailIsValid;
  useKeyPressEnter(onSignIn, !buttonDisabled, { emailIsValid, passwordIsValid });

  return (
    <AccountLayout>
      <h3>Connexion</h3>

      <EmailPasswordForm
        setValue={setValue} fieldIsDefined={fieldIsDefined} emailIsValid={emailIsValid}
        passwordIsValid={passwordIsValid}
      />

      <div className="account-extra text-xs my-0">
        <Link to="/account/resetpasswordrequest">Mot de passe oublié</Link>
      </div>

      <br/>
      <br/>

      <Button
        type="primary"
        size="large"
        disabled={buttonDisabled}
        loading={loading}
        onClick={onSignIn}
      >
        Se connecter
      </Button>
    </AccountLayout>
  );
};

export default SignIn;
