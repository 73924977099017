import Url from 'url';
import fetch from 'isomorphic-fetch';
import { compose, assoc, prop, path, not, isEmpty } from 'ramda';

const Tokens = (() => {
  let vars = {
    clientId: null,
    key: null,
    tokens: {},
    useToken: true
  };

  const buildUrl = (pathname, query) => {
    return Url.format({ pathname, query });
  };

  const setKey = (clientId, key) => {
    vars = compose(
      assoc('key', key),
      assoc('clientId', clientId)
    )(vars);
  };

  const getAccessToken = async (refresh = false) => {
    if (refresh) return getAccessTokenRefresh();

    if (prop('useToken', vars) === false) {
      return Promise.resolve('');
    }

    if (compose(not, isEmpty, prop('tokens'))(vars)) return Promise.resolve(path(['tokens', 'access_token'], vars));

    const response = await fetch(
      buildUrl('/u/token'),
      {
        method: 'POST',
        body: JSON.stringify({ grant_type: 'client_credentials' }),
        headers: {
          Accept: 'application/json',
          Authorization: 'Basic ' + btoa(`${prop('clientId', vars)}:${prop('key', vars)}`),
          'Content-Type': 'application/json'
        }
      }
    );

    vars = assoc('tokens', await response.json(), vars);
    return path(['tokens', 'access_token'], vars);
  };

  const getAccessTokenRefresh = async () => {
    const response = await fetch(
      buildUrl('/u/token'),
      {
        method: 'POST',
        body: JSON.stringify({
          grant_type: 'refreshToken',
          refreshToken: path(['tokens', 'refresh_token'], vars)
        }),
        headers: {
          Accept: 'application/json',
          Authorization: 'Basic ' + btoa(`${prop('clientId', vars)}:${prop('key', vars)}`),
          'Content-Type': 'application/json'
        }
      }
    );
    const tokens = await response.json();
    vars = assoc('tokens', tokens, vars);

    return path(['tokens', 'access_token'], vars);
  };

  const setUseToken = (useToken) => {
    vars = assoc('useToken', useToken, vars);
  };

  return {
    getAccessToken,
    setKey,
    setUseToken
  };
})();

export default Tokens;
