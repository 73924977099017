import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation DuplicateDocumentMutation($actualDocumentId: ID!, $documentToCopyId: ID!) {
    duplicateDocument(actualDocumentId: $actualDocumentId, documentToCopyId: $documentToCopyId) {
      ok
      error
    }
  }
`;

export default ({
  documentToCopyId,
  actualDocumentId
}, done) => {
  const variables = {
    documentToCopyId,
    actualDocumentId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ duplicateDocument }) => basicMutationOnCompleted(duplicateDocument, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
