import React from 'react';

const DropperIcon = () => (
  <svg
    version="1.1" viewBox="0 0 16.02 16.02" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>90D74977-7D9F-4698-9854-315F1F719562</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1042 -477)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 347)">
          <g transform="translate(753 129)">
            <g transform="translate(0 1)">
              <path d="m4.2904 13.585c-0.092592 0.092477-0.21803 0.14448-0.34906 0.14448h-1.6718v-1.6708c0-0.13276 0.05078-0.25765 0.14837-0.35128l4.4124-4.4129-1.0618-1.0602-4.4124 4.4129c-0.37468 0.37472-0.58499 0.88289-0.58499 1.4129v1.4211l-0.64874 1.1354c-0.20759 0.36222-0.14654 0.81851 0.1485 1.1123 0.29503 0.295 0.75092 0.35597 1.1131 0.14911l1.1353-0.64881h1.4209c0.52999 0 1.0381-0.21052 1.4128-0.58537l4.4124-4.4129-1.0584-1.0585-4.4165 4.4126zm10.831-12.707c-1.1712-1.171-3.0703-1.171-4.2406 0l-3.4656 3.4684-0.37812-0.38129c-0.29278-0.29281-0.76717-0.29281-1.06 0-0.29281 0.29281-0.29278 0.76695 0 1.0598l4.9968 4.9973c0.14637 0.14645 0.33749 0.21961 0.52999 0.21961s0.38343-0.073194 0.52999-0.21961c0.29278-0.29281 0.29278-0.76726 0-1.0598l-0.38124-0.37816 3.4656-3.4659c1.175-1.1689 1.175-3.069 0.003125-4.2404z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export default DropperIcon;
