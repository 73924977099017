import React from 'react';

const LoadingIcon = () => (
  <svg
    version="1.1" viewBox="-.02 0 21.03 18.02" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>FD68CF6C-5A49-480A-AF08-B68BBDA87453</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-354 -530)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(288 97)">
          <g transform="translate(26 419)">
            <g transform="translate(40 14)">
              <path id="a" d="m5.3615 4.0947c1.9298-1.8307 4.7769-2.4787 7.3701-1.6773 0.37983 0.12965 0.80385 0.051894 1.1056-0.20275s0.43314-0.64553 0.34253-1.0192c-0.090609-0.3737-0.38873-0.67056-0.77735-0.77407-3.4927-1.0514-7.3103-0.09826-9.8079 2.4487-2.4976 2.547-3.2287 6.2327-1.8784 9.4688 0.03723 0.093142 3.3385e-4 0.19831-0.088265 0.2516l-1.2357 0.77997c-0.28959 0.18391-0.43827 0.51245-0.37954 0.83867 0.067548 0.33273 0.33918 0.59414 0.68847 0.66255l3.8925 0.8219h0.17653c0.17303-0.0012838 0.34185-0.050864 0.48546-0.14257 0.19501-0.12156 0.33146-0.31151 0.37954-0.52836l0.80321-3.6986c0.060576-0.32448-0.08446-0.6526-0.37071-0.83867-0.2966-0.18803-0.68314-0.18803-0.97974 0l-1.1828 0.73803c-0.062215 0.024234-0.13197 0.024234-0.19418 0-0.064847-0.023859-0.11611-0.072572-0.14122-0.13419-0.8205-2.4666-0.12999-5.1621 1.7918-6.9945z" />
              <path d="m20.984 3.8096c-0.061756-0.33124-0.32583-0.59536-0.67082-0.67094l-3.8748-0.86383c-0.22825-0.050192-0.46814-0.0121-0.66681 0.10588-0.19867 0.11798-0.33983 0.30618-0.39237 0.52312l-0.88265 3.6734c-0.064339 0.32104 0.073452 0.64836 0.35306 0.83867 0.149 0.10251 0.32799 0.15822 0.51194 0.15935 0.16658 9.0623e-4 0.32958-0.045851 0.46781-0.13419l1.271-0.75481c0.062215-0.024234 0.13197-0.024234 0.19418 0 0.064847 0.023859 0.11611 0.072572 0.14122 0.13419 0.85445 2.4619 0.18885 5.1712-1.7245 7.0192s-4.7533 2.5248-7.358 1.7533c-0.28091-0.083647-0.58535-0.05716-0.8456 0.07357-0.26025 0.13073-0.45473 0.35486-0.54017 0.62253-0.088144 0.26468-0.061251 0.55187 0.074693 0.79761 0.13594 0.24574 0.36965 0.42963 0.64908 0.51073 0.91144 0.2699 1.861 0.40566 2.8157 0.40262 3.1516 0.0032638 6.0993-1.4806 7.8667-3.96s2.1237-5.6309 0.951-8.4105c-0.035103-0.095666 9.448e-4 -0.20185 0.088265-0.25999l1.1916-0.70449c0.29622-0.18655 0.4458-0.5237 0.37954-0.85545z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LoadingIcon;
