import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
    mutation UpdateDefaultFontMutation($teamId: ID!, $value: String!) {
      updateDefaultFont(teamId: $teamId, value: $value) {
        ok
        error
      }
    }
  `;

export default ({
  teamId,
  value
}, done) => {
  const variables = {
    teamId,
    value
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateDefaultFont }) => basicMutationOnCompleted(updateDefaultFont, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
