import { compose, flatten, pluck, pathOr, or, equals, over, lensPath, ifElse, isNil, append, insert, prop, assoc, dissoc, defaultTo, remove, adjust, lt, length, path, update, map, reject, filter, includes, assocPath } from 'ramda';
import { isNilOrEmpty, isNotEmpty, isTrue } from 'ramda-adjunct';
import { BASE_PADDING_DOCUMENT } from '../_CONST';

export const useWidgets = (state, setState) => {
  const widgetsAreDefined = compose(
    isNotEmpty,
    flatten,
    pluck('widgets'),
    pathOr([], ['values', 'pages'])
  )(state);

  const isHeaderOrFooter = (type) => or(equals('header')(type), equals('footer')(type));
  const pathWidgetsSection = (sectionIndex, type) => ['values', 'pages', sectionIndex, type];
  const pathWidgetsRow = (sectionIndex, rowIndex, type) => [...pathWidgetsSection(sectionIndex, type), rowIndex, 'widgets'];
  const pathDataRules = (sectionIndex, rowIndex, widgetIndex, type) => [...pathWidgetsRow(sectionIndex, rowIndex, type), widgetIndex, 'rules'];

  const addWidget = (sectionIndex, type) => (rowIndex) => setState(over(
    lensPath(pathWidgetsSection(sectionIndex, type)),
    (data) => {
      const widgetData = {
        widgets: [{ rules: [] }],
        padding: BASE_PADDING_DOCUMENT
      };
      if (!isHeaderOrFooter(type)) widgetData.breakPage = false;
      return ifElse(
        isNil,
        () => append(widgetData)(data),
        () => insert(rowIndex, widgetData)(data)
      )(rowIndex);
    }
  ));

  const addRowWidget = (sectionIndex, type) => rowIndex => setState(over(
    lensPath(pathWidgetsRow(sectionIndex, rowIndex, type)),
    append({ rules: [] })
  ));

  const updateManualBreakPage = (sectionIndex) => (rowIndex, checked) =>
    setState(
      over(
        lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex]),
        compose(
          ifElse(
            compose(isTrue, prop('breakPage')),
            assoc('rulesBreakPage', []),
            dissoc('rulesBreakPage')
          ),
          assoc('breakPage', checked)
        )
      )
    );

  const addManualBreakPageRule = (sectionIndex) => (rowIndex) => setState(over(
    lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex, 'rulesBreakPage']),
    compose(
      append({}),
      defaultTo([])
    )
  ));

  const removeManualBreakPageRule = (sectionIndex) => (rowIndex) => (ruleIndex) => () => setState(over(
    lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex, 'rulesBreakPage']),
    remove(ruleIndex, 1)
  ));

  const updateManualBreakPageRule = (sectionIndex) => (rowIndex) => (ruleIndex) => (fn) => setState(over(
    lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex, 'rulesBreakPage']),
    adjust(ruleIndex, fn)
  ));

  const updatePaddingRow = (sectionIndex, type) => rowIndex => (key, value) => setState(over(
    lensPath(['values', 'pages', sectionIndex, type, rowIndex, 'padding']),
    assoc(key, value)
  ));

  const removeWidget = (sectionIndex, type) => (rowIndex, index) => ifElse(
    compose(
      lt(1),
      length,
      path(pathWidgetsRow(sectionIndex, rowIndex, type))
    ),
    () => setState(over(
      lensPath(pathWidgetsRow(sectionIndex, rowIndex, type)),
      remove(index, 1)
    )),
    () => {
      if (equals('widgets', type)) updateManualBreakPage(sectionIndex)(rowIndex, false);
      setState(over(
        lensPath(pathWidgetsSection(sectionIndex, type)),
        remove(rowIndex, 1)
      ));
    }
  )(state);

  const moveRowWidget = (sectionIndex, type) => (oldIndex, newIndex) => {
    const widgetOldIndex = path([...pathWidgetsSection(sectionIndex, type), oldIndex], state);
    const widgetNewIndex = path([...pathWidgetsSection(sectionIndex, type), newIndex], state);
    setState(
      over(
        lensPath(pathWidgetsSection(sectionIndex, type)),
        compose(
          update(oldIndex, {
            ...widgetNewIndex, breakPage: prop('breakPage', widgetOldIndex), rulesBreakPage: prop('rulesBreakPage', widgetOldIndex)
          }),
          update(newIndex, {
            ...widgetOldIndex, breakPage: prop('breakPage', widgetNewIndex), rulesBreakPage: prop('rulesBreakPage', widgetNewIndex)
          })
        )
      )
    );
  };

  const updateWidget = (globalVariablesNames, sectionIndex, type) => (rowIndex, index, widgetId) => {
    const rulesLastWidget = path([...pathDataRules(sectionIndex, rowIndex, index, type)], state);
    const newRules = compose(
      reject((rule) => isNilOrEmpty(prop('conditions', rule))),
      map((rule) => {
        const conditionsToKeep = filter((condition) => includes(prop('variable', condition), globalVariablesNames))(prop('conditions', rule));
        return { ...rule, conditions: conditionsToKeep };
      })
    )(rulesLastWidget);

    setState(over(
      lensPath(pathWidgetsRow(sectionIndex, rowIndex, type)),
      adjust(index, compose(
        assoc('type', equals('widgets', type) ? 'content' : type),
        assoc('widgetId', widgetId),
        assoc('rules', newRules)
      ))
    ));
  };

  const updateBreakPage = (sectionIndex, value) => setState(
    assocPath(['values', 'pages', sectionIndex, 'breakPage'], value)
  );

  const addWidgetRule = (sectionIndex, type) => (rowIndex, widgetIndex) => () => setState(over(
    lensPath(pathDataRules(sectionIndex, rowIndex, widgetIndex, type)),
    compose(
      append({}),
      defaultTo([])
    )
  ));

  const removeWidgetRule = (sectionIndex, type) => (rowIndex, widgetIndex) => (ruleIndex) => () => setState(over(
    lensPath(pathDataRules(sectionIndex, rowIndex, widgetIndex, type)),
    remove(ruleIndex, 1)
  ));

  const updateWidgetRule = (sectionIndex, type) => (rowIndex, widgetIndex) => (ruleIndex) => (fn) => setState(over(
    lensPath(pathDataRules(sectionIndex, rowIndex, widgetIndex, type)),
    adjust(ruleIndex, fn)
  ));

  return {
    state,
    widgetsAreDefined,
    addWidget,
    addRowWidget,
    removeWidget,
    updateWidget,
    moveRowWidget,
    updateBreakPage,
    addWidgetRule,
    removeWidgetRule,
    updateWidgetRule,
    updateManualBreakPage,
    updatePaddingRow,
    addManualBreakPageRule,
    removeManualBreakPageRule,
    updateManualBreakPageRule
  };
};
