import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';
import { updaterReview } from './helpers/updaterReview';

const mutation = graphql`
  mutation RejectReviewMutation($versionId: ID!, $reason: String!) {
    rejectReview(versionId: $versionId, reason: $reason) {
      ok
      error
    }
  }
`;

export default ({ versionId, reason, itemId }, done) => {
  const variables = {
    versionId,
    reason
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { rejectReview }) => updaterReview('reject', proxyStore, rejectReview, itemId, reason),
      onCompleted: ({ rejectReview }) => basicMutationOnCompleted(rejectReview, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
