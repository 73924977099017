import { forEach, compose, replace, prop } from 'ramda';
import getCommentsElements from './getCommentsElements';

const hideComments = () => {
  const commentsElements = getCommentsElements();

  forEach(commentElement => {
    commentElement.className = compose(
      v => `${v} hidden`,
      replace('hidden', '')
    )(prop('className', commentElement));
  })(commentsElements);
};

export default hideComments;
