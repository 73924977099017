import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { HistoryOutlined, MoreOutlined, FileTextOutlined } from '@ant-design/icons';
import { isNotEmpty } from 'ramda-adjunct';
import { Link } from 'react-router-dom';

const FrameDropdown = ({ hasComparisonInProgress, setInfosFrameId, comparisons, id }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <div className="flex justify-end gap-2 text-lg">
      <Dropdown
        overlay={ (
          <Menu onClick={() => setDropdownVisible(false)}>
            <Menu.Item
              key={`details-${id}`}
              icon={<FileTextOutlined/>}
              className="btn-see-frame-details"
            >
              <Link to={`/trames/${id}/informations/`}>Voir la trame détaillée</Link>
            </Menu.Item>
            {isNotEmpty(comparisons) && (
              <Menu.Item
                key={`history-${id}`}
                icon={<HistoryOutlined/>}
                onClick={() => setInfosFrameId(id)}
                className="btn-last-comparison"
              >
                Voir la dernière comparaison
              </Menu.Item>
            )}
          </Menu>
        )}
        visible={dropdownVisible}
        onVisibleChange={setDropdownVisible}
        overlayClassName="modular-dropdown"
        trigger={['click']}
        disabled={hasComparisonInProgress}
      >
        <MoreOutlined className={`grey ${hasComparisonInProgress ? 'cursor-not-allowed' : 'cursor-pointer'}`} />
      </Dropdown>
    </div>
  );
};

export default FrameDropdown;
