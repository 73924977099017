import { isNil } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateCommentStatusMutation($commentId: ID!, $status: CommentStatusEnum!) {
    updateCommentStatus(commentId: $commentId, status: $status) {
      ok
      error
    }
  }
`;

export default ({
  commentId,
  status
}, done) => {
  const variables = {
    commentId,
    status
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { updateCommentStatus: { ok, error } }) => {
        if (ok && !error) {
          const comment = proxyStore.get(commentId);

          if (!isNil(comment)) {
            comment.setValue(status, 'status');
          }
        }
      },
      onCompleted: ({ updateCommentStatus }) => basicMutationOnCompleted(updateCommentStatus, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
