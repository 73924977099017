import React, { useState } from 'react';
import { propOr, reject, find, equals, path, prop, isNil, assoc, without, map, length, isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';
import { Input, Button, Table, Popconfirm, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Trash } from '../../components/icons';
import { Section, Title, ChevronBottom } from '../../components';
import CreateGroupMutation from '../../_graphql/mutations/groups/CreateGroupMutation';
import AddGroupMemberMutation from '../../_graphql/mutations/groups/AddGroupMemberMutation';
import withUser from '../../withUser';
import { getEmail } from '../helpers/getEmail';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import AvatarEmail from './AvatarEmail';

const useCreateGroup = (userTeam) => {
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    name: '',
    userToAdd: []
  });

  const [tempUserToAdd, setTempUserToAdd] = useState(undefined);

  const teamMembers = propOr([], 'members', userTeam);

  const teamMembersNotInGroup = reject(member => {
    const groupMember = find(equals(path(['user', 'id'], member)))(prop('userToAdd', state));
    return !isNil(groupMember);
  })(teamMembers);

  const getUserEmail = userId => getEmail(userId, teamMembers);

  const createGroup = () => {
    setState(assoc('loading', true));

    CreateGroupMutation({ name: prop('name', state) }, (ok, error, groupId) => {
      setState(assoc('loading', false));

      if (ok && !error && !isNil(groupId)) {
        successMessage('groupe', 'créé');
        prop('userToAdd', state).map(memberId => {
          AddGroupMemberMutation({ groupId, memberId }, (ok, error) => {
            if (ok && !error) {
              setState(assoc('userToAdd', without([memberId], prop('userToAdd', state))));
              successMessage('utilisateur', 'ajouté au groupe', true);
            } else {
              errorMessage();
            }
          });
        });
        history.push('/equipe');
      } else {
        errorMessage();
      }
    });
  };

  return {
    ...state,
    setState,
    createGroup,
    getUserEmail,
    teamMembersNotInGroup,
    tempUserToAdd,
    setTempUserToAdd
  };
};

const CreateGroup = ({
  userIsAdmin,
  userTeam
}) => {
  const {
    loading,
    name,
    createGroup,
    setState,
    userToAdd,
    getUserEmail,
    teamMembersNotInGroup,
    tempUserToAdd,
    setTempUserToAdd
  } = useCreateGroup(userTeam);

  if (!userIsAdmin) {
    return null;
  }

  return (
    <>
      <Title title="Créer un nouveau groupe" backTo="/equipe" />
      <Section>
        <Input
          placeholder="Nom du groupe"
          value={name}
          onChange={e => setState(assoc('name', e.target.value))}
        />

        <div className="mt-8">
          <label className="block mb-2 font-bold">Utilisateurs du groupe</label>
          <Select
            className="w-full"
            placeholder="Ajouter un utilisateur à ce groupe"
            value={tempUserToAdd}
            showArrow
            suffixIcon={<ChevronBottom />}
            mode="multiple"
            onChange={v => setTempUserToAdd(v)}
          >
            {map(member => {
              const memberId = path(['user', 'id'])(member);

              return (
                <Select.Option
                  key={memberId}
                  value={memberId}
                >
                  {path(['user', 'email'])(member)}
                </Select.Option>
              );
            })(teamMembersNotInGroup)}
          </Select>

          <Button
            onClick={() => {
              setState(assoc('userToAdd', [...userToAdd, ...tempUserToAdd])); setTempUserToAdd(undefined);
            }}
            disabled={isNil(tempUserToAdd) || !length(tempUserToAdd)}
            className="mt-4"
            type="primary"
            icon={<PlusOutlined />}
          >
            {length(userToAdd) > 1 ? 'Ajouter ces utilisateurs au groupe' : 'Ajouter l\'utilisateur au groupe'}
          </Button>
        </div>

        <Table
          dataSource={userToAdd}
          pagination={false}
          rowKey={userId => userId}
          className="mb-8 mt-10"
        >
          <Table.Column
            title="Email"
            dataIndex=""
            width={800}
            render={userId => {
              const email = getUserEmail(userId);

              return (
                <AvatarEmail email={email} />

              );
            }}
          />

          <Table.Column
            title="Actions"
            dataIndex=""
            width={100}
            render={(userId) => (
              <Popconfirm
                title="Êtes-vous sûr de vouloir retirer cet utilisateur du groupe ?"
                onConfirm={() => setState(assoc('userToAdd', without([userId], userToAdd)))}
              >
                <Trash />
              </Popconfirm>
            )}
          />
        </Table>

        <Button
          type="primary"
          loading={loading}
          disabled={isEmpty(name)}
          onClick={createGroup}
          className="block mt-4"
        >
          Créer le groupe
        </Button>
      </Section>
    </>
  );
};

export default withUser(CreateGroup);
