import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation PreviewDocumentMutation($pages: [DocumentSectionInput!]!, $variables: String, $padding: PaddingInput, $documentId: ID) {
    previewDocument(pages: $pages, variables: $variables, padding: $padding, documentId: $documentId) {
      ok
      error
      url
    }
  }
`;

export default ({
  pages,
  variables,
  padding,
  documentId
}, done) => {
  variables = JSON.stringify(variables);
  commitMutation(
    environment(),
    {
      mutation,
      variables: {
        pages,
        variables,
        padding,
        documentId
      },
      onCompleted: ({ previewDocument }) => {
        const { ok, error, url } = previewDocument;
        done(ok, error, url);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
