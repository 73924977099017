import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QWidgetsQuery = graphql`
  query QWidgetsQuery($scope: DocumentScopeEnum, $offerId: ID, $status: OfferStatus) {
    widgets(scope: $scope, offerId: $offerId, status: $status) {
      id
      name
      parentFolder
      scope
      offerId
      rootWidgetId
      offerName
      nbColsGrid
      heightRowsGrid
      contents
      orientation
      frameStyle {
        backgroundColor
        borderColor {
          top
          left
          right
          bottom
        }
        borderWidth {
          top
          left
          right
          bottom
        }
        borderStyle {
          top
          left
          right
          bottom
        }
        borderRadius {
          topLeft
          topRight
          bottomLeft
          bottomRight
        }
      }
      version {
        status
        major
        minor
        itemRootId
        versionId
        itemId
      }
      variables {
        type
        key
        dynamicTableData {
          colsToHide
          rows {
            rowName
            cells {
              value
            }
          }
        }
        dataMatrix
        fields
        options {
          value
          title
        }
        default
      }
      isUsed
      isUsedBy {
        id
        name
      }
      dates {
        creation
        lastUpdate
      }
    }
  }
`;

const QWidgets = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    query={QWidgetsQuery}
    caching={false}
    args={args}
  >
    {populateChildren('widgets')(children, childDataProp)}
  </Query>
);

export default QWidgets;
