import React, { useState } from 'react';
import { Badge, Popconfirm, Tooltip, message } from 'antd';
import { CopyOutlined, DeleteOutlined, FormOutlined, PlusCircleOutlined, SlidersOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { isNotEmpty, isNotNil } from 'ramda-adjunct';
import { concat, equals, find, map, not, prop, propEq } from 'ramda';
import DocumentRules from '../../../rules/DocumentRules';
import SelectWidget from '../../SelectWidget';
import { errorMessage } from '../../../../../../utils/messageMutation';
import DuplicateWidgetMutation from '../../../../../../_graphql/mutations/documents/DuplicateWidgetMutation';

const WidgetForm = ({
  index,
  widgetId,
  scope,
  itemPathPrefix,
  rowIndex,
  widgetsList,
  rules,
  getWidgetVariables,
  globalVariables,
  getRulesAreValid,
  getConditionsAreValid,
  addWidgetRule,
  removeWidgetRule,
  updateWidgetRule,
  updateWidget,
  removeWidget,
  setWidgetIdEditing,
  setRowIndexEditing,
  canRemove,
  rowWidgetsId,
  type,
  setIsDynamicTableInSection,
  setIsOpenCreateWidgetModal,
  setDuplicateWidgetPlacement,
  setWidgetCreated,
  userIsAdmin,
  setIsOpenCreateGlobalVariableModal
}) => {
  const [displayRules, setDisplayRules] = useState(false);
  const widgetHasRules = (isNotNil(widgetId) && isNotNil(rules)) || isNotEmpty(globalVariables);
  const variables = concat(getWidgetVariables(widgetId), map((v) => ({ type: prop('type', v), key: prop('name', v) }), globalVariables));
  const canShowRules = widgetHasRules && isNotEmpty(rules);

  const onDuplicateWidget = async () => {
    message.info('Duplication du widget en cours...');
    DuplicateWidgetMutation({ widgetId }, (ok, error, widgetIdDuplicate, name) => {
      if (ok && !error) {
        message.success('Le widget a bien été dupliqué.');
        const widgetDuplicated = find(propEq('id', widgetId))(widgetsList);
        setDuplicateWidgetPlacement({
          rowIndex, index, sectionIndex: itemPathPrefix[2], type: itemPathPrefix[3]
        });
        setWidgetCreated({
          ...widgetDuplicated, name, id: widgetIdDuplicate, rootDocumentId: widgetId, dates: { creation: new Date() }
        });
      } else {
        errorMessage();
      }
    });
  };

  return (
    <div className="w-full">
      <div>
        <div className="flex items-center w-full justify-between">
          <SelectWidget
            updateWidget={updateWidget}
            index={index}
            rowIndex={rowIndex}
            widgetId={widgetId}
            widgetsList={widgetsList}
            scope={scope}
            itemPathPrefix={itemPathPrefix}
            rowWidgetsId={rowWidgetsId}
            type={type}
            canShowRules={canShowRules}
            setIsDynamicTableInSection={setIsDynamicTableInSection}
            userIsAdmin={userIsAdmin}
          />
          <div className="grid grid-cols-5 gap-x-6 items-center ml-4">
            <Badge
              dot={canShowRules}
              color={canShowRules ? '#0197DC' : undefined}
            >
              <Tooltip
                title={`${userIsAdmin ? 'Ajouter' : 'Consulter'} une règle`}
                color="#0197DC"
              >
                <SlidersOutlined
                  onClick={widgetId ? () => setDisplayRules(not) : null}
                  rotate={90}
                  className={classNames({ 'opacity-50': !widgetId })}
                />
              </Tooltip>
            </Badge>
            {userIsAdmin && (
              <Tooltip
                title="Créer un widget"
                color="#0197DC"
              >
                <PlusCircleOutlined
                  className="create-widget-button"
                  onClick={() => {
                    const typeRow = equals('content', type) ? 'widgets' : type;
                    setRowIndexEditing([typeRow, rowIndex]);
                    setIsOpenCreateWidgetModal({
                      updateWidget, rowIndex, index, sectionIndex: itemPathPrefix[2], type: itemPathPrefix[3]
                    });
                  }}
                />
              </Tooltip>
            )}

            {userIsAdmin && (
              <Tooltip
                title="Dupliquer le widget"
                color="#0197DC"
              >
                <CopyOutlined
                  onClick={widgetId ? () => onDuplicateWidget() : null}
                  className={classNames({ 'opacity-50': !widgetId })}
                />
              </Tooltip>
            )}

            <Tooltip
              title={userIsAdmin ? 'Éditer le widget' : 'Voir le widget'}
              color="#0197DC"
            >
              <FormOutlined
                onClick={widgetId ? () => {
                  setWidgetIdEditing(widgetId);
                  const typeRow = equals('content', type) ? 'widgets' : type;
                  setRowIndexEditing([typeRow, rowIndex]);
                } : null}
                className={classNames({ 'opacity-50': !widgetId })}
              />
            </Tooltip>

            {userIsAdmin && (
              <Popconfirm
                title="Êtes-vous sûr de vouloir supprimer ce widget ?"
                onConfirm={() => {
                  setIsDynamicTableInSection(false);
                  removeWidget(rowIndex, index);
                }}
                disabled={!canRemove}
              >
                <Tooltip
                  title="Supprimer le widget"
                  color="#0197DC"
                >
                  <DeleteOutlined className={classNames({ 'opacity-50 !cursor-default': !canRemove })} />
                </Tooltip>
              </Popconfirm>
            )}
          </div>
        </div>
        {widgetHasRules && displayRules && (
          <DocumentRules
            variables={variables}
            rules={rules}
            rulesAreValid={getRulesAreValid(rules)}
            getConditionsAreValid={getConditionsAreValid}
            onAdd={addWidgetRule(rowIndex, index)}
            onRemove={removeWidgetRule(rowIndex, index)}
            onUpdate={updateWidgetRule(rowIndex, index)}
            userIsAdmin={userIsAdmin}
            setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
          />
        )}
      </div>
    </div>
  );
};

export default WidgetForm;
