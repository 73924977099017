import React from 'react';
import { useParams } from 'react-router-dom';
import { SCOPE_OFFER } from '../../_CONST';
import DocumentForm from './components/document/DocumentForm';

const CreateOfferDocument = () => {
  const { offerId } = useParams();
  return (
    <DocumentForm
      scope={SCOPE_OFFER}
      offerId={offerId}
      cacheKey={['offerViewCache', `widgets-${offerId}`]}
    />
  );
};

export default CreateOfferDocument;
