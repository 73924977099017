import React from 'react';
import { Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const RemoveUserFromTeam = ({ memberId, onRemove }) => {
  const confirm = () => {
    onRemove(memberId)();
  };

  return (
    <Popconfirm
      title="Êtes-vous sûr de vouloir retirer cet utilisateur ?"
      onConfirm={confirm}
      okText="Oui"
      cancelText="Non"
    >
      <Tooltip title="Supprimer de l'équipe" color="#0197DC">
        <DeleteOutlined className="delete-member hover:text-red-500" />
      </Tooltip>
    </Popconfirm>
  );
};

export default RemoveUserFromTeam;
