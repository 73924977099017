import { filter, prop, isNil } from 'ramda';
import attributesNames from '../data/attributesNames';

const getElements = (itemRootId) => {
  const elements = document.querySelectorAll(`*[${prop('itemPath', attributesNames)}]`);

  return filter(element => {
    const itemRoot = prop('attributes', element).getNamedItem(prop('itemRoot', attributesNames));
    return !isNil(itemRoot) && prop('value', itemRoot) === itemRootId;
  })(elements);
};

export default getElements;
