import React from 'react';

const FontIcon = () => (
  <svg
    version="1.1" viewBox="-.02 0 16.03 16" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>75AA787C-D5C6-4AF2-B7F0-20FA8D30313C</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-964 -618)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 347)">
          <g transform="translate(35 254)">
            <g transform="translate(345)">
              <g transform="translate(285)">
                <g transform="translate(10 17)">
                  <path d="m15.143 14.286h-1.1229l-5.22-13.734c-0.125-0.33036-0.44679-0.54894-0.79929-0.55216-0.00335 0 0.0022321 0 0 0-0.35043 0-0.66714 0.2129-0.79786 0.53894l-5.4971 13.747h-0.84857c-0.47321 0-0.85714 0.38572-0.85714 0.85716s0.38393 0.85716 0.85714 0.85716h4c0.475 0 0.85714-0.38215 0.85714-0.85716s-0.38214-0.85716-0.85714-0.85716h-1.3054l1.2575-3.1429h6.1821l1.1943 3.1429h-1.0429c-0.47321 0-0.85714 0.38394-0.85714 0.85716 0 0.47322 0.38571 0.85716 0.85714 0.85716h4c0.47321 0 0.85714-0.38394 0.85714-0.85716 0-0.47322-0.38214-0.85716-0.85714-0.85716zm-9.6464-4.8573 2.485-6.2109 2.3579 6.2109h-4.8429z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FontIcon;
