import { pathOr, over, lensPath, append, assoc, compose, defaultTo, adjust, remove, prop, cond, insert, nth, set, lensIndex, T, equals } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { BASE_PADDING_DOCUMENT } from '../_CONST';

export const defaultPage = {
  header: [],
  widgets: [],
  footer: [],
  breakPage: true,
  padding: BASE_PADDING_DOCUMENT,
  rules: []
};

export const useSections = (state, setState) => {
  const pathPages = ['values', 'pages'];
  const pages = pathOr([], pathPages, state);
  const actionToSection = (actionToDo) => over(lensPath(pathPages), actionToDo);

  const addSection = () => setState(actionToSection(append(defaultPage)));

  const updatePaddingSection = (index) => (key, value) =>
    setState(
      over(lensPath([...pathPages, index, 'padding']), assoc(key, value))
    );

  const addRuleSection = (index) => setState(over(
    lensPath([...pathPages, index, 'rules']),
    compose(
      append({}),
      defaultTo([])
    )
  ));

  const updateRuleSection = (index) => (ruleIndex) => (fn) => setState(over(
    lensPath([...pathPages, index, 'rules']),
    adjust(ruleIndex, fn)
  ));

  const removeRuleSection = (index) => (ruleIndex) => () => setState(over(
    lensPath([...pathPages, index, 'rules']),
    remove(ruleIndex, 1)
  ));

  const removeSection = (index) => {
    setState(actionToSection(remove(index, 1)));
    if (isNotNilOrEmpty(prop('previewHtml', state))) {
      setState(assoc('previewHtml', remove(index, 1, prop('previewHtml', state))));
    }
  };

  const editSections = (index, action, oldIndex) => {
    cond([
      [equals('add'), () => addSection()],
      [equals('remove'), () => removeSection(index)],
      [equals('addSectionAfter'), () => setState(actionToSection(insert(index + 1, defaultPage)))],
      [equals('addSectionBefore'), () => setState(actionToSection(insert(index, defaultPage)))],
      [equals('duplicateSection'), () => setState(actionToSection(append(nth(index, pages))))],
      [equals('swapSections'), () => setState(actionToSection(compose(
        set(lensIndex(index), nth(oldIndex, pages)),
        set(lensIndex(oldIndex), nth(index, pages))
      )))],
      [equals('moveSectionBefore'), () => setState(actionToSection(compose(
        insert(oldIndex, nth(index, pages)),
        remove(index, 1)
      )))],
      [T, () => state]
    ])(action);
  };

  return {
    editSections,
    updatePaddingSection,
    addRuleSection,
    updateRuleSection,
    removeRuleSection
  };
};
