import { Button, message, Select } from 'antd';
import { prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Actions, ChevronBottom, Section, Title } from '../../../components';
import { fontOptions } from '../common/FontOptions';
import withUser from '../../../withUser';
import Loading from '../../../components/Loading';
import { errorMessage } from '../../../utils/messageMutation';
import UpdateDefaultFontMutation from '../../../_graphql/mutations/documents/UpdateDefaultFontMutation';
import useDefaultFont from '../../../hooks/useDefaultFont';

const DefaultFont = ({ userTeam }) => {
  const {
    defaultFont,
    isLoading
  } = useDefaultFont({ teamId: prop('teamId', userTeam) });

  const [selectedFont, setSelectedFont] = useState();

  useEffect(() => {
    if (!isLoading) setSelectedFont(prop('value', defaultFont));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) return <Loading />;

  const onSave = () => {
    const callback = (ok, error) => {
      if (ok && !error) {
        message.success(
          'Police par défaut sauvegardée.'
        );
      } else {
        errorMessage();
      }
    };

    UpdateDefaultFontMutation({ teamId: prop('teamId', userTeam), value: selectedFont }, callback);
  };

  return (
    <>
      <Actions
        title={<Title title="Ma police par défaut" backTo="/equipe" />}
      />
      <Section>
        <Select
          value={selectedFont}
          onChange={setSelectedFont}
          className="w-full"
          suffixIcon={<ChevronBottom />}
        >
          {fontOptions}
        </Select>

      </Section>
      <Button
        size="large"
        className="mx-auto"
        type="primary"
        onClick={onSave}
      >
        Enregistrer
      </Button>
    </>
  );
};

export default withUser(DefaultFont);
