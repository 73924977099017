import { Dropdown, Menu } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { compose, cond, equals, includes, isEmpty, map, prop, propEq, propOr, sortBy, toLower } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import { errorMessage } from '../../../utils/messageMutation';
import withUser, { UserContext } from '../../../withUser';
import DefineActiveTeamMutation from '../../../_graphql/mutations/teams/DefineActiveTeamMutation';

const StyledTeam = styled.div`
  display: flex;
  &>.icon {
    display: flex;
    width: 48px;
    align-items: center;
    justify-content: center;
  }
  &>.teamname {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: bold;
    color: black;
    display: flex;
  }
`;

const TeamsMenu = () => {
  const {
    teams,
    userTeam,
    user,
    userRefetch
  } = useContext(UserContext);

  const history = useHistory();
  const { pathname } = useLocation();

  // do not remove '/empty' path, it's used to force a re-render of the component when the user change his team
  // https://stackoverflow.com/questions/47602091/how-to-use-react-router-4-0-to-refresh-current-route-not-reload-the-whole-page
  const historyPushWithReRender = (path) => {
    history.push('/empty');
    history.replace(path);
  };

  const onDefineActiveTeam = (teamId, teamName, apiKey) => () => {
    DefineActiveTeamMutation({
      teamId, teamName, userId: prop('userId', user), apiKey
    }, (ok, error) => {
      if (!ok || error) errorMessage();
      userRefetch();
      cond([
        [equals('/'), () => historyPushWithReRender('/')],
        [includes('/offre'), () => historyPushWithReRender('/offre')],
        [includes('/trames'), () => historyPushWithReRender('/trames')],
        [includes('/equipe'), () => historyPushWithReRender('/equipe')]
      ])(pathname);
    });
  };
  const teamsSorted = sortBy(
    compose(
      toLower,
      prop('name')
    )
  )(teams);

  return (
    <Menu className="h-350px overflow-y-scroll scrollbar">
      {map(({ id, name, apiKey }) => (
        <Menu.Item
          key={id}
          disabled={propEq('teamId', id, userTeam)}
          onClick={onDefineActiveTeam(id, name, apiKey)}
        >
          {name}
        </Menu.Item>
      ))(teamsSorted)}
    </Menu>
  );
};

const SelectTeam = () => {
  return (
    <Dropdown
      overlay={<TeamsMenu/>}
      placement="bottomRight"
      trigger={['click']}
      overlayClassName="shadow-sm"
    >
      <div className="dropdown-team">
        <img
          src="/icon/icon-dropdown-black.svg"
          alt="dropdown"
        />
      </div>
    </Dropdown>
  );
};

const TeamTop = ({
  userTeam,
  userRefetch,
  teams
}) => {
  const teamName = propOr('', 'name', userTeam);
  useEffect(() => {
    if (isNotNilOrEmpty(teamName) && isNilOrEmpty(teams)) userRefetch();
  }, [teamName, teams, userRefetch]);

  if (isNilOrEmpty(teams)) return null;

  return (
    <StyledTeam>
      <div className="icon">
        <img src="/image/ic-menu@3x.png" width="22px"/>
      </div>
      {!isEmpty(teamName) && (
        <div className="teamname">
          {`Équipe ${teamName}`}
          {isNotNilOrEmpty(teams) && <SelectTeam/>}
        </div>
      )}
    </StyledTeam>
  );
};

export default withUser(TeamTop);
