import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateTeamEmailsConfigMutation($emailsConfig: [TeamEmailConfigInput!]!) {
    updateEmailsConfig(emailsConfig: $emailsConfig) {
      ok
      error
    }
  }
`;

export default ({ emailsConfig }, done) => {
  const variables = { emailsConfig };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateEmailsConfig }) => basicMutationOnCompleted(updateEmailsConfig, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
