import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

const LinkButton = ({
  children,
  type,
  to,
  history,
  size,
  disabled,
  icon
}) => (
  <Button
    className="btn-link"
    disabled={disabled}
    size={size}
    type={type}
    onClick={(e) => {
      e.preventDefault();
      history.push(to);
    }}
    icon={icon}
  >
    {children}
  </Button>
);

export default withRouter(LinkButton);
