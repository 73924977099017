import React from 'react';
import { useParams } from 'react-router-dom';
import { propEq, prop } from 'ramda';
import QDocument from '../../_graphql/queries/documents/QDocument';
import { SCOPE_OFFER } from '../../_CONST';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';
import DocumentForm from './components/document/DocumentForm';
import { commentsMode } from './components/helpers/elements';

const EditDocument = ({ breadcrumb, userTeam }) => {
  const { documentId } = useParams();
  return (
    <QDocument args={{ documentId }}>
      {data => {
        const { document = {} } = data;
        const accessDenied = verifyRouteAccess(userTeam, document, 'ce document', true);
        if (accessDenied) return accessDenied;
        const cacheKey = propEq('scope', SCOPE_OFFER, document) ? ['offerViewCache', `widgets-${prop('offerId', document)}`] : null;
        return (
          <DocumentForm
            document={document}
            scope={SCOPE_OFFER}
            offerId={prop('offerId', document)}
            commentsMode={commentsMode(document)}
            breadcrumb={breadcrumb}
            documentId={documentId}
            cacheKey={cacheKey}
          />
        );
      }}
    </QDocument>
  );
};

export default withUser(EditDocument);
