import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
mutation CreateTeamMutation($name: String!, $userId: ID!) {
  createTeam(userId: $userId, teamName: $name) { 
    ok
    error
    teamId
  }
}
`;

export default ({ name, userId }, done) => {
  const variables = {
    name,
    userId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (store, { createTeam }) => {
        const { teamId } = createTeam;
        const root = store.get('userTeam');
        root.setValue(teamId, 'teamId');
        root.setValue(name, 'name');
        root.setLinkedRecords([], 'emailsConfig');
        root.setLinkedRecords([], 'remindersConfig');
      },
      onCompleted: ({ createTeam }) => basicMutationOnCompleted(createTeam, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
