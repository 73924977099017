import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { path, isNil } from 'ramda';

const StyledMenuItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 114px;
  width: 100%;
  border-bottom: 1px solid ${props => path(['theme', 'primaryColorDark'], props)};
  &:hover, &.active {
    background-color: ${props => path(['theme', 'primaryColorDark'], props)}
  }
  &>.menuitem-icon {

  }
  &>.menuitem-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 16px;
  }
`;

const MenuItem = ({
  title,
  to,
  imageSrc,
  icon,
  exact
}) => (
  <StyledMenuItem exact={exact} to={to}>
    {!isNil(icon) && <span className="text-2xl text-white">{icon}</span>}
    {!isNil(imageSrc) && <img className="menuitem-icon" src={imageSrc} width="30px" />}
    <div className="menuitem-title">{title}</div>
  </StyledMenuItem>
);

export default MenuItem;
