import { compose, map, reverse, filter, pathEq, prop, assoc } from 'ramda';

const associateVersions = (items, rootIdField, idField = 'id') => compose(
  map(item => {
    const versions = compose(
      reverse,
      filter(pathEq(rootIdField.split('.'), prop(idField, item)))
    )(items);

    return assoc('versions', versions)(item);
  })
)(items);

export {
  associateVersions
};
