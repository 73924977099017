import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { useState } from 'react';
import classNames from 'classnames';
import { not } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

const ElementsBar = ({
  title,
  children,
  info,
  isClosed,
  setIsClosed,
  className
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={`bg-white p-4 ${className}`} >
      <div
        className="flex"
      >
        <div
          className={classNames('btn-close-elements flex items-center min-h-50px gap-4', { 'cursor-pointer': isNotNilOrEmpty(isClosed) })}
          onClick={() => setIsClosed && setIsClosed(not(isClosed))}
        >
          <div className="text-flexibranche-blue uppercase text-elementBar">
            {title}
          </div>
          {isNotNilOrEmpty(isClosed) && (
            <DownOutlined
              className={classNames('text-flexibranche-blue text-medium', { 'rotate-180 mb-1': isClosed })}
            />
          )}
        </div>
        {info && (
          <Popover
            content={(
              <div className="w-64 relative">
                <p className="absolute -right-1 -top-1" onClick={() => setVisible(false)}>X</p>
                {info}
              </div>
            )}
            placement="topRight"
            trigger="hover"
            visible={visible}
            onVisibleChange={(b) => setVisible(b)}
          >
            <InfoCircleOutlined
              className="text-xs text-flexibranche-blue cursor-pointer pr-5 pt-2"
            />
          </Popover>
        )}
      </div>
      <div className="flex justify-center">
        {children}
      </div>
    </div>
  );
};

export default ElementsBar;
