import React, { useState } from 'react';
import { compose, toUpper, head, defaultTo, equals, map, isEmpty, propEq, filter } from 'ramda';
import { Input } from 'antd';
import AddCommentMessageMutation from '../../../../../_graphql/mutations/comments/AddCommentMessageMutation';
import { useDocumentContext } from '../../../../../context/Document';
import { errorMessage } from '../../../../../utils/messageMutation';

const getInitial = compose(
  toUpper,
  head,
  defaultTo('')
);

const Comment = ({
  comment,
  activeComment,
  setActiveComment
}) => {
  const { setWidgetIdEditing } = useDocumentContext();
  const {
    id,
    widgetName,
    widgetId,
    messages = []
  } = comment;

  const isActive = equals(id, activeComment);

  const [responseMessage, setResponseMessage] = useState('');

  const addMessage = () => {
    AddCommentMessageMutation({
      commentId: id,
      message: responseMessage
    }, (ok, error) => {
      if (ok && !error) {
        setResponseMessage('');
      } else {
        errorMessage();
      }
    });
  };

  return (
    <div
      key={id}
      className={`border border-solid rounded mt-4 p-4 ${isActive ? 'border-gray-400' : 'border-gray-300 cursor-pointer'}`}
      // eslint-disable-next-line no-restricted-syntax
      style={{ boxShadow: isActive ? '-1px 2px 10px -2px rgba(0,0,0,0.61)' : undefined }}
      onClick={!isActive ? () => setActiveComment(id) : undefined}
    >
      <div className="flex items-center place-content-between mb-2">
        <span className="font-semibold text-flexibranche-darkgray text-sm">{widgetName}</span>

        {isActive && (
          <button
            onClick={() => setWidgetIdEditing(widgetId)}
            className="cursor-pointer"
          >
            <img src="/icons/icon-pen-to-square.svg" alt="icon-pen-to-square"/>
          </button>
        )}
      </div>

      <div className="flex flex-col gap-y-4">
        {map(message => {
          const { text, userName, when } = message;
          return (
            <div
              key={`${text}-${when}`}
              className="flex"
            >
              <div className="flex justify-center items-center text-white h-9 w-9 bg-flexibranche-blue mr-2.5 rounded-3xl">
                {getInitial(userName)}
              </div>

              <div>
                <div className="flex items-center">
                  <span className="font-semibold text-md">{userName}</span>
                  <span>{when}</span>
                </div>

                {text}
              </div>
            </div>
          );
        })(messages)}

        {isActive && (
          <div className="mt-4">
            <Input.TextArea
              className="mb-4"
              value={responseMessage}
              onChange={e => setResponseMessage(e.target.value)}
              placeholder="Votre réponse"
            />

            <div className="flex gap-x-6">
              <button
                className="text-flexibranche-darkgray cursor-pointer font-bold"
                onClick={() => {
                  setActiveComment(null);
                  setResponseMessage('');
                }}
              >
                Annuler
              </button>

              {!isEmpty(responseMessage) && (
                <button
                  className="text-flexibranche-lightblue cursor-pointer font-bold "
                  onClick={addMessage}
                >
                  Répondre
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CommentsTab = ({
  comments = []
}) => {
  comments = filter(propEq('itemKey', 'widget'))(comments);

  const [activeComment, setActiveComment] = useState();

  return (
    <div className="mt-4 p-4">
      <span className="text-xl uppercase text-flexibranche-blue font-bold">Commentaires</span>

      {map(comment => (
        <Comment
          key={comment.id}
          comment={comment}
          activeComment={activeComment}
          setActiveComment={setActiveComment}
        />
      ))(comments)}
    </div>
  );
};

export default CommentsTab;
