import React from 'react';
import { pathEq } from 'ramda';
import { Popover, Upload, Button } from 'antd';
import { Upload as UploadIcon } from '../../../../components/icons';
import { errorMessage, successMessage } from '../../../../utils/messageMutation';

const UploadButton = ({
  scope,
  offerId,
  reFetch
}) => {
  const uploadStatusIs = (status) => pathEq(['file', 'status'], status);

  return (
    <Upload
      multiple
      name="file"
      accept="application/pdf"
      action="/files/upload"
      showUploadList={false}
      data={{
        scope,
        offerId
      }}
      onChange={(info) => {
        if (uploadStatusIs('done')(info)) {
          successMessage('fichier', 'téléchargé', false, false, true);
          reFetch();
        }

        if (uploadStatusIs('error')(info)) {
          errorMessage();
        }
      }}
    >
      <Button icon={<UploadIcon className="text-xs" />}>
        Importer des documents
      </Button>
    </Upload>
  );
};

export default UploadButton;
