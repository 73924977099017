import React, { useState } from 'react';
import { propOr, map, over, lensPath, isEmpty, append, adjust, findIndex, propEq, assocPath, compose, defaultTo, find, either, reduce, path, assoc, prop } from 'ramda';
import { Table, Select, Input, Button } from 'antd';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { ITEM_ROLES } from '../../../lib/rights';
import { Section, ChevronBottom } from '../../../components';
import { Loading } from '../../../components/icons';
import frequencyUnits from '../../../lib/frequencyUnits';
import UpdateTeamRemindersConfigMutation from '../../../_graphql/mutations/teams/UpdateTeamRemindersConfigMutation';
import UpdateTeamEmailsConfigMutation from '../../../_graphql/mutations/teams/UpdateTeamEmailsConfigMutation';
import { errorMessage, successMessage } from '../../../utils/messageMutation';

const events = [{
  label: 'Mise en revue d\'une offre',
  value: 'offer_to_review'
}, {
  label: 'Validation d\'une offre',
  value: 'offer_validation'
}, {
  label: 'Refus d\'une offre',
  value: 'offer_rejection'
}, {
  label: 'Validation d\'un document',
  value: 'document_validation'
}, {
  label: 'Refus d\'un document',
  value: 'document_rejection'
}];

const reminders = [{
  label: 'Action en attente',
  value: 'pending_action'
}];

const useRemindersConfig = userTeam => {
  const [remindersConfig, setRemindersConfig] = useState(propOr([], 'remindersConfig', userTeam));

  const onSaveRemindersConfig = () => {
    const remindersConfigToSave = map(
      over(
        lensPath(['frequency', 'value']),
        parseInt
      )
    )(remindersConfig);

    UpdateTeamRemindersConfigMutation({ remindersConfig: remindersConfigToSave }, (ok, error) => {
      if (ok && !error) {
        successMessage('configuration', 'mise à jour', false, true);
      } else {
        errorMessage();
      }
    });
  };

  const onUpdateReminderConfig = (event, key, value) => {
    if (isEmpty(getReminderConfig(event))) {
      setRemindersConfig(append({
        event,
        frequency: { [key]: value }
      }));
    } else {
      setRemindersConfig(
        adjust(
          findIndex(propEq('event', event))(remindersConfig),
          assocPath(['frequency', key], value)
        )
      );
    }
  };

  const getReminderConfig = event => compose(
    defaultTo({}),
    find(propEq('event', event))
  )(remindersConfig);

  const remindersConfigsAreValid = !either(
    isEmpty,
    reduce((acc, reminderConfig) => acc || !isNotNilOrEmpty(path(['frequency', 'value'], reminderConfig)) || !isNotNilOrEmpty(path(['frequency', 'unit'], reminderConfig)), false)
  )(remindersConfig);

  return {
    remindersConfig,
    onSaveRemindersConfig,
    onUpdateReminderConfig,
    getReminderConfig,
    remindersConfigsAreValid
  };
};

const useEmailsConfig = (userTeam) => {
  const [emailsConfig, setEmailsConfig] = useState(propOr([], 'emailsConfig', userTeam));

  const onSaveEmailsConfig = () => {
    UpdateTeamEmailsConfigMutation({ emailsConfig }, (ok, error) => {
      if (ok && !error) {
        successMessage('configuration', 'mise à jour', false, true);
      } else {
        errorMessage();
      }
    });
  };

  const onUpdateEmailConfig = (event, to) => {
    if (isEmpty(getEmailConfig(event))) {
      setEmailsConfig(append({ event, to }));
    } else {
      setEmailsConfig(
        adjust(
          findIndex(propEq('event', event))(emailsConfig),
          assoc('to', to)
        )
      );
    }
  };

  const getEmailConfig = event => compose(
    defaultTo({}),
    find(propEq('event', event))
  )(emailsConfig);

  return {
    getEmailConfig,
    onSaveEmailsConfig,
    onUpdateEmailConfig,
    emailsConfig
  };
};

const EmailsConfig = ({ userTeam }) => {
  const {
    getEmailConfig,
    onSaveEmailsConfig,
    onUpdateEmailConfig,
    emailsConfig
  } = useEmailsConfig(userTeam);

  const {
    getReminderConfig,
    onUpdateReminderConfig,
    onSaveRemindersConfig,
    remindersConfigsAreValid
  } = useRemindersConfig(userTeam);

  return (
    <>
      <Section
        title="Configuration des emails"
      >
        <Table
          pagination={false}
          dataSource={events}
          rowKey="value"
        >
          <Table.Column
            title="Événement"
            dataIndex="label"
            width={700}
          />

          <Table.Column
            title="Destinataire(s)"
            dataIndex="value"
            render={value => {
              const emailConfig = getEmailConfig(value);

              return (
                <Select
                  mode="multiple"
                  allowClear
                  showArrow
                  suffixIcon={<ChevronBottom />}
                  className="w-full"
                  placeholder="Sélectionner les destinataires"
                  value={prop('to', emailConfig)}
                  onChange={role => onUpdateEmailConfig(value, role)}
                >
                  {map(role => {
                    const { label, value } = role;

                    return (
                      <Select.Option
                        key={value}
                        value={value}
                      >
                        {label}
                      </Select.Option>
                    );
                  })(ITEM_ROLES())}
                </Select>
              );
            }}
          />
        </Table>

        <Button
          disabled={isEmpty(emailsConfig)}
          onClick={onSaveEmailsConfig}
          className="block mt-6"
          type="primary"
          icon={<Loading />}
        >
          Mettre à jour la configuration des emails
        </Button>
      </Section>

      <Section
        title="Configuration des rappels"
      >
        <Table
          pagination={false}
          dataSource={reminders}
          rowKey="value"
        >
          <Table.Column
            title="Rappel"
            dataIndex="label"
          />

          <Table.Column
            title="Fréquence"
            dataIndex="value"
            render={value => {
              const reminderConfig = getReminderConfig(value);

              return (
                <div className="flex items-center gap-4">
                  <Input
                    type="number"
                    placeholder="Fréquence de rappel"
                    onChange={e => onUpdateReminderConfig(value, 'value', e.target.value)}
                    value={path(['frequency', 'value'], reminderConfig)}
                    className="w-2/4"
                  />

                  <Select
                    placeholder="Unité"
                    onChange={v => onUpdateReminderConfig(value, 'unit', v)}
                    value={path(['frequency', 'unit'], reminderConfig)}
                    className="w-2/4"
                    suffixIcon={<ChevronBottom />}
                  >
                    {map(unit => {
                      const { label, value } = unit;

                      return (
                        <Select.Option
                          key={value}
                          value={value}
                        >
                          {label}
                        </Select.Option>
                      );
                    })(frequencyUnits)}
                  </Select>
                </div>
              );
            }}
          />
        </Table>

        <Button
          disabled={!remindersConfigsAreValid}
          className="block mt-6"
          type="primary"
          onClick={onSaveRemindersConfig}
          icon={<Loading />}
        >
          Mettre à jour la configuration des rappels
        </Button>
      </Section>
    </>
  );
};

export default EmailsConfig;
