import { always, is, isNil, prop, when } from 'ramda';
import React from 'react';

const populateChildren = (dataKey) => (children, childDataProp) => (data) => {
  if (isNil(children)) return null;

  if (isNil(dataKey)) {
    if (is(Function, children)) {
      return children(data);
    }

    return React.cloneElement(
      children,
      data
    );
  }

  data = prop(dataKey, data);
  if (is(Function, children)) {
    return children(data);
  }

  return React.cloneElement(
    children,
    { [when(isNil, always(dataKey), childDataProp)]: data }
  );
};

export {
  populateChildren
};
