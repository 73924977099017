import React, { useContext } from 'react';
import { Button } from 'antd';
import { propEq, cond, always, T, prop } from 'ramda';
import { useHistory } from 'react-router-dom';
import { isTrue } from 'ramda-adjunct';
import TagsTable from '../../docs/components/tag/TagsTable';
import QTags from '../../../_graphql/queries/documents/QTags';
import { Section, Title, Actions } from '../../../components';
import { UserContext } from '../../../withUser';
import { deleteTag } from '../../helpers/deleteTag';

const Tags = () => {
  const history = useHistory();
  const baseUrlTeam = '/equipe/elements/tags';
  const { userTeam } = useContext(UserContext);
  const isAdmin = propEq('role', 'admin')(userTeam);

  const onEdit = (tagId) => history.push(`${baseUrlTeam}/${tagId}/edition`);
  const onCreate = () => history.push(`${baseUrlTeam}/creer`);
  const onPreview = (tagId) => history.push(`${baseUrlTeam}/${tagId}/preview`);
  const getLink = (tag) => cond([
    [isTrue, always(`${baseUrlTeam}/${prop('id', tag)}/edition`)],
    [T, always(`${baseUrlTeam}/${prop('id', tag)}/preview`)]
  ])(isAdmin);

  return (
    <>
      <QTags
        args={{ scope: 'global' }}
      >
        {tags => {
          return (
            <>
              <Actions
                title={(
                  <Title
                    title="Les tags de mon équipe"
                    backTo="/equipe"
                  />
                )}
                buttons={isAdmin && (
                  <Button
                    onClick={onCreate}
                    type="primary"
                    size="large"
                  >
                    Créer un tag
                  </Button>
                )}
              />
              <Section>
                <TagsTable
                  tags={tags}
                  userCanEditOffer={isAdmin}
                  onClickEdit={onEdit}
                  onClickPreview={onPreview}
                  onClickDelete={deleteTag}
                  getLink={getLink}
                />
              </Section>
            </>
          );
        }}
      </QTags>
    </>
  );
};

export default Tags;
