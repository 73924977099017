import { equals, path, always, ifElse, prop, allPass, gt, is, compose, map, cond, all, sort, T, reduceWhile, find, propEq, isNil } from 'ramda';
import { round, ceil } from 'ramda-adjunct';
import { elementResizable, getMinWidth } from './elements';

const WIDTH_A4_WITHOUT_MARGINS = 794;
const WIDTH_LANDSCAPE_WITHOUT_MARGINS = 1123;

const editorWidth = (orientation) => equals('portrait', orientation) ? WIDTH_A4_WITHOUT_MARGINS : WIDTH_LANDSCAPE_WITHOUT_MARGINS;
const widthOneGrid = (nbColsGrid, orientation) => editorWidth(orientation) / nbColsGrid;

export const getSizeElement = (type, element) => path(['size', type], element);

/**
 * It takes a list of elements, and returns a list of elements with the same properties, but with the
 * width and height properties converted to a grid system
 * @param contents - the array of elements that you want to display
 * @param nbColsGrid - the number of columns in the grid
 * @param heightRowsGrid - the height of a row in the grid
 * @param setLayout - a function that sets the layout state
 * @param widgetEditable - boolean
 * @param isDownNbColsGrid - boolean
 * @param elements - the array of elements that are being rendered
 * @param widgetMinSize - the minimum size of a widget
 */
const updateLayout = (contents, nbColsGrid, heightRowsGrid, setLayout, widgetEditable, isDownNbColsGrid, elements, widgetMinSize, orientation) => {
  const newLayout = contents.map((element, i) => {
    const widthSizeElement = getSizeElement('width', element);
    const heightSizeElement = getSizeElement('height', element);
    const elementType = prop('type', element);

    const roundWidth = ifElse(
      equals(true),
      always(round(widthSizeElement / widthOneGrid(nbColsGrid, orientation))),
      always(ceil(widthSizeElement / widthOneGrid(nbColsGrid, orientation)))
    )(isDownNbColsGrid);

    const width = ifElse(
      allPass([equals(true), () => !equals(nbColsGrid, 1), () => !equals(roundWidth, nbColsGrid), () => gt(roundWidth, 1)]),
      always(roundWidth - 1),
      always(roundWidth)
    )(isDownNbColsGrid);

    const minHeightElement = 1;

    return {
      i,
      x: element.position.x / widthOneGrid(nbColsGrid, orientation),
      y: element.position.y / heightRowsGrid,
      h: is(Number, heightSizeElement) ? heightSizeElement / heightRowsGrid : minHeightElement,
      w: is(Number, widthSizeElement) ? width : nbColsGrid,
      maxW: nbColsGrid,
      minW: getMinWidth(elementType, widthOneGrid(nbColsGrid, orientation), widgetMinSize),
      minH: minHeightElement,
      isDraggable: widgetEditable,
      isResizable: widgetEditable && elementResizable(element, elements),
      isBounded: widgetEditable,
      uuid: prop('uuid', element)
    };
  });
  setLayout(newLayout);
};

/**
 * It takes a list of widgets and an id, and returns the height of the widget with the given id
 * @param widgetsList - The list of widgets
 * @param id - the id of the widget you want to get the height of
 */
const widgetHeight = (widgetsList = [], id) => compose(
  ifElse(
    isNil,
    always(0),
    compose(
      (elements) => {
        const elementsTop = map(path(['y']))(elements);
        const elementsHeight = map(path(['h']))(elements);
        const heightTopElement = elementsHeight.map((e, i) => ({
          id: i,
          h: e.h,
          t: elementsTop[i]
        }));
        return compose(
          cond([
            [all(equals(elementsTop[0])), () => sort((a, b) => b - a, elementsHeight)[0]],
            [T, () => compose(
              path(['0']),
              sort((a, b) => b - a),
              (hs) => hs.map((h, i) => reduceWhile(
                equals,
                () => (h += find((t) => i === prop('id', t), heightTopElement).t),
                0,
                sort((a, b) => a - b, elementsTop)
              ))
            )(elementsHeight)]
          ])
        )(elementsTop);
      },
      map((element) => ({ h: getSizeElement('height', element), y: path(['position', 'y'], element) })),
      JSON.parse,
      path(['contents'])
    )
  ),
  () => find(propEq('id', id))(widgetsList)
);

export {
  WIDTH_A4_WITHOUT_MARGINS,
  WIDTH_LANDSCAPE_WITHOUT_MARGINS,
  editorWidth,
  widthOneGrid,
  updateLayout,
  widgetHeight
};
