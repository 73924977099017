import Moment from 'moment';
import PropTypes from 'prop-types';
import { isNil, propOr } from 'ramda';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ErrorBoundary from 'react-error-boundary';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-intl-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AuthRedirect, AuthRedirectProvider } from '@fasstech/react-auth-redirect';
import Account from './pages/account';
import Frames from './pages/frames';
import NotFound from './pages/errors/NotFound';
import Home from './pages/home';
import OfferRoutes from './pages/offer/Routes';
import Team from './pages/team';
import JoinTeam from './pages/team/components/JoinTeam';
import User from './User';
import { UserContext } from './withUser';
import ProtectedRoute from './ProtectedRoute';
import { ROLES } from './lib/rights';

Moment.locale('fr');

const FallbackComponent = ({ componentStack, error }) => (
  <div>
    <p><strong>Oops! An error occurred!</strong></p>
    <p>Here’s what we know…</p>
    <p><strong>Error:</strong> {error.toString()}</p>
    <p><strong>Stacktrace:</strong> {componentStack}</p>
  </div>
);

const Routes = ({ store }) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <User refreshInterval={60000}>
        {({ user, userTeam, teamInvitationsCount, userRefetch, teams }) => {
          return (
            <UserContext.Provider
              value={{
                user, userTeam, teamInvitationsCount, userRefetch, teams
              }}
            >
              <Provider store={store}>
                <AuthRedirectProvider>
                  <DndProvider backend={HTML5Backend}>
                    <Router>
                      <Switch>
                        <Route path="/account" component={Account}/>
                        <Route path="/join/team" exact component={JoinTeam}/>
                        <AuthRedirect
                          when={isNil(propOr(null, 'email', user))}
                          redirectRender={<Redirect to="/account/signin"/>}
                        >
                          <ProtectedRoute path="/equipe" Component={Team} roles={[ROLES.admin]} />
                          <Route path="/" exact component={Home}/>
                          <ProtectedRoute path="/offre" Component={OfferRoutes} roles={[ROLES.admin, ROLES.regular]} />
                          <ProtectedRoute path="/trames" Component={Frames} roles={[ROLES.admin, ROLES.qa]} />
                        </AuthRedirect>
                        <Route component={NotFound}/>
                      </Switch>
                    </Router>
                  </DndProvider>
                </AuthRedirectProvider>
              </Provider>
            </UserContext.Provider>
          );
        }}
      </User>
    </ErrorBoundary>
  );
};

Routes.propTypes = { store: PropTypes.object.isRequired };

export default hot(Routes);
