import { commitMutation,
  graphql } from 'react-relay';
import environment from '../..';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation DeleteFrameMutation($ids: [ID]!) {
    deleteFrame(ids: $ids) {
      ok
      error
    }
  }
`;

export default ({
  ids
}, done) => {
  const variables = { ids };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ deleteFrame }) => basicMutationOnCompleted(deleteFrame, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
