import { commitMutation,
  graphql } from 'react-relay';
import environment from '../..';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateFrameMutation($name: String!, $offerId: ID!, $offerName: String!, $version: String!, $metadata: String!, $bundleTag: String!, $description: String) {
    createFrame(name: $name, offerId: $offerId, offerName: $offerName, version: $version, metadata: $metadata, bundleTag: $bundleTag, description: $description) {
      ok
      error
      frameId
    }
  }
`;

export default ({
  name,
  offerId,
  offerName,
  version,
  metadata,
  bundleTag,
  description
}, done) => {
  commitMutation(
    environment(),
    {
      mutation,
      variables: {
        name,
        offerId,
        offerName,
        version,
        metadata,
        bundleTag,
        description
      },
      onCompleted: ({ createFrame }) => {
        const { ok, error, frameId } = createFrame;
        done(ok, error, frameId);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
