import { map, reduce } from 'ramda';
import { QStylesQuery } from '../_graphql/queries/documents/QStyles';
import useFetchQuery from './useFetchQuery';

const useStyles = () => {
  const { styles: customStyles, reFetch } = useFetchQuery({
    query: QStylesQuery,
    args: { scope: 'global' },
    dataProp: 'styles',
    defaultData: []
  });

  const styles = map(({ id, name, styles }) => ({
    id,
    name,
    styles: reduce(
      (acc, { format, value }) => ({
        ...acc,
        [format]: value
      }),
      {},
      styles
    )
  }))(customStyles);

  return { styles, reFetch };
};

export default useStyles;
