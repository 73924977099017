import '../../less/account.less';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useAuthRedirect } from '@fasstech/react-auth-redirect';
import { isNil, prop, propOr } from 'ramda';
import withUser from '../../withUser';
import SignIn from './SignIn';
import SignOut from './SignOut';
import RequestPassword from './RequestPassword';
import ResetPassword from './ResetPassword';
import NewPasswordRequired from './NewPasswordRequired';
import RejectConfirm from './RejectConfirm';
import ConfirmAccount from './ConfirmAccount';
import SignUpWithToken from './SignUpWithToken';

const userAccountExist = user => !isNil(user) && !isNil(prop('email', user));
const firstTimeUserIsConnected = user => userAccountExist(user) && userStatusIsPending(user);
const userIsConnected = user => userAccountExist(user) && !userStatusIsPending(user);
const userStatusIsPending = user => propOr('', 'status')(user) === 'pending';
const userMustUpdateIsPassword = user => propOr(false, 'resetPasswordRequired', user);

const Account = ({
  user,
  match
}) => {
  const { getRedirect } = useAuthRedirect();
  const matchUrl = prop('url', match);
  return (
    <Switch>
      <Route exact path={`${matchUrl}/signout`} component={SignOut}/>
      <Route exact path={`${matchUrl}/rejectconfirm`} component={RejectConfirm}/>
      <Route exact path={`${matchUrl}/confirm`} component={ConfirmAccount}/>
      {userIsConnected(user) && userMustUpdateIsPassword(user) &&
        <Route exact path={`${matchUrl}/newpasswordrequired`} component={NewPasswordRequired}/>}
      {firstTimeUserIsConnected(user) && <Redirect to={getRedirect('/')}/>}
      {userIsConnected(user) && <Redirect to="/" />}
      <Route exact path={`${matchUrl}/signin`} component={SignIn}/>
      {userStatusIsPending(user) && <Redirect to="/account/signin"/>}
      <Route exact path={`${matchUrl}/signup`} component={SignUpWithToken} />
      <Route exact path={`${matchUrl}/resetpasswordrequest`} component={RequestPassword}/>
      <Route exact path={`${matchUrl}/resetpassword`} component={ResetPassword}/>
    </Switch>
  );
};

export default withRouter(withUser(Account));
