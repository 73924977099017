import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation GenerateComparePdfMutation($ids: [String]!) {
    generateComparePdf(ids: $ids) {
      ok
      error
    }
  }
`;

export default ({
  ids
}, done) => {
  const variables = { ids };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ generateComparePdf }) => basicMutationOnCompleted(generateComparePdf, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
