import { commitMutation, graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateDocumentMutation($documentId: ID!, $name: String!, $pages: [DocumentSectionInput!]!, $variables: String, $padding: PaddingInput, $orientation: OrientationEnum!, $renderingEngineVersion: String, $formatPdf: formatPdfEnum,$isVariablesHighlighted:Boolean, $isHDS: Boolean) {
    updateDocument(documentId: $documentId, name: $name, pages: $pages, variables: $variables, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, formatPdf: $formatPdf,isVariablesHighlighted:$isVariablesHighlighted, isHDS: $isHDS) {
      ok
      error
    }
  }
`;

export default ({
  documentId,
  name,
  pages,
  variables,
  padding,
  orientation,
  renderingEngineVersion,
  formatPdf,
  isVariablesHighlighted,
  isHDS
}, done) => {
  const args = {
    documentId,
    name,
    pages,
    variables,
    padding,
    orientation,
    renderingEngineVersion,
    formatPdf,
    isVariablesHighlighted,
    isHDS
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables: args,
      onCompleted: ({ updateDocument }) => basicMutationOnCompleted(updateDocument, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
