import { DeleteOutlined, MenuOutlined, SlidersOutlined } from '@ant-design/icons';
import { Badge, Button, Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import { isNotEmpty, isNotNil, notEqual } from 'ramda-adjunct';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { addIndex, compose, equals, filter, has, map, not, path, pathOr, prop, propOr } from 'ramda';
import DocumentRules from '../../../rules/DocumentRules';
import PaddingInfo from '../PaddingInfo';
import WidgetForm from './WidgetForm';

const mapIndexed = addIndex(map);

const WidgetCard = ({ rowIndex, addRowWidget, moveRowWidget, children, widgetRow, updatePaddingRow, userIsAdmin }) => {
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'CARD',
    hover (item, monitor) {
      const current = prop('current', previewRef);
      if (!current) return;
      const dragIndex = prop('index', item);
      const hoverIndex = rowIndex;
      if (equals(dragIndex, hoverIndex)) return;
      const hoverBoundingRect = current.getBoundingClientRect();
      const hoverMiddleY = (prop('bottom', hoverBoundingRect) - prop('top', hoverBoundingRect)) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = prop('y', clientOffset) - prop('top', hoverBoundingRect);
      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) return;
      moveRowWidget(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() })
  });

  const [, drag, preview] = useDrag({
    type: 'CARD',
    item: { index: rowIndex }
  });

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <div className={classNames('p-3 border rounded border-transparent hover:bg-gray-100', { 'mt-1': rowIndex > 0 })}>
      <PaddingInfo updatePadding={updatePaddingRow(rowIndex)} element={widgetRow} />
      <div ref={previewRef} className="flex" data-handler-id={handlerId}>
        {userIsAdmin && (
          <MenuOutlined ref={dragRef} className="flex items-center mr-2 cursor-grab"/>
        )}
        <div className="flex flex-col w-full">
          <div className="w-full flex flex-col">
            {children}
          </div>
          {userIsAdmin && (
            <a
              className="text-md text-flexibranche-lightblue font-medium w-fit"
              onClick={() => addRowWidget(rowIndex)}
            >
              + ajouter un widget sur la ligne
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const WidgetsForm = ({
  widgets,
  updateWidget,
  removeWidget,
  getWidgetVariables,
  globalVariables,
  getRulesAreValid,
  getConditionsAreValid,
  widgetsList,
  addWidgetRule,
  removeWidgetRule,
  updateWidgetRule,
  addRowWidget,
  scope,
  itemPathPrefix,
  setWidgetIdEditing,
  setRowIndexEditing,
  moveRowWidget,
  updateManualBreakPage,
  updatePaddingRow,
  type,
  addWidget,
  disabled,
  hasSectionBreakPage,
  setIsDynamicTableInSection,
  addManualBreakPageRule,
  removeManualBreakPageRule,
  updateManualBreakPageRule,
  setIsOpenCreateWidgetModal,
  setDuplicateWidgetPlacement,
  setWidgetCreated,
  userIsAdmin,
  setIsOpenCreateGlobalVariableModal
}) => {
  const [showRules, setShowRules] = useState(false);
  const [widgetsFiltered, setWidgets] = useState([]);
  useEffect(() => setWidgets(filter(w => isNotEmpty(prop('widgets', w)), widgets)), [widgets]);
  return (
    <div className="mr-2">
      {mapIndexed((widgetRow, rowIndex) => {
        const rowWidgets = propOr([], 'widgets', widgetRow);
        const rowWidgetsId = compose(
          map(path(['widgetId'])),
          filter(has('widgetId'))
        )(rowWidgets);
        const canBreakPageBetween = isNotNil(widgetsFiltered[rowIndex - 1]);
        const hasBreakPage = pathOr(false, ['breakPage'], widgetRow);
        const globalVariablesForRules = map((v) => ({ type: prop('type', v), key: prop('name', v) }), globalVariables);

        const displayWidgetOptions = userIsAdmin && canBreakPageBetween && equals('content', type);

        return (
          <div key={`selectWidgetRow${rowIndex}`}>
            {displayWidgetOptions && (
              <div className="relative my-4">
                {hasBreakPage ? (
                  <div className="flex flex-col">
                    <div className="inline-flex w-full">
                      <div className="w-full flex justify-center">
                        <hr className="absolute top-3 z-0 h-0 border-gray-200 border-dotted border-t-2 w-10/12" />
                        <span className="bg-white shadow-none w-fit relative z-10 px-3 text-xs text-flexibranche-darkgray">Saut de page manuel</span>
                      </div>
                      <div className="inline-flex space-x-4 mr-3 items-center">
                        <Badge
                          dot={isNotEmpty(prop('rulesBreakPage', widgetRow))}
                          color={isNotEmpty(prop('rulesBreakPage', widgetRow)) ? '#0197DC' : undefined}
                        >
                          <Tooltip
                            title="Ajouter une règle"
                            color="#0197DC"
                          >
                            <SlidersOutlined
                              onClick={() => setShowRules(not)}
                              rotate={90}
                            />
                          </Tooltip>
                        </Badge>
                        <Tooltip
                          title="Supprimer le saut de page"
                          color="#0197DC"
                        >
                          <DeleteOutlined
                            onClick={() => updateManualBreakPage(rowIndex, false)}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    {showRules && (
                      <div className="ml-4">
                        <DocumentRules
                          variables={globalVariablesForRules}
                          rules={prop('rulesBreakPage', widgetRow)}
                          rulesAreValid={getRulesAreValid(prop('rulesBreakPage', widgetRow))}
                          getConditionsAreValid={getConditionsAreValid}
                          onAdd={() => addManualBreakPageRule(rowIndex)}
                          onRemove={removeManualBreakPageRule(rowIndex)}
                          onUpdate={updateManualBreakPageRule(rowIndex)}
                          userIsAdmin={userIsAdmin}
                          setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="ml-3 rounded-full">
                      <Tooltip title="Ajouter une ligne">
                        <Button
                          size="small"
                          className="rounded-full"
                          onClick={() => addWidget(rowIndex)}
                          disabled={disabled}
                        >
                          +
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="bg-white shadow-none w-fit mt-1 ml-3 relative z-10 px-1 switch-break-page-manual">
                      <Switch
                        size="small"
                        checked={hasBreakPage}
                        onChange={() => updateManualBreakPage(rowIndex, true)}
                      />
                      <span
                        className="inline-block align-middle ml-2 cursor-pointer"
                        onClick={() => updateManualBreakPage(rowIndex, !hasBreakPage)}
                      >
                        Saut de page manuel
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
            <WidgetCard
              rowIndex={rowIndex} addRowWidget={addRowWidget} moveRowWidget={moveRowWidget}
              widgetRow={widgetRow} updatePaddingRow={updatePaddingRow} userIsAdmin={userIsAdmin}
            >
              {mapIndexed(({ widgetId, rules }, index) => (
                <WidgetForm
                  rowWidgetsId={rowWidgetsId}
                  key={`selectWidget${rowIndex}-${index}`}
                  index={index}
                  widgetId={widgetId}
                  scope={scope}
                  itemPathPrefix={itemPathPrefix}
                  rowIndex={rowIndex}
                  widgetsList={widgetsList}
                  rules={rules}
                  getWidgetVariables={getWidgetVariables}
                  globalVariables={globalVariables}
                  getRulesAreValid={getRulesAreValid}
                  getConditionsAreValid={getConditionsAreValid}
                  addWidgetRule={addWidgetRule}
                  removeWidgetRule={removeWidgetRule}
                  updateWidgetRule={updateWidgetRule}
                  updateWidget={updateWidget}
                  removeWidget={removeWidget}
                  setWidgetIdEditing={setWidgetIdEditing}
                  setRowIndexEditing={setRowIndexEditing}
                  canRemove={notEqual('content', type) || (notEqual(0, rowIndex) || widgetsFiltered.length > 1 || rowWidgets.length > 1)}
                  type={type}
                  hasSectionBreakPage={hasSectionBreakPage}
                  setIsDynamicTableInSection={setIsDynamicTableInSection}
                  setIsOpenCreateWidgetModal={setIsOpenCreateWidgetModal}
                  setDuplicateWidgetPlacement={setDuplicateWidgetPlacement}
                  setWidgetCreated={setWidgetCreated}
                  userIsAdmin={userIsAdmin}
                  setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
                />
              ))(rowWidgets)}
            </WidgetCard>

          </div>
        );
      })(widgetsFiltered)}
    </div>
  );
};

export default WidgetsForm;
