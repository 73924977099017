import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateOfferMutation($offerName: String!) {
    createOffer(offerName: $offerName) {
      ok
      error
    }
  }
`;

export default ({ offerName }, done) => {
  const variables = { offerName };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createOffer }) => basicMutationOnCompleted(createOffer, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
