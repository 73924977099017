import React from 'react';
import { Collapse as CollapseAntd } from 'antd';
import { isNotEmpty } from 'ramda-adjunct';
const { Panel } = CollapseAntd;

const Collapse = ({
  title,
  children,
  actions,
  setCollapseIsOpen,
  defaultActiveKey,
  className,
  collapseIsOpen
}) => {
  const onChange = (key) => setCollapseIsOpen && setCollapseIsOpen(isNotEmpty(key));
  return (
    <CollapseAntd
      activeKey={collapseIsOpen ? '1' : ''}
      defaultActiveKey={defaultActiveKey}
      bordered={false}
      className={`!bg-white !mb-4 px-8 py-4 my-6 relative ${className}`}
      onChange={onChange}
      expandIconPosition="start"
    >
      <Panel
        key="1"
        header={(
          <h2
            className="uppercase mb-0 font-bold text-lg text-flexibranche-blue"
          >{title}
          </h2>
        )}
        extra={(
          <div className="flex gap-4" onClick={(e) => e.stopPropagation()}>
            {actions}
          </div>
        )}
      >
        {children}
      </Panel>
    </CollapseAntd>
  );
};

export default Collapse;
