import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import fetch from 'node-fetch';
import SignUp from './SignUp';

const SignUpWithToken = (props) => {
  const token = new URLSearchParams(props.location.search).get('token');
  const [isValidToken, setIsValidToken] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await fetch(`/user/verify-token?token=${token}`, { headers: { Authorization: `Bearer ${token}` } });
        if (response.status === 200) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        setIsValidToken(false);
      }
    };
    if (token) {
      verifyToken();
    } else {
      setIsValidToken(false);
    }
  }, [token]);

  if (isValidToken === null) { return <div>Loading...</div>; }
  if (!isValidToken) { return <Redirect to="/account/signin" />; }

  return <SignUp {...props} />;
};

export default SignUpWithToken;
