import React from 'react';
import { propOr, compose, flatten, pluck, groupBy, prop, either, isNil, isEmpty, map, reject, length, nth, keys } from 'ramda';
import { Collapse, List, Badge } from 'antd';
import Section from './Section';

const getTypeLabel = type => propOr(type, type, { document: 'Documents' });

const getStatusData = status => propOr({ label: status, color: 'white' }, status, {
  pending: {
    label: 'À traiter',
    color: '#ffd88c'
  },
  accepted: {
    label: 'Validé',
    color: '#00b800'
  },
  rejected: {
    label: 'Rejeté',
    color: '#ff4040'
  }
});

const useItemComments = (comments) => {
  const allComments = compose(
    flatten,
    pluck('comments')
  )(comments);

  const groupedComments = groupBy(prop('type'))(comments);

  return {
    allComments,
    groupedComments
  };
};

const ItemCommentStatus = ({ status, className = '' }) => {
  return (
    <span
      className={`rounded-xl text-xs px-2 text-center w-90px mt-1 ${className}`}
      // eslint-disable-next-line no-restricted-syntax
      style={{ backgroundColor: getStatusData(status).color }}
    >
      {getStatusData(status).label}
    </span>
  );
};

const ItemComments = ({
  comments,
  title
}) => {
  const {
    allComments,
    groupedComments
  } = useItemComments(comments);

  if (either(isNil, isEmpty)(comments) || either(isNil, isEmpty)(allComments)) {
    return null;
  }

  return (
    <Section
      title={title}
      // eslint-disable-next-line no-restricted-syntax
      style={{ lineHeight: 'unset' }}
      titleStyle={{ marginBottom: '0' }}
    >

      <Collapse bordered={false}>
        {compose(
          map(type => {
            const itemsComments = propOr({}, type, groupedComments);

            if (isEmpty(itemsComments)) {
              return null;
            }

            return (
              <Collapse.Panel
                key={`item-comments-${type}`}
                header={getTypeLabel(type)}
              >
                <Collapse>
                  {map(itemComment => {
                    let {
                      itemId,
                      name,
                      comments = []
                    } = itemComment;

                    comments = reject(isNil, comments);

                    if (length(comments) === 0) {
                      return null;
                    }

                    return (
                      <Collapse.Panel
                        key={`item-comments-${type}-${itemId}`}
                        header={name}
                        extra={<Badge count={length(comments)}/>}
                      >

                        <List
                          bordered
                          size="small"
                          dataSource={comments}
                          renderItem={comment => {
                            const {
                              id,
                              status,
                              messages = []
                            } = comment;

                            const firstMessage = nth(0, messages);

                            if (isNil(firstMessage) || isNil(firstMessage.text)) {
                              return null;
                            }

                            return (
                              <List.Item
                                key={`item-comments-${type}-${itemId}-${id}`}
                              >
                                <List.Item.Meta
                                  title={firstMessage.text}
                                  description={(
                                    <div className="text-black">
                                      <span className="text-sm">(<b>{prop('userName', firstMessage)}</b>)&nbsp;</span>

                                      <ItemCommentStatus
                                        status={status}
                                      />
                                    </div>
                                  )}
                                />
                              </List.Item>
                            );
                          }}
                        />

                      </Collapse.Panel>
                    );
                  })(itemsComments)}
                </Collapse>
              </Collapse.Panel>
            );
          }),
          keys
        )(groupedComments)}
      </Collapse>

    </Section>
  );
};

export default ItemComments;
