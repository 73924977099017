import { Avatar } from 'antd';
import { compose, take, toUpper } from 'ramda';
import React from 'react';

const AvatarEmail = ({ email }) => {
  const firstChar = compose(
    toUpper,
    take(1)
  )(email);

  return (
    <>
      <Avatar className="mr-2.5">{firstChar}</Avatar>
      <span className="text-black">{email}</span>
    </>
  );
};

export default AvatarEmail;
