import { filter, map } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import { isNotNil } from 'ramda-adjunct';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation DefineActiveTeamMutation($teamId: ID!, $userId: ID!) {
    defineActiveTeam(teamId: $teamId, userId: $userId) {
      ok
      error
    }
  }
`;

export default ({ teamId, userId, teamName, apiKey }, done) => {
  const variables = {
    teamId,
    userId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { defineActiveTeam: { ok, error } }) => {
        if (ok && !error) {
          const updateActive = (root, team, active) => {
            if (isNotNil(team)) {
              const teamMembers = team.getLinkedRecords('members');
              if (isNotNil(teamMembers)) {
                const members = map(member => {
                  const user = member.getLinkedRecord('user');

                  if (isNotNil(user)) {
                    const id = user.getValue('id');
                    if (id !== userId) return member;
                  }

                  member.setValue(active, 'active');
                  return member;
                })(filter(isNotNil, teamMembers));

                team.setLinkedRecords(members, 'members');
              }

              if (active && isNotNil(team)) {
                root.setValue(teamId, 'teamId');
                root.setValue(teamName, 'name');
                root.setValue(apiKey, 'apiKey');
                const members = team.getLinkedRecords('members');
                if (isNotNil(members)) root.setLinkedRecords(members, 'members');
              }
            }
          };

          const root = proxyStore.get('userTeam');
          const formerTeamId = root.getValue('teamId');

          const team = proxyStore.get(teamId);
          const formerTeam = proxyStore.get(formerTeamId);

          if (isNotNil(team)) updateActive(root, team, true);
          if (isNotNil(formerTeam)) updateActive(root, formerTeam, false);
        }
      },
      onCompleted: ({ defineActiveTeam }) => basicMutationOnCompleted(defineActiveTeam, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
