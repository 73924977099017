import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QGetAllFramesQuery = graphql`
  query QGetAllFramesQuery($teamId: ID!) {
    allFrames(teamId: $teamId) {
      id
      name
      pdfIds
      teamId
      offerId
      offerName
      metadata
      bundleTag
      version
      description
      isReferent
      versionFlexidocs
      comment
      who {
        creation
        lastUpdate
      }
      dates {
        creation
        lastUpdate
      }
      comparisons {
        id
        globalStatusResult
        when
        comparisonDetails {
          status
          diffPngsIds
          referentPdfId
          actualPdfId
          fileName
        }
      }
    }
  }
`;

const QGetAllFrames = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QGetAllFramesQuery}
      caching={false}
      args={args}
      isCompareFrame
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export default QGetAllFrames;
