import { propOr } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { useState, useEffect } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import * as pdfjs from 'pdfjs-dist';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { QFileQuery } from '../_graphql/queries/documents/QFile';
import { base64toBlob } from '../pages/helpers/base64ToBlob';
import { getFileName } from '../utils/getFilename';

const useFrameSynch = ({ fileId, frameRef, otherFrameRef, showDiff }) => {
  const [fileData, setFileData] = useState({});
  const [fileDataReturned, setFileDataReturned] = useState({});
  const [iframeHeight, setIframeHeight] = useState(50000);
  const [sourceElement, setSourceElement] = useState(null);
  const [targetElement, setTargetElement] = useState(null);
  const environment = useRelayEnvironment();

  useEffect(() => {
    const fetchPdf = async () => {
      const { getFile } = await fetchQuery(environment, QFileQuery, { fileId }).toPromise();
      setFileData(getFile);
    };

    if (fileId) fetchPdf();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (frameRef) setSourceElement(frameRef.current);
    if (otherFrameRef) setTargetElement(otherFrameRef.current);
  }, [frameRef, otherFrameRef, showDiff]);

  useEffect(() => {
    const base64PdfString = propOr('', 'fileBase64', fileData);
    const fileName = getFileName(propOr('', 'fileName', fileData));
    const IMAGE_SRC = `data:image/png;base64,${base64PdfString}`;
    const pdfBlob = base64toBlob(base64PdfString, 'application/pdf');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setFileDataReturned({
      fileName,
      IMAGE_SRC,
      pdfUrl
    });

    const setIframeHeightFromPageCount = async (pdfUrl) => {
      pdfjs.GlobalWorkerOptions.workerSrc = PDFJSWorker;
      try {
        const pdfDocument = await pdfjs.getDocument({ url: pdfUrl }).promise;
        const numPages = pdfDocument.numPages;
        const iframeHeight = numPages * 990; // We estimate that each page is ~990px height in order to avoid big blank space at the end of the iframe container
        setIframeHeight(iframeHeight);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('pdfjs', error.message);
      }
    };

    setIframeHeightFromPageCount(pdfUrl);
  }, [fileData]);

  const synchronizeScrolling = (sourceElement, targetElement) => {
    if (isNotNilOrEmpty(sourceElement) && isNotNilOrEmpty(targetElement)) {
      const scrollY = sourceElement.scrollTop;
      targetElement.scrollTop = scrollY;
    }
  };

  return {
    iframeHeight,
    sourceElement,
    targetElement,
    fileDataReturned,
    synchronizeScrolling
  };
};

export default useFrameSynch;
