import React from 'react';
import { Modal } from 'antd';
import StyleFormPreview from '../../../elements/styles/components/StyleFormPreview';

const EditStyleModal = ({
  show,
  onCancel
}) => {
  return (
    <Modal
      visible={show}
      footer={null}
      onCancel={onCancel}
      width="90%"
      destroyOnClose
    >
      <StyleFormPreview displayBackBtn={false} closeModal={onCancel} />
    </Modal>
  );
};

export default EditStyleModal;
