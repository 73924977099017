import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateStyleMutation($style: StyleInput!) {
    createStyle(style: $style) {
      ok
      error
      styleId
    }
  }
`;

export default ({
  style
}, done) => {
  const variables = { style };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createStyle }) => {
        const { ok, error, styleId } = createStyle;
        done(ok, error, styleId);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
