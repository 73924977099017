import React from 'react';
import { QOffer } from '../_graphql/queries';

const withOffer = Component => {
  return function WithComponent ({
    offerId,
    ...restProp
  }) {
    return (
      <QOffer args={{ offerId }}>
        {offer => <Component offerId={offerId} offer={offer} {...restProp}/>}
      </QOffer>
    );
  };
};

export default withOffer;
