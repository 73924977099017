import React from 'react';
import { useParams } from 'react-router-dom';
import History from '../../components/History';
import QDocument from '../../_graphql/queries/documents/QDocument';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';

const DocumentHistory = ({ userTeam }) => {
  const { documentId } = useParams();
  return (
    <QDocument args={{ documentId }}>
      {data => {
        const { document = {} } = data;
        const accessDenied = verifyRouteAccess(userTeam, document, 'l\'historique du document');
        if (accessDenied) return accessDenied;
        return (
          <History id={documentId} type="document" />
        );
      }}
    </QDocument>
  );
};

export default withUser(DocumentHistory);
