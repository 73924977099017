import { Select, Tag } from 'antd';
import React from 'react';
import { compose, defaultTo, uniq, pluck, filter, propSatisfies, includes, map } from 'ramda';
import QTags from '../../../../_graphql/queries/documents/QTags';
import useDocumentsTags from '../../../../hooks/useDocumentsTags';
import { SCOPE_OFFER } from '../../../../_CONST';

const SelectTags = ({
  offerId,
  documentId
}) => {
  const {
    onAddItem,
    onRemoveItem
  } = useDocumentsTags(documentId);

  return (
    <QTags
      args={{
        scope: SCOPE_OFFER, offerId, itemType: 'document'
      }}
    >
      {tags => {
        tags = defaultTo([])(tags);

        const defaultTags = compose(
          uniq,
          pluck('id'),
          filter(
            propSatisfies(
              compose(
                includes(documentId),
                defaultTo([])
              ),
              'itemsIds'
            )
          )
        )(tags);

        return (
          <Select
            mode="multiple"
            showArrow
            placeholder="Sélectionner les tags"
            className="w-400px"
            onSelect={onAddItem}
            onDeselect={onRemoveItem}
            defaultValue={defaultTags}
          >
            {map(tag => {
              const { id, name, color } = tag;

              return (
                <Select.Option
                  key={id}
                  value={id}
                  className="w-full"
                >
                  <Tag color={color} className="max-w-full text-ellipsis whitespace-nowrap overflow-hidden" title={name}>
                    {name}
                  </Tag>
                </Select.Option>
              );
            })(tags)}
          </Select>
        );
      }}
    </QTags>
  );
};

export default SelectTags;
