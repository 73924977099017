import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Collapse } from '../../../components';
import GlobalVariablesTable from '../../docs/components/globalVariable/GlobalVariablesTable';
import QGlobalVariables from '../../../_graphql/queries/documents/QGlobalVariables';
import { SCOPE_OFFER } from '../../../_CONST';

const GlobalVariablesSection = ({
  offerId,
  userCanEditOffer,
  version
}) => {
  const history = useHistory();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  return (
    <Collapse
      className="section-global-variable"
      collapseIsOpen={collapseIsOpen}
      setCollapseIsOpen={setCollapseIsOpen}
      title="Variables globales de l'offre"
      actions={userCanEditOffer && (
        <Button
          onClick={() => history.push(`/offre/${offerId}/edition/variables-globales/creer`)}
          icon={<PlusOutlined />}
        >
          Créer une variable globale
        </Button>
      )}
    >
      <QGlobalVariables
        args={{
          offerId,
          scope: SCOPE_OFFER
        }}
      >
        {(globalVariables) => {
          return (
            <GlobalVariablesTable
              globalVariables={globalVariables}
              userCanEditOffer={userCanEditOffer}
              version={version}
              offerId={offerId}
            />
          );
        }}
      </QGlobalVariables>
    </Collapse>
  );
};

export default GlobalVariablesSection;
