import React, { useContext } from 'react';
import { prop } from 'ramda';
import QGetAllFrames from '../../_graphql/queries/documents/QGetAllFrames';
import { UserContext } from '../../withUser';
import FrameForm from './components/FrameForm';

const CreateFrame = () => {
  const { userTeam } = useContext(UserContext);
  return (
    <QGetAllFrames args={{ teamId: prop('teamId', userTeam) }}>
      {({ allFrames }) => <FrameForm allFrames={allFrames} />}
    </QGetAllFrames>
  );
};

export default CreateFrame;
