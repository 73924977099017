import React from 'react';
import withUser from '../../withUser';
import Layout from './Layout';
import Sider from './Sider';
import Top from './Top';

const DefaultLayout = ({
  userIsSignedIn,
  menu,
  children,
  contentStyle = {}
}) => {
  return (
    <Layout>
      {userIsSignedIn && (
        <Sider collapsed={false}>
          {menu}
        </Sider>
      )}

      <div className="w-full overflow-y-hidden overflow-x-auto min-h-full max-h-full">
        <Top />

        <div
          // eslint-disable-next-line no-restricted-syntax
          style={{ ...contentStyle }}
          className="flex flex-col overflow-x-auto pt-2.5 px-38px pb-4"
        >
          {children}
        </div>
      </div>
    </Layout>
  );
};

export default withUser(DefaultLayout);
