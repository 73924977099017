import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
    mutation UpdateTeamNameMutation($name: String!) {
      updateTeamName(name: $name) {
        ok
        error
      }
    }
  `;

export default ({ name }, done) => {
  const variables = { name };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateTeamName }) => basicMutationOnCompleted(updateTeamName, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
