import React from 'react';
import { isNil, is, propOr } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import { Timeline } from 'antd';
import moment from 'moment';
import { Dot } from '../components';
import QVersionActions from '../_graphql/queries/QVersionActions';
import { Glasses, BriefCase, CheckCircle, Cross, User } from './icons';

const formatDate = date => {
  if (isNil(date)) {
    return '';
  }

  if (is(String, date)) {
    date = parseInt(date);
  }

  return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

const renderNewVersionMetadata = ({ newVersion = false, minor, major }) => {
  if (!newVersion) {
    return null;
  }

  return (
    <span className="inline-flex ml-1.5">
      « Nouvelle version ({major}.{minor}) »
    </span>
  );
};

const getAction = action => propOr({ actionLabel: action }, action, {
  CREATE_OFFER: {
    actionLabel: 'Offre créée',
    renderMetadatas: renderNewVersionMetadata,
    actionColor: '#0197dc',
    icon: <BriefCase />
  },
  CREATE_OFFER_FROM_EXISTING: {
    actionLabel: 'Offre créée à partir d\'une autre',
    actionColor: '#0197dc',
    icon: <BriefCase />
  },
  CREATE_WIDGET: {
    actionLabel: 'Widget créé',
    renderMetadatas: renderNewVersionMetadata,
    actionColor: '#0197dc',
    icon: <BriefCase />
  },
  UPDATE_WIDGET: {
    actionLabel: 'Widget mis à jour',
    actionColor: '#0197dc',
    icon: <CheckCircle />
  },
  CREATE_DOCUMENT: {
    actionLabel: 'Document créé',
    renderMetadatas: renderNewVersionMetadata,
    actionColor: '#0197dc',
    icon: <BriefCase />
  },
  UPDATE_DOCUMENT: {
    actionLabel: 'Document mis à jour',
    actionColor: '#0197dc',
    icon: <CheckCircle />
  },
  CREATE_STYLE: {
    actionLabel: 'Style créé',
    renderMetadatas: renderNewVersionMetadata,
    icon: <BriefCase />
  },
  UPDATE_STYLE: {
    actionLabel: 'Style mis à jour',
    icon: <CheckCircle />
  },
  ACCEPT_REVIEW: {
    actionLabel: 'Revue acceptée',
    actionColor: '#61c397',
    icon: <CheckCircle />
  },
  REJECT_REVIEW: {
    actionLabel: 'Revue refusée',
    actionColor: '#e03a3a',
    icon: <Cross />,
    // eslint-disable-next-line react/display-name
    renderMetadatas: ({ rejectionReason }) => (
      <span className="inline-flex ml-1.5 text-red-500">
        « {rejectionReason} »
      </span >
    )
  },
  UPDATE_REVIEW_COMMENTS: {
    actionLabel: 'Commentaires de revue mis à jour',
    actionColor: '#8a98a5',
    icon: <CheckCircle />,
    // eslint-disable-next-line react/display-name
    renderMetadatas: ({ comments }) => <p className="italic text-gray-700">{comments}</p>
  },
  UPDATE_TO_DRAFT_MODE: {
    actionLabel: 'Passage en mode édition',
    actionColor: '#8a98a5',
    icon: <Glasses />
  },
  UPDATE_TO_REVIEW_MODE: {
    actionLabel: 'Passage en mode revue',
    actionColor: '#8a98a5',
    icon: <Glasses />
  },
  UPDATE_TO_ARCHIVED_MODE: {
    actionLabel: 'Offre archivée',
    actionColor: '#8a98a5',
    icon: <CheckCircle />
  }
});

const VersionHistory = ({ versionId, itemId }) => {
  return (
    <QVersionActions args={{ versionId, itemId }}>
      {versionActions => {
        return (
          <Timeline mode="left">
            {mapIndexed(({ date, action, userName, metadata }, index) => {
              return (
                <Timeline.Item
                  key={date}
                  label={formatDate(date)}
                  dot={<Dot color={getAction(action).actionColor} width={index === 0 ? 14 : 10} />}
                >
                  <div className="inline-flex items-center text-[14px] font-semibold">
                    <div
                      // eslint-disable-next-line no-restricted-syntax
                      style={{ color: getAction(action).actionColor === '#8a98a5' ? '#000' : getAction(action).actionColor }}
                    >
                      <span className="text-[16px] mr-2.5 inline-block -translate-y-1">
                        {getAction(action).icon}
                      </span>
                      <span>
                        {getAction(action).actionLabel}
                        {
                          !isNil(getAction(action).renderMetadatas) &&
                    is(Function, getAction(action).renderMetadatas) &&
                    getAction(action).renderMetadatas(metadata || {})
                        }
                      </span>
                    </div>
                    <span className="text-flexibranche-darkgray font-light ml-5 inline-flex items-center gap-1.5">
                      <User />
                      {userName}
                    </span>
                  </div>
                </Timeline.Item>
              );
            })(versionActions)}
          </Timeline>
        );
      }}
    </QVersionActions>
  );
};

export default VersionHistory;
