import React from 'react';

const PreviewColor = ({ color, className = '' }) => {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <div
        className="h-18px w-18px rounded-lg"
        // eslint-disable-next-line no-restricted-syntax
        style={{ backgroundColor: color }}
      />
      <div
        className="uppercase"
        // eslint-disable-next-line no-restricted-syntax
        style={{ color: color }}
      >
        {color}
      </div>
    </div>
  );
};

export default PreviewColor;
