import qs from 'querystring';
import React from 'react';
import { prop } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Breadcrumb } from '../../components';
import Menu from '../../containers/menu';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import Layout from '../../layout/default';
import withUser from '../../withUser';
import ElementsRoutes from '../elements/Routes';
import CreateGroup from '../groups/CreateGroup';
import EditGroup from '../groups/EditGroup';
import { home } from '../helpers/breadCrumb';
import ManageRole from './components/ManageRole';
import MyTeam from './components/MyTeam';
import NewTeam from './components/NewTeam';

const myTeam = ['/equipe', 'Mon équipe'];
const manageTeam = ['/gestion', 'Gérer mon équipe'];
const createGroup = ['/groupes/creer', 'Créer un groupe'];
const editGroup = ({ groupId }) => [`/groupes/${groupId}`, 'Gestion du groupe'];

const query = queryString => qs.parse(queryString.replace('?', ''));

const routes = [
  ['/equipe/gestion', [home, myTeam, manageTeam]],
  ['/equipe/groupes/creer', [home, myTeam, createGroup]],
  ['/equipe/groupes/:groupId', [home, myTeam, editGroup]],
  ['/equipe', [home, myTeam]]
];

const Team = ({ userTeam, match }) => {
  const breadcrumb = useBreadcrumb(routes, home);
  const hasTeam = isNotNil(prop('teamId', userTeam));
  const matchUrl = prop('url', match);
  return (
    <>
      {hasTeam ? (
        <Switch>
          <Route path="/equipe/elements" component={ElementsRoutes}/>

          <Layout menu={<Menu/>}>
            {hasTeam && <Breadcrumb value={breadcrumb}/>}
            <Route exact path={matchUrl} component={MyTeam}/>
            <Route path={`${matchUrl}/groupes/creer`} component={CreateGroup}/>
            <Route path={`${matchUrl}/groupes/:groupId/gestion`} component={EditGroup}/>
            <Route
              path={`${matchUrl}/gestion`}
              render={({ location }) => {
                const { member } = query(location.search);
                return <ManageRole memberId={member}/>;
              }}
            />
          </Layout>
        </Switch>
      ) : (
        <NewTeam/>
      )}
    </>
  );
};

export default withRouter(withUser(Team));
