import { equals, map } from 'ramda';
import React, { useState, useRef } from 'react';
import { Alert, Tabs, Switch } from 'antd';
import { isNotEmpty } from 'ramda-adjunct';
import { Title } from '../../components';
import StatusComparison from './StatusComparison';
import FrameResult from './components/FrameResult';

const ComparisonDetails = ({ comparison }) => {
  const { comparisonDetails } = comparison;
  const [showActualPdf, setShowActualPdf] = useState(true);
  const [showDiff, setShowDiff] = useState(false);
  const [isScrollSynchronized, setIsScrollSynchronized] = useState(true);
  const referentFrameRef = useRef(null);
  const actualFrameRef = useRef(null);

  return (
    <div>
      <div className="flex flex-row items-center">
        <Title title="Comparaison" />
        <div className="flex flex-row items-center pl-4">
          <Switch checked={isScrollSynchronized} onChange={() => setIsScrollSynchronized(!isScrollSynchronized)} />
          <p className="ml-2">Scroll simultané</p>
        </div>
      </div>
      <Tabs>
        {map((comparison) => {
          const { referentPdfId, actualPdfId, status, diffPngsIds, fileName } = comparison;

          const buttonComparison = (title, show, isActual) => (
            <button
              className={`text-xl font-bold ${show ? 'text-flexibranche-blue' : 'text-gray-300'} ${equals('passed', status) && 'cursor-default'}`}
              onClick={() => {
                setShowActualPdf(isActual);
                setShowDiff(!isActual);
              }}
            >
              {title}
            </button>
          );

          return (
            <Tabs.TabPane
              key={referentPdfId}
              tab={(
                <div className="flex flex-row items-center">
                  <div className="flex justify-center items-center space-x-2">
                    <StatusComparison status={status} />
                    <h3 className="text-xl font-bold text-flexibranche-lightblue mb-0">{fileName}</h3>
                  </div>
                </div>
              )}
            >
              <div className="w-full inline-flex">
                <div className="inline-flex justify-center space-x-6 w-1/2 ">
                  <div className="flex flex-col items-center space-y-4">
                    <h4 className="text-xl font-bold text-flexibranche-blue">
                      Document référent
                    </h4>
                    <FrameResult
                      fileId={referentPdfId} isScrollSynchronized={isScrollSynchronized} frameRef={referentFrameRef}
                      otherFrameRef={actualFrameRef} showDiff={showDiff}
                    />
                  </div>
                </div>
                <div className="inline-flex justify-center mt-2 space-x-6 w-1/2 ">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="inline-flex space-x-6">
                      {buttonComparison('PDF généré', showActualPdf, true)}
                      {equals('failed', status) && buttonComparison('Différences', showDiff, false)}
                    </div>
                    {showActualPdf && (
                      <FrameResult
                        fileId={actualPdfId} isScrollSynchronized={isScrollSynchronized} frameRef={actualFrameRef}
                        otherFrameRef={referentFrameRef}
                      />
                    )}
                    {showDiff && (
                      <>
                        {isNotEmpty(diffPngsIds) ? (
                          <div className="flex flex-col items-center space-y-4 h-1000px overflow-auto">
                            <div className="overflow-auto">
                              {map((fileId) => (
                                <FrameResult
                                  fileId={fileId} isImg frameRef={actualFrameRef}
                                  otherFrameRef={referentFrameRef}
                                />
                              ))(diffPngsIds)}
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center space-y-4">
                            <Alert message="Le nombre de page du document généré est différent du document référent." type="error" showIcon />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
          );
        })(comparisonDetails)}
      </Tabs>
    </div>
  );
};

export default ComparisonDetails;
