import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QGetFrameQuery = graphql`
  query QGetFrameQuery($frameId: ID!) {
    getFrame(frameId: $frameId) {
      id
      name
      pdfIds
      teamId
      offerId
      offerName
      metadata
      bundleTag
      version
      versionFlexidocs
      isReferent
      description
      comment
      who {
        creation
        lastUpdate
        creationData {
          extra {
            firstname
            lastname
          }
        }
      }
      dates {
        creation
        lastUpdate
      }
      comparisons {
        globalStatusResult
        when
        id
        comparisonDetails {
          status
          diffPngsIds
          referentPdfId
          actualPdfId
          fileName
        }
      }
    }
  }
`;

const QGetFrame = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QGetFrameQuery}
      caching={false}
      args={args}
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export default QGetFrame;
