import { compose, apply, map, prop, reduce, add, ifElse, isEmpty, always, has, filter, sum, isNil, path, find, propEq, flatten, forEach } from 'ramda';
import { widgetHeight } from './widget';

const HEIGHT_MAX_A4_WITHOUT_MARGINS = 1123; // max height A4

/**
 * It takes a list of pages, and returns a list of heights for each page
 * @param widgetsHeader - header widgets
 * @param pagesOnSection - an array of pages, each page is an array of widgets
 * @param widgetsFooter - footer widgets
 * @param widgetsList - the list of all widgets in the section
 * @param padding - {top: number, bottom: number}
 */
const getPagesHeight = (widgetsHeader, pagesOnSection, widgetsFooter, widgetsList, padding) => {
  const getWidgetHeight = (rowWidgets) => compose(
    apply(Math.max),
    map((widget) => {
      if (rowWidgets.length > 1) {
        if (widget === rowWidgets[0]) {
          return compose(
            apply(Math.max),
            map((w) => widgetHeight(widgetsList, prop('widgetId', w))(w))
          )(rowWidgets);
        }
      }
      return widgetHeight(widgetsList, prop('widgetId', widget))(widget);
    })
  )(rowWidgets);

  const contentHeight = (widgetsPage) => compose(
    reduce(add, 0),
    map(
      compose(
        ifElse(
          isEmpty,
          always(0),
          getWidgetHeight
        ),
        filter(has('widgetId'))
      )
    ),
    map(prop('widgets'))
  )(widgetsPage);

  return map(
    compose(
      add(prop('top', padding)),
      add(prop('bottom', padding)),
      add(contentHeight(widgetsFooter)),
      add(contentHeight(widgetsHeader)),
      contentHeight
    )
  )(pagesOnSection);
};

const getPageWidth = (widgetsPage, widgetsList, padding) => compose(
  add(prop('right', padding)),
  add(prop('left', padding)),
  apply(Math.max),
  map(compose(
    sum,
    map(compose(
      ifElse(
        isNil,
        always(0),
        compose(
          apply(Math.max),
          map((element) => (path(['size', 'width'], element))),
          JSON.parse,
          path(['contents'])
        )
      ),
      (id) => find(propEq('id', id))(widgetsList)
    )),
    map(path(['widgetId'])),
    filter(has('widgetId'))
  )),
  map(path(['widgets']))
)(widgetsPage);

const getWidgetType = (widgetsPage, widgetsList) => compose(
  flatten,
  map(compose(
    map(compose(
      ifElse(
        isNil,
        always(0),
        compose(
          forEach((element) => (prop('type', element))),
          path(['variables'])
        )
      ),
      (id) => find(propEq('id', id))(widgetsList)
    )),
    map(path(['widgetId'])),
    filter(has('widgetId'))
  )),
  map(path(['widgets']))
)(widgetsPage);

export {
  HEIGHT_MAX_A4_WITHOUT_MARGINS,
  getPagesHeight,
  getPageWidth,
  getWidgetType
};
