import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { message, Popconfirm, Table, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { always, and, assoc, compose, cond, equals, filter, isNil, length, map, path, pick, prop, propEq, propOr, reject } from 'ramda';
import { Copy, Trash, Edit } from '../../../../components/icons';
import QWidgets from '../../../../_graphql/queries/documents/QWidgets';
import DeleteWidgetMutation from '../../../../_graphql/mutations/documents/DeleteWidgetMutation';
import withUser from '../../../../withUser';
import UpdateWidgetMutation from '../../../../_graphql/mutations/documents/UpdateWidgetMutation';
import Select from '../../../../components/Select';
import { renderDate } from '../helpers/dates';
import CreateWidgetMutation from '../../../../_graphql/mutations/documents/CreateWidgetMutation';
import { deleteMutationMessage, errorMessage, updateMutationMessage } from '../../../../utils/messageMutation';
import TableDates from '../tag/TableDates';
import { SCOPE_OFFER } from '../../../../_CONST';
import WidgetIsUsed from './WidgetIsUsed';

const WidgetsTable = ({
  scope,
  offerId,
  userIsAdmin,
  offerIsDraft,
  widgetsByRootOfferId = [],
  refetch,
  setRefetch,
  isTeamPath
}) => {
  const [widgetContent, setWidgetContent] = useState({});
  const history = useHistory();

  const allOffers = isNil(scope);

  const deleteWidget = (widgetId) => () => {
    setRefetch(true);
    DeleteWidgetMutation({ widgetId }, (ok, error) => deleteMutationMessage(ok, error, 'widget'));
  };

  const updateWidgetContent = () => {
    const { widget, content } = widgetContent;
    UpdateWidgetMutation({
      widget: compose(
        pick(['id', 'content', 'name']),
        assoc('content', content)
      )(widget)
    }, (ok, error) => updateMutationMessage(ok, error, 'widget'));
  };

  const userCanEditWidgets = equals(scope, SCOPE_OFFER) && offerIsDraft && userIsAdmin;

  return (
    <QWidgets
      args={{
        scope, offerId, refetch, setRefetch
      }}
    >
      {widgets => {
        return (
          <Table
            size="small"
            dataSource={reject(isNil, widgets)}
            rowKey="id"
            pagination={false}
          >
            <Table.Column
              title="Nom"
              dataIndex="name"
              defaultSortOrder="ascend"
              width={300}
              sorter={(a, b) => prop('name', a).toLowerCase().localeCompare(prop('name', b).toLowerCase())}
              render={(name, widget) => {
                const link = cond([
                  [() => and(userCanEditWidgets, isTeamPath), always(`/equipe/elements/widgets/${prop('id', widget)}/editer`)],
                  [() => and(!userCanEditWidgets, isTeamPath), always(`/equipe/elements/widgets/${prop('id', widget)}/preview`)],
                  [always(userCanEditWidgets), always(`/offre/${offerId}/widgets/${prop('id', widget)}/editer`)],
                  [always(!userCanEditWidgets), always(`/offre/${offerId}/widgets/${prop('id', widget)}/preview`)]
                ])(widget);
                return <Link to={link}>{name}</Link>;
              }}
            />

            {allOffers && (
              <Table.Column
                title="Offre"
                dataIndex="offerId"
                width={300}
                render={(offerId, widget) => {
                  if (propEq('scope', SCOPE_OFFER, widget)) {
                    return <Link to={`/offre/${offerId}/edition`}>{propOr(offerId, 'offerName', widget)}</Link>;
                  }
                  return null;
                }}
              />
            )}

            <TableDates renderDate={renderDate} />

            {userCanEditWidgets ? (
              <Table.Column
                title=""
                dataIndex="id"
                render={(id, widget) => {
                  const isUsed = propOr(false, 'isUsed', widget);
                  const isUsedBy = propOr([], 'isUsedBy', widget);
                  const onSaveDuplicateWidget = async () => {
                    const widgetsHasSameRootWidgetId = filter(w => equals(prop('id', widget), prop('rootWidgetId', w)))(widgets);
                    const lengthSameParentWidget = length(widgetsHasSameRootWidgetId);
                    const duplicateWidget = {
                      name: `${prop('name', widget)} (${lengthSameParentWidget + 1})`,
                      nbColsGrid: prop('nbColsGrid', widget),
                      heightRowsGrid: prop('heightRowsGrid', widget),
                      contents: prop('contents', widget),
                      orientation: prop('orientation', widget),
                      scope,
                      offerId,
                      frameStyle: prop('frameStyle', widget),
                      rootWidgetId: prop('id', widget)
                    };
                    const callback = successMessage => (ok, error) => {
                      if (ok && !error) {
                        message.success(successMessage);
                        setRefetch(true);
                      } else {
                        errorMessage();
                      }
                    };
                    CreateWidgetMutation({ widget: duplicateWidget }, callback('Le widget a bien été dupliqué.'));
                  };

                  return (
                    <div className="flex justify-end gap-2 text-lg">
                      <Tooltip
                        title="Éditer le widget"
                        color="#0197DC"
                      >
                        <Edit
                          onClick={() => history.push(`/offre/${offerId}/widgets/${id}/editer`)}
                        />
                      </Tooltip>

                      {widget.rootWidgetId && (
                        <Popconfirm
                          title={(
                            <div className="flex flex-col w-72">
                              <p>Sélectionner la version à copier</p>
                              <Select
                                getPopupContainer={(node) => prop('parentNode', node)}
                                className="w-full"
                                onChange={e => setWidgetContent({ widget, content: path(['target', 'value'], e) })}
                              >
                                {map(widget => (
                                  <Select.Option
                                    key={prop('id', widget)}
                                    value={prop('content', widget)}
                                  >
                                    {prop('name', widget)} - {path(['offerVersion', 'major'], widget)}.{path(['offerVersion', 'minor'], widget)}
                                  </Select.Option>
                                ))(widgetsByRootOfferId)}
                              </Select>
                            </div>
                          )}
                          onConfirm={updateWidgetContent}
                        >
                          <Copy />
                        </Popconfirm>
                      )}

                      <Tooltip title="Dupliquer le widget" color="#0197DC">
                        <CopyOutlined onClick={() => onSaveDuplicateWidget()} />
                      </Tooltip>

                      {!isUsed && (
                        <Popconfirm
                          placement="left"
                          title="Êtes-vous sûr de vouloir supprimer ce widget ?"
                          onConfirm={deleteWidget(id)}
                        >
                          <Tooltip
                            title="Supprimer le widget"
                            color="#0197DC"
                          >
                            <Trash />
                          </Tooltip>
                        </Popconfirm>
                      )}

                      {isUsed && (
                        <Tooltip
                          color="#0197DC"
                          title={(
                            <div>
                              <WidgetIsUsed isUsedBy={isUsedBy} />
                            </div>
                          )}
                        >
                          <Trash className="text-gray-300" />
                        </Tooltip>
                      )}
                    </div>
                  );
                }}
              />
            ) : (
              <Table.Column
                title=""
                dataIndex="id"
                render={(id) => {
                  return (
                    <div className="flex justify-end gap-2 text-lg">
                      <Tooltip
                        title="Voir le widget"
                        color="#0197DC"
                      >
                        <EyeOutlined className="grey" onClick={() => history.push(isTeamPath ? `/equipe/elements/widgets/${id}/preview` : `/offre/${offerId}/widgets/${id}/preview`)} />
                      </Tooltip>
                    </div>
                  );
                }}
              />
            )}
          </Table>
        );
      }}
    </QWidgets>
  );
};

WidgetsTable.propTypes = {
  scope: PropTypes.oneOf(['global', 'offer']),
  offerId: PropTypes.string
};

export default withUser(WidgetsTable);
