import React from 'react';
import { Button, Modal } from 'antd';
import { assoc, compose, concat, difference, flatten, isNil, map, prop, propEq, propOr } from 'ramda';
import { isNilOrEmpty, isNotEmpty, notEqual } from 'ramda-adjunct';
import WidgetForm from '../widget/WidgetForm';
import { QWidgetQuery } from '../../../../_graphql/queries/documents/QWidget';
import useFetchQuery from '../../../../hooks/useFetchQuery';
import Loading from '../../../../components/Loading';
import { useDocumentContext } from '../../../../context/Document';
import UpdateCacheMutation from '../../../../_graphql/mutations/UpdateCacheMutation';
import { getVariablesOnWidgetHtml } from '../../../helpers/getVariablesOnWidgetHtml';

const EditWidgetModal = ({ totalPaddingX }) => {
  const {
    widgetIdEditing,
    setWidgetIdEditing,
    offerId,
    documentId,
    setWidgets,
    widgets
  } = useDocumentContext();

  const { widget, isLoading, reFetch } = useFetchQuery({
    query: QWidgetQuery,
    args: { widgetId: widgetIdEditing },
    dataProp: 'widget',
    defaultData: {}
  });
  const onCancel = () => setWidgetIdEditing(undefined);

  const updateWidget = (values, name) => {
    const variablesOnOldWidget = getVariablesOnWidgetHtml(JSON.parse(propOr([], 'contents', widget)));
    const variablesOnUpdatedWidget = getVariablesOnWidgetHtml(propOr([], 'contents', values));
    const diffVariables = compose(
      flatten,
      () => concat(difference(variablesOnOldWidget, variablesOnUpdatedWidget), difference(variablesOnUpdatedWidget, variablesOnOldWidget))
    )();

    const updateWidgetData = w => {
      if (propEq('id', widgetIdEditing, w)) {
        let updatedWidget = w;
        if (isNotEmpty(diffVariables)) {
          updatedWidget = compose(
            assoc('variables', variablesOnUpdatedWidget),
            assoc('contents', JSON.stringify(prop('contents', values)))
          )(updatedWidget);
        }
        if (notEqual(prop('name', widget), name)) {
          updatedWidget = assoc('name', name, updatedWidget);
        }
        return updatedWidget;
      }
      return w;
    };

    const widgetsUpdated = map(updateWidgetData, widgets);
    setWidgets(widgetsUpdated);
    UpdateCacheMutation({ date: new Date(), key: `widgets-${offerId}` }, () => {});
  };

  return (
    <Modal
      visible={!isNil(widgetIdEditing)}
      footer={null}
      onCancel={onCancel}
      width="90%"
      destroyOnClose
    >
      {
        isNilOrEmpty(widget)
          ? isLoading
            ? <Loading />
            : (
              <div>
                {'Le widget n\'existe pas.'}
                <Button onClick={onCancel}>Fermer</Button>
              </div>
            )
          : (
            <WidgetForm
              widget={widget}
              scope={prop('scope', widget)}
              modalMode
              onCancel={onCancel}
              hideModal={onCancel}
              documentId={documentId}
              offerId={offerId}
              setRefetch={reFetch}
              totalPaddingX={totalPaddingX}
              updateWidget={updateWidget}
            />
          )
      }
    </Modal>
  );
};

export default EditWidgetModal;
