import { Button, Tooltip } from 'antd';
import { applySpec, filter, isNil, path, pathEq, pathOr, prop, propEq, propOr } from 'ramda';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notEqual } from 'ramda-adjunct';
import { FormOutlined } from '@ant-design/icons';
import { Actions, Title } from '../../components';
import CreateVersion from '../../components/CreateVersion';
import { Clock } from '../../components/icons';
import ItemComments from '../../components/ItemComments';
import Review from '../../components/Review';
import StatusActions from '../../components/StatusActions';
import withUser from '../../withUser';
import { SCOPE_OFFER } from '../../_CONST';
import { QOffer } from '../../_graphql/queries';
import QOfferComments from '../../_graphql/queries/comments/QOfferComments';
import useFetchQuery from '../../hooks/useFetchQuery';
import { QFoldersQuery } from '../../_graphql/queries/folders/QFolders';
import Loading from '../../components/Loading';
import UpdateOfferNameMutation from '../../_graphql/mutations/offers/UpdateOfferNameMutation';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import FullscreenLoading from '../../components/FullscreenLoading';
import DocumentsSection from './editOffer/DocumentsSection';
import EditOfferValidator from './editOffer/EditOfferValidator';
import GlobalVariablesSection from './editOffer/GlobalVariablesSection';
import TagsSection from './editOffer/TagsSection';
import WidgetsSection from './editOffer/WidgetList';
import withOfferRights from './withOfferRights';

const EditOffer = ({
  userIsAdmin,
  userIsOfferValidator,
  userTeam
}) => {
  const { offerId } = useParams();
  const history = useHistory();
  const nameOfferRef = useRef();
  const [refetchWidgets, setRefetchWidgets] = useState(false);
  const [focusOfferName, setFocusOfferName] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isLoadingOfferItem, setIsLoadingOfferItem] = useState(false);
  const [fullscreenLoading, setFullscreenLoading] = useState(false);

  const { folders, reFetch, isLoading } = useFetchQuery({
    query: QFoldersQuery,
    args: { offerId, scope: SCOPE_OFFER },
    dataProp: 'folders',
    defaultData: [],
    cacheKey: ['offerViewCache', `folders-${offerId}`]
  });

  if (isLoading) return <Loading />;

  return (
    <div>
      <QOffer
        args={{
          offerId, refetch, setRefetch
        }}
      >
        {offer => {
          if (isNil(offer)) {
            return <span>Aucune offre trouvée.</span>;
          }
          if (notEqual(prop('teamId', userTeam), prop('team', offer))) return <span>{'Vous n\'avez pas accès à cette offre.'}</span>;

          const {
            isDraft,
            isArchived,
            isPublished,
            versionId
          } = applySpec({
            isDraft: pathEq(['version', 'status'], 'draft'),
            isReview: pathEq(['version', 'status'], 'review'),
            isPublished: pathEq(['version', 'status'], 'release'),
            isArchived: pathEq(['version', 'status'], 'archived'),
            versionId: path(['version', 'versionId'])
          })(offer);

          if (isArchived) {
            return <span>Cette offre est archivée.</span>;
          }

          const userCanEditOffer = isDraft && userIsAdmin;

          const onBlurOfferName = () => {
            const newOfferName = path(['current', 'innerText'], nameOfferRef);
            if (notEqual(propOr('', 'offerName', offer), newOfferName)) {
              const rootOfferId = pathOr(offerId, ['version', 'itemRootId'], offer);
              UpdateOfferNameMutation({ rootOfferId, offerName: newOfferName }, (ok, error) => {
                if (ok && !error) {
                  successMessage('offre', 'modifiée', true, true);
                  setRefetch(true);
                } else {
                  errorMessage();
                }
              });
            }
            setFocusOfferName(false);
          };

          const onKeyDownOfferName = (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.target.blur();
            }
          };

          return (
            <>
              {fullscreenLoading && <FullscreenLoading />}
              <Actions
                title={(
                  <Title
                    title="mon offre :"
                    infoTitle={() => (
                      <>
                        <span
                          ref={nameOfferRef}
                          className="text-lg"
                          suppressContentEditableWarning
                          contentEditable={focusOfferName}
                          spellCheck={false}
                          onBlur={onBlurOfferName}
                          onKeyDown={onKeyDownOfferName}
                        >
                          {propOr('', 'offerName', offer)}
                        </span>
                        {userCanEditOffer && (
                          <Tooltip title="Éditer" color="#0197DC">
                            <FormOutlined
                              onClick={() => {
                                setFocusOfferName(true);
                                nameOfferRef.current.focus();
                              }}
                              className="hover:text-flexibranche-darkgray pl-2"
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                    backTo="/offre"
                  />
                )}
                buttons={(
                  <>
                    <Button
                      size="large"
                      onClick={() => history.push(`/offre/${offerId}/historique`)}
                      icon={<Clock />}
                    >
                      {'Voir l\'historique'}
                    </Button>

                    <CreateVersion
                      versionId={versionId}
                      userCanCreateVersion={userIsOfferValidator}
                      redirect="/offre/:itemId/edition"
                    />

                    <StatusActions
                      item={offer}
                      draftLabel="Passer l'offre en édition"
                      reviewLabel="Passer l'offre en revue"
                      userCanValidate={userIsOfferValidator}
                    />
                  </>
                )}
              />

              <Review
                item={offer}
                userCanValidate={userIsOfferValidator}
              />
              {isDraft && !userIsOfferValidator && (
                <QOfferComments args={{ offerId }}>
                  {offerComments => {
                    return (
                      <ItemComments
                        comments={offerComments}
                        title="Commentaires de revue"
                      />
                    );
                  }}
                </QOfferComments>
              )}

              <EditOfferValidator
                offerId={offerId}
                userCanEditOffer={userCanEditOffer}
              />

              <DocumentsSection
                isOfferPath
                offerId={offerId}
                offer={offer}
                userCanCreateOfferDocument={userCanEditOffer}
                isPublished={isPublished}
                setRefetchWidgets={setRefetchWidgets}
                folders={filter(propEq('type', 'DOCUMENT'))(folders)}
                reFetchFolders={reFetch}
                setIsLoadingOfferItem={setIsLoadingOfferItem}
                isLoadingOfferItem={isLoadingOfferItem}
                setFullscreenLoading={setFullscreenLoading}
              />

              <WidgetsSection
                isOfferPath
                args={{
                  offerId, scope: SCOPE_OFFER, type: 'WIDGET'
                }}
                userCanEditOffer={userCanEditOffer}
                userIsAdmin={userIsAdmin}
                userIsOfferValidator={userIsOfferValidator}
                offerIsDraft={isDraft}
                scope={SCOPE_OFFER}
                isPublished={isPublished}
                refetchWidgets={refetchWidgets}
                setRefetchWidgets={setRefetchWidgets}
                cacheKey={['offerViewCache', `widgets-${offerId}`]}
                folders={filter(propEq('type', 'WIDGET'))(folders)}
                reFetchFolders={reFetch}
                setIsLoadingOfferItem={setIsLoadingOfferItem}
                isLoadingOfferItem={isLoadingOfferItem}
                setFullscreenLoading={setFullscreenLoading}
              />

              <TagsSection
                offerId={offerId}
                userCanEditOffer={userCanEditOffer}
                version={offer.version}
              />

              <GlobalVariablesSection
                offerId={offerId}
                userCanEditOffer={userCanEditOffer}
                version={offer.version}
              />
            </>
          );
        }}
      </QOffer>
    </div>
  );
};

export default withUser(withOfferRights(EditOffer));
