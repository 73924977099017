import { Button, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { has, prop, pathOr, map, concat, compose, reduce, find, toPairs, propEq, assoc, pick } from 'ramda';
import TextArea from 'antd/es/input/TextArea';

const ModalAddMetadata = ({ variablesValues, globalVariables, variables, onChangeMultipleVariables }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isJSONValid, setIsJSONValid] = useState(true);
  const showModal = () => setIsModalVisible(true);

  const getVariablesValue = key => {
    if (has('rows', prop(key, variablesValues))) return prop(key, variablesValues);
    return pathOr('', [key, 'value'], variablesValues);
  };

  const formatVariableData = (key1, key2) => (data) => {
    const {
      [key1]: name,
      [key2]: type
    } = pick([key1, key2], data);

    return {
      name,
      type,
      value: getVariablesValue(name)
    };
  };

  const formattedVariables = map(formatVariableData('key', 'type'))(variables);
  const formattedGlobalVariables = map(formatVariableData('name', 'type'))(globalVariables);
  const mergedFormattedVariables = concat(formattedGlobalVariables, formattedVariables);

  const variablesInJSONFormatToDisplay = useCallback(() => {
    const formattedVariables = compose(
      reduce((acc, variable) => {
        acc[prop('name', variable)] = prop('value', variable);
        return acc;
      }, {}),
      map(pick(['name', 'value']))
    )(mergedFormattedVariables);

    return JSON.stringify(formattedVariables, null, 2);
  }, [mergedFormattedVariables]);

  const [metadata, setMetadata] = useState(variablesInJSONFormatToDisplay);

  const formatJSONValue = (newValue) => {
    const reorganizedVariables = compose(
      map(([name, value]) => ({ name, value })),
      toPairs
    )(newValue);

    const updatedVariable = map(variable => {
      const isNameMatch = find(propEq('name', prop('name', variable)), reorganizedVariables);
      return isNameMatch ? assoc('value', prop('value', isNameMatch), variable) : variable;
    }, mergedFormattedVariables);

    return updatedVariable;
  };

  useEffect(() => {
    setMetadata(variablesInJSONFormatToDisplay);
    setIsJSONValid(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const handleOk = () => {
    try {
      const newVariableValue = JSON.parse(metadata);
      onChangeMultipleVariables(formatJSONValue(newVariableValue));
      setIsModalVisible(false);
    } catch (error) {
      setIsJSONValid(false);
    }
  };

  const handleChange = e => setMetadata(e.target.value);

  const handleCancel = () => {
    setMetadata(variablesInJSONFormatToDisplay);
    setIsModalVisible(false);
  };

  const TITLE = 'Insérer la donnée JSON';

  return (
    <div className="my-3">
      <Button onClick={showModal}>{TITLE}</Button>
      <Modal
        title={TITLE}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={640}
      >
        <TextArea
          rows="30"
          placeholder="Insérer vos metadata sous format JSON"
          value={metadata}
          onChange={handleChange}
        />
        {!isJSONValid && <p className="w-full flex justify-center text-red-500">Erreur dans le format JSON</p>}
      </Modal>
    </div>
  );
};

export default ModalAddMetadata;

ModalAddMetadata.propTypes = {
  variablesValues: PropTypes.object,
  globalVariables: PropTypes.array,
  variables: PropTypes.array,
  onChangeMultipleVariables: PropTypes.func
};
