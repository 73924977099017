import React from 'react';
import { defs } from './common';

const ClockIcon = () => (
  <svg
    version="1.1" viewBox="43 43 18 18" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>5C10EF34-4837-42B0-B7B6-61F53D490496</title>
    {defs}
    <g fill="none" fillRule="evenodd" strokeWidth="1">
      <g transform="translate(-880 -67)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(895 96)" filter="url(#a)">
          <g transform="translate(28 14)">
            <path d="m9 0c-3.1942 0-6.0911 1.6823-7.7143 4.4036v-3.7607c0-0.35518-0.28768-0.64286-0.64286-0.64286-0.35518 0-0.64286 0.28768-0.64286 0.64286v5.1429c0 0.35357 0.28768 0.64286 0.64286 0.64286h5.1429c0.35357 0 0.64286-0.28929 0.64286-0.64286 0-0.35357-0.28929-0.64286-0.64286-0.64286h-3.4493c1.3801-2.3826 3.8953-3.8571 6.6636-3.8571 4.2549 0 7.7143 3.4606 7.7143 7.7143 0 4.2537-3.4606 7.7143-7.7143 7.7143-2.3898 0-4.6085-1.0812-6.079-2.9668-0.21849-0.28125-0.62196-0.33272-0.90281-0.11049-0.27936 0.21849-0.32898 0.62277-0.11049 0.90161 1.7164 2.1998 4.3039 3.4614 7.0923 3.4614 4.9621 0 9-4.0379 9-9 0-4.9621-4.0379-9-9-9zm0 3.8571c-0.35357 0-0.64286 0.28929-0.64286 0.64286v5.1429c0 0.20214 0.095424 0.39299 0.25738 0.51469l2.5714 1.9286c0.11628 0.05183 0.24887 0.12817 0.34931 0.12817 0.19587 0 0.38861-0.089156 0.51469-0.25738 0.21343-0.28374 0.15569-0.68665-0.12869-0.9004l-2.2784-1.7351v-4.8214c0-0.35357-0.28929-0.64286-0.64286-0.64286z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ClockIcon;
