import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteWidgetMutation($widgetId: ID!) {
    deleteWidget(widgetId: $widgetId) {
      ok
      error
    }
  }
`;

export default ({
  widgetId
}, done) => {
  const variables = { widgetId };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteWidget: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, widgetId),
      onCompleted: ({ deleteWidget }) => basicMutationOnCompleted(deleteWidget, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
