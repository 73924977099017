import { createClient } from 'graphql-ws';
import { defaultTo, prop } from 'ramda';
import Tokens from '../lib/Tokens';
const {
  Environment,
  Network,
  Observable,
  RecordSource,
  Store
} = require('relay-runtime');

const GQLEnvironment = async () => {
  const sendRequest = async (url, headers, body, refreshToken = false) => {
    const accessToken = await Tokens.getAccessToken(refreshToken);
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`
      },
      body
    });

    if (response.status === 200) {
      return await response.json();
    }

    let errorMessage = null;

    if (response.status === 401) {
      if (refreshToken) {
        return await sendRequest(url, headers, body, true);
      }
      errorMessage = 'can not refresh access token';
    }

    return new Error(defaultTo('graphql request failed')(errorMessage));
  };

  const fetchQuery = async (operation, variables) => {
    let body = new FormData();
    body = JSON.stringify({
      query: operation.text,
      variables
    });
    const headers = {
      Accept: '*/*',
      'Content-Type': 'application/json'
    };

    return await sendRequest('/graphql', headers, body);
  };

  const graphqlWsUrl = async () => {
    const response = await fetch('/graphqlwsurl');
    return prop('url', await response.json());
  };

  const wsClient = createClient({ url: graphqlWsUrl });

  const subscribe = (operation, variables) => {
    return Observable.create((sink) => {
      return wsClient.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables
        },
        sink
      );
    });
  };

  return new Environment({
    network: Network.create(fetchQuery, subscribe),
    store: new Store(new RecordSource())
  });
};

export default GQLEnvironment;
