import React, { useEffect, useState } from 'react';
import { Input as InputAntd } from 'antd';
import { defaultTo, omit, propOr } from 'ramda';
import { isNotNil } from 'ramda-adjunct';

const Input = React.forwardRef((props, ref) => {
  const { className = '', size = 'default', inputsize, type, allowClear, value, onChange, debounce = false } = props;
  const [currentValue, setCurrentValue] = useState(defaultTo('')(value));

  useEffect(() => {
    // update the parent state one second after the last internal state update to avoid unnecessary rendering
    const timeout = setTimeout(() => onChange(currentValue), 1000);
    return () => clearTimeout(timeout);
  }, [currentValue]);

  let builtClassName = '';

  if (size === 'small') {
    builtClassName = 'text-sm';
  }

  const disabled = propOr(false, 'disabled')(props);

  const finalProps = debounce ? {
    ...props, value: currentValue, onChange: (e) => setCurrentValue(e.target.value)
  } : props;

  return (
    <InputAntd
      {...omit(['className', 'size', 'ref', 'debounce'], finalProps)}
      ref={ref}
      size={inputsize}
      maxLength={inputsize}
      type={isNotNil(type) ? type : 'text'}
      allowClear={allowClear}
      className={`flex border border-solid border-gray-300 ${builtClassName} ${className}`}
      disabled={disabled}
    />
  );
});

export default Input;

Input.displayName = 'Input';
