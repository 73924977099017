import React from 'react';
import { useParams } from 'react-router-dom';
import History from '../../components/History';
import QWidget from '../../_graphql/queries/documents/QWidget';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';

const WidgetHistory = ({ userTeam }) => {
  const { widgetId } = useParams();
  return (
    <QWidget args={{ widgetId }}>
      {widget => {
        const accessDenied = verifyRouteAccess(userTeam, widget, 'l\'historique de ce widget');
        if (accessDenied) return accessDenied;
        return (
          <History id={widgetId} type="widget" />
        );
      }}
    </QWidget>
  );
};

export default withUser(WidgetHistory);
