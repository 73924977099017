import { isNil, is } from 'ramda';
import CreateCommentMutation from '../_graphql/mutations/comments/CreateCommentMutation';
import AddCommentMessageMutation from '../_graphql/mutations/comments/AddCommentMessageMutation';
import UpdateCommentStatusMutation from '../_graphql/mutations/comments/UpdateCommentStatusMutation';
import DeleteCommentMutation from '../_graphql/mutations/comments/DeleteCommentMutation';
import { errorMessage } from '../utils/messageMutation';

const useComments = () => {
  const callback = onSuccess => (ok, error, data) => {
    if (ok && !error) {
      if (!isNil(onSuccess) && is(Function, onSuccess)) {
        onSuccess(data);
      }
    } else {
      errorMessage();
    }
  };

  const onCreateComment = ({ itemKey, itemPath, itemRootId }, onSuccess) => {
    CreateCommentMutation({
      itemKey, itemPath, itemRootId
    }, callback(onSuccess));
  };

  const onAddMessage = ({ commentId, message }, onSuccess) => {
    AddCommentMessageMutation({ commentId, message }, callback(onSuccess));
  };

  const onUpdateStatus = ({ commentId, status }, onSuccess) => {
    UpdateCommentStatusMutation({ commentId, status }, callback(onSuccess));
  };

  const onDeleteComment = ({ commentId }, onSuccess) => {
    DeleteCommentMutation({ commentId }, callback(onSuccess));
  };

  return {
    onCreateComment,
    onAddMessage,
    onUpdateStatus,
    onDeleteComment
  };
};

export default useComments;
