import { useLocation,
  matchPath } from 'react-router-dom';
import { prop, isNil } from 'ramda';

const useMatchLocation = (options = { strict: false }) => {
  const location = useLocation();
  const matchLocation = (path) => {
    const match = matchPath(prop('pathname', location), { path, ...options });
    return !isNil(match);
  };

  const getParams = (path) => {
    const match = matchPath(prop('pathname', location), { path, ...options });
    if (isNil(match)) return null;
    return prop('params', match);
  };

  return {
    getParams,
    matchLocation
  };
};

export default useMatchLocation;
