import { always,
  applySpec,
  assoc,
  complement,
  compose,
  cond,
  dec,
  defaultTo,
  equals,
  find,
  flatten,
  gt,
  hasPath,
  inc,
  isEmpty,
  last,
  length,
  lt,
  map,
  omit,
  path,
  prop,
  propEq,
  propOr,
  split,
  T,
  when } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { mapIndexed } from '../../utils/mapIndexed';

export const addKeys = (key, keyName = 'kkey') => mapIndexed((data, idx) => {
  const kkey = `${key}-${idx}`;
  data = assoc(keyName, kkey, data);
  const children = prop('children', data);
  if (children && !isEmpty(children)) {
    data.children = addKeys(kkey, keyName)(children);
  }

  return data;
});

export const cleanFolders = map(folder => applySpec({
  parent: prop('parent'),
  parentId: prop('parentId'),
  element: prop('dataObj'),
  children: compose(cleanFolders, propOr([], 'children'))
})(folder));

export const setDefaultPropsToElement = compose(
  assoc('isFolder', false),
  assoc('allowDelete', false),
  assoc('allowEdit', false),
  assoc('allowAdd', false),
  assoc('allowAppendChildren', false)
);

export const appendElementToFolder = (elements) => map(
  when(
    hasPath(['element', 'elmId']),
    folder => {
      const elmId = path(['element', 'elmId'], folder);
      const treeId = prop('id', folder);

      const element = compose(
        defaultTo({}),
        find(propEq('id', elmId))
      )(elements);

      return setDefaultPropsToElement({
        ...omit(['element'], folder),
        ...omit(['id'], element),
        // Ré-attribution des ID
        elmId,
        treeId,
        _element: element
      });
    }
  )
);

export const addAllowedAccessFolders = (useCanEdit) => map(when(
  complement(hasPath(['element', 'elmId'])),
  compose(
    folder => ({ ...folder, treeId: prop('id', folder) }),
    assoc('isFolder', true),
    assoc('allowDelete', useCanEdit),
    assoc('allowEdit', useCanEdit),
    assoc('allowAdd', useCanEdit),
    assoc('allowAppendChildren', useCanEdit)
  )
));

export const hasParent = compose(isNotNilOrEmpty, prop('parent'));

export const updateKeys = (expandedKeys, nodeKeys, type) => flatten(map((key) => {
  const updatedKeys = map((nodeKey) => {
    const splittedNodeKey = split('-', nodeKey);
    const lengthNodeKey = length(splittedNodeKey);
    const splittedKey = split('-', key);
    const lengthExpandedKey = length(splittedKey);
    if (equals('move', type)) {
      splittedKey[1] = inc(splittedKey[1]);
    }

    if (lengthNodeKey <= lengthExpandedKey) {
      const nodeKeyIsLTSplittedKey = lt(last(splittedNodeKey), splittedKey[lengthNodeKey - 1]);
      const equalsLength = equals(lengthNodeKey, lengthExpandedKey);
      const splittedKeyToUpdate = splittedKey[lengthNodeKey - 1];
      splittedKey[lengthNodeKey - 1] = cond([
        [equals('remove'), () => {
          if (equalsLength && gt(last(splittedNodeKey), splittedKeyToUpdate)) {
            splittedKey[lengthExpandedKey - 1] = dec(splittedKey[lengthExpandedKey - 1]);
            return splittedKeyToUpdate;
          }
          return dec(splittedKeyToUpdate);
        }],
        [equals('duplicate'), () => {
          return inc(splittedKeyToUpdate);
        }],
        [() => (equalsLength && nodeKeyIsLTSplittedKey) || nodeKeyIsLTSplittedKey, always(dec(splittedKeyToUpdate))],
        [T, always(splittedKeyToUpdate)]
      ])(type);
    }

    let updatedKey = splittedKey[0];
    for (let i = 1; i < lengthExpandedKey; i++) {
      updatedKey += `-${splittedKey[i]}`;
    }

    return updatedKey;
  })(nodeKeys);

  return updatedKeys;
})(expandedKeys));
