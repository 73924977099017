const BASE_PADDING_DOCUMENT = {
  top: 0, right: 0, bottom: 0, left: 0
};
const SCOPE_OFFER = 'offer';
const IFRAME_PDF_HEIGHT = 1000;
const IFRAME_PDF_WIDTH = 700;
const DEFAULT_HEIGHT_PAGE_PORTRAIT = '29.6cm';
const DEFAULT_HEIGHT_PAGE_LANDSCAPE = '21cm';
const CLASSNAME_DOCUMENT_PORTAIT = 'html-document-page page-portrait';
const CLASSNAME_DOCUMENT_LANDSCAPE = 'html-document-page page-landscape';
const BASE_FRAME_STYLE = {
  backgroundColor: '',
  borderColor: {
    top: '', left: '', right: '', bottom: ''
  },
  borderWidth: {
    top: 0, left: 0, right: 0, bottom: 0
  },
  borderStyle: {
    top: 'none', left: 'none', right: 'none', bottom: 'none'
  },
  borderRadius: {
    topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0
  }
};
const DEFAULT_COLOR = '#000000';
export {
  BASE_PADDING_DOCUMENT,
  SCOPE_OFFER,
  IFRAME_PDF_HEIGHT,
  IFRAME_PDF_WIDTH,
  DEFAULT_HEIGHT_PAGE_PORTRAIT,
  DEFAULT_HEIGHT_PAGE_LANDSCAPE,
  CLASSNAME_DOCUMENT_PORTAIT,
  CLASSNAME_DOCUMENT_LANDSCAPE,
  BASE_FRAME_STYLE,
  DEFAULT_COLOR
};
