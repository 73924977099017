import { commitMutation,
  graphql } from 'react-relay';
import environment from '../..';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation DuplicateWidgetMutation($widgetId: ID!) {
    duplicateWidget(widgetId: $widgetId) {
      ok
      error
      widgetId
      name
    }
  }
`;

export default ({
  widgetId
}, done) => {
  const variables = { widgetId };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ duplicateWidget }) => {
        const { ok, error, widgetId, name } = duplicateWidget;
        done(ok, error, widgetId, name);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
