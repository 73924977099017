import React, { useState } from 'react';
import { isNil, equals } from 'ramda';
import { Tabs } from 'antd';
import CreateTab from './documentFormTabs/CreateTab';
import VariablesTab from './documentFormTabs/VariablesTab';
import CommentsTab from './documentFormTabs/CommentsTab';

const TabTitle = ({ icon, title, isActive, className }) => {
  return (
    <div className={`${className} flex items-center`}>
      {!isNil(icon) && (
        <img
          alt=""
          // eslint-disable-next-line no-restricted-syntax
          style={{ filter: isActive ? 'invert(38%) sepia(98%) saturate(1486%) hue-rotate(171deg) brightness(94%) contrast(104%)' : 'unset' }}
          className="w-25px mr-2.5"
          src={`/icons/${icon}`}
        />
      )}

      <span className="uppercase font-semibold text-sm">{title}</span>
    </div>
  );
};

const DocumentFormTabs = (props) => {
  const {
    comments,
    showSettings
  } = props;
  const [activeKey, setActiveKey] = useState('1');

  return (
    <div className={`bg-white w-full p-6 shadow-lg ${showSettings ? 'visible' : 'invisible'}`}>
      <Tabs
        defaultActiveKey={activeKey}
        onChange={setActiveKey}
      >
        <Tabs.TabPane
          tab={(
            <TabTitle
              className="create-tab-button"
              icon="icon-layer-plus.svg"
              title="Création"
              isActive={equals('1', activeKey)}
            />
          )}
          key="1"
        >
          <CreateTab {...props} />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={(
            <TabTitle
              icon="icon-comment-quote.svg"
              title="Commentaires"
              isActive={equals('2', activeKey)}
            />
          )}
          key="2"
        >
          <CommentsTab comments={comments}/>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={(
            <TabTitle
              className="variables-tab-button"
              icon="icon-pen-field.png"
              title="Variables"
              isActive={equals('3', activeKey)}
            />
          )}
          key="3"
        >
          <VariablesTab />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default DocumentFormTabs;
