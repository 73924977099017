import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation RemoveFoldersMutation($foldersIds: [ID]!) {
    removeFolders(foldersIds: $foldersIds) {
      ok
      error
    }
  }
`;

export default ({ foldersIds }, done) => {
  const variables = { foldersIds };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { removeFolders: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, foldersIds),
      onCompleted: ({ removeFolders }) => {
        const { ok, error, id } = removeFolders;
        done(ok, error, id);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
