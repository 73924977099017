import React, { useEffect, useRef, useState } from 'react';
import { Components, useBorders, usePopup } from '@fasstech/flexiget';
import { BorderBottomOutlined, BorderLeftOutlined, BorderOuterOutlined, BorderRightOutlined, BorderTopOutlined, FormatPainterOutlined, LockOutlined, RadiusBottomleftOutlined, RadiusBottomrightOutlined, RadiusUpleftOutlined, RadiusUprightOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { all, always, assocPath, compose, cond, dec, equals, find, head, inc, not, path, prop, values as RValues, sort, tail } from 'ramda';
import { isNotNilOrEmpty, notEqual } from 'ramda-adjunct';
import classNames from 'classnames';
import { DEFAULT_COLOR } from '../../../../_CONST';
import useColors from '../../../../hooks/useColors';
import defaultColors from '../../../../utils/defaultColors';
import FrameBorderStyle from './FrameBorderStyle';

const FrameStyle = ({ values, setState }) => {
  const bordersRadius = path(['frameStyle', 'borderRadius'], values);
  const [borderRadiusIsSetForAll, setBorderRadiusIsSetForAll] = useState(all(equals(head(RValues(bordersRadius))), tail(RValues(bordersRadius))));

  const changeBorderRadius = (value, position) => {
    if (borderRadiusIsSetForAll) {
      const newBorderRadiusObject = {
        topLeft: value, bottomRight: value, bottomLeft: value, topRight: value
      };
      setState(assocPath(['values', 'frameStyle', 'borderRadius'], newBorderRadiusObject));
    } else {
      setState(assocPath(['values', 'frameStyle', 'borderRadius', position], value));
    }
  };

  useEffect(() => {
    const tallerBorderRadius = compose(
      head,
      (v) => sort((a, b) => b - a)(v),
      RValues
    )(bordersRadius);
    if (borderRadiusIsSetForAll && notEqual(0, tallerBorderRadius)) changeBorderRadius(tallerBorderRadius);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borderRadiusIsSetForAll]);

  const IS_ICON_SELECTED_STYLE = 'border-blue-tooltip bg-blue-tooltip text-white';
  const {
    isEditingBorders,
    setIsEditingBorders,
    isEditingBorderLeft,
    setIsEditingBorderLeft,
    isEditingBorderTop,
    setIsEditingBorderTop,
    isEditingBorderRight,
    setIsEditingBorderRight,
    isEditingBorderBottom,
    setIsEditingBorderBottom
  } = useBorders();
  const { colors } = useColors();
  const favoritesColors = [defaultColors, colors];
  const colorPickerRef = useRef(null);
  const [showOptions, setShowOptions] = usePopup(colorPickerRef);
  const bgColor = path(['frameStyle', 'backgroundColor'], values);
  const hasBorderStyle = isNotNilOrEmpty(find(notEqual('none'))(RValues(path(['frameStyle', 'borderStyle'], values))));

  const inputPosition = (position) => {
    const prefixIcon = cond([
      [equals('topLeft'), always(<RadiusUpleftOutlined />)],
      [equals('topRight'), always(<RadiusUprightOutlined />)],
      [equals('bottomLeft'), always(<RadiusBottomleftOutlined />)],
      [equals('bottomRight'), always(<RadiusBottomrightOutlined />)]
    ])(position);

    const currentValuePosition = prop(position, bordersRadius);
    return (
      <div>
        <Components.InputNumbers
          prefix={prefixIcon}
          inputClassName={`!w-14 input-border-radius-${position}`}
          value={currentValuePosition}
          defaultValue={currentValuePosition}
          minimumValue={0}
          handleNewValue={(e) => {
            e.preventDefault();
            changeBorderRadius(Number(e.target.value), position);
          }}
          onClickPlus={() => changeBorderRadius(inc(currentValuePosition), position)}
          onClickMinus={() => changeBorderRadius(dec(currentValuePosition), position)}
        />
      </div>
    );
  };

  const colorIsNotDefine = showOptions || equals('transparent', bgColor);

  const borderButtonsData = [
    {
      title: 'Style des bordures',
      state: isEditingBorders,
      setState: setIsEditingBorders,
      icon: <BorderOuterOutlined />,
      type: 'all'
    },
    {
      title: 'Style de la bordure gauche',
      state: isEditingBorderLeft,
      setState: setIsEditingBorderLeft,
      icon: <BorderLeftOutlined />,
      type: 'left'
    },
    {
      title: 'Style de la bordure haute',
      state: isEditingBorderTop,
      setState: setIsEditingBorderTop,
      icon: <BorderTopOutlined />,
      type: 'top'
    },
    {
      title: 'Style de la bordure droite',
      state: isEditingBorderRight,
      setState: setIsEditingBorderRight,
      icon: <BorderRightOutlined />,
      type: 'right'
    },
    {
      title: 'Style de la bordure basse',
      state: isEditingBorderBottom,
      setState: setIsEditingBorderBottom,
      icon: <BorderBottomOutlined />,
      type: 'bottom'
    }
  ];

  const renderBorderButtons = (buttons) => {
    return buttons.map((button, index) => (
      <Components.ButtonStyleBorders
        key={index}
        title={button.title}
        className={classNames(`btn-style-border-${button.type}`, { [IS_ICON_SELECTED_STYLE]: button.state })}
        onClick={() => button.setState(!button.state)}
        icon={button.icon}
      />
    ));
  };

  return (
    <Components.ToolbarLayout show>
      {/* BG */}
      <div className="popup-wrapper" ref={colorPickerRef}>
        <Components.TooltipLayout placement="topLeft" title="Couleur de fond">
          <Button
            type="button"
            // eslint-disable-next-line no-restricted-syntax
            style={{ color: colorIsNotDefine ? DEFAULT_COLOR : bgColor }}
            onClick={() => setShowOptions((prev) => !prev)}
            shape="round"
            size="small"
            icon={<FormatPainterOutlined />}
            className="btn-bg-color"
          />
        </Components.TooltipLayout>
        {showOptions && (
          <Components.ColorPickerBody
            color={bgColor}
            setColor={(v) => setState(assocPath(['values', 'frameStyle', 'backgroundColor'], v))}
            handleColorChange={(v) => setState(assocPath(['values', 'frameStyle', 'backgroundColor'], v))}
            setShowOptions={setShowOptions}
            favoritesColors={favoritesColors}
            isDraggable
          />
        )}
      </div>

      {/* BORDER */}
      <div className="space-x-2">
        {renderBorderButtons(borderButtonsData.slice(0, 3))}
      </div>
      <div className="space-x-2">
        {renderBorderButtons(borderButtonsData.slice(3))}
      </div>
      <div className="flex flex-col mt-2 justify-evenly">
        {isEditingBorders && <FrameBorderStyle values={values} setState={setState} borderSide="all" />}
        {isEditingBorderTop && <FrameBorderStyle values={values} setState={setState} borderSide="top" />}
        {isEditingBorderRight && <FrameBorderStyle values={values} setState={setState} borderSide="right" />}
        {isEditingBorderBottom && <FrameBorderStyle values={values} setState={setState} borderSide="bottom" />}
        {isEditingBorderLeft && <FrameBorderStyle values={values} setState={setState} borderSide="left" />}
        {hasBorderStyle && (
          <div className="section-border-radius flex justify-center mb-2">
            <Components.TooltipLayout title="Style des bordures">
              <div>
                <i className="text-flexibranche-blue">Arrondi des bordures</i>
                <div>
                  <Space>
                    {inputPosition('topLeft')}
                    {inputPosition('topRight')}
                  </Space>
                  <div className="flex justify-center m-1">
                    <Components.TooltipLayout title={`Cliquer pour ${borderRadiusIsSetForAll ? 'déverrouiller' : 'verrouiller'} les valeurs ${borderRadiusIsSetForAll ? '' : 'sur celle la plus haute'}`}>
                      {borderRadiusIsSetForAll
                        ? <LockOutlined className="text-s cursor-pointer" onClick={() => setBorderRadiusIsSetForAll(not)} />
                        : <UnlockOutlined className="text-s cursor-pointer" onClick={() => setBorderRadiusIsSetForAll(not)} />
                      }
                    </Components.TooltipLayout>
                  </div>
                  <Space>
                    {inputPosition('bottomLeft')}
                    {inputPosition('bottomRight')}
                  </Space>
                </div>
              </div>
            </Components.TooltipLayout>
          </div>
        )}
      </div>
    </Components.ToolbarLayout>
  );
};

export default FrameStyle;
