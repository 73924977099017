import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteStyleMutation($styleId: ID!) {
    deleteStyle(styleId: $styleId) {
      ok
      error
    }
  }
`;

export default ({
  styleId
}, done) => {
  const variables = { styleId };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteStyle: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, styleId),
      onCompleted: ({ deleteStyle }) => basicMutationOnCompleted(deleteStyle, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
