import React, { useState } from 'react';
import { map, propOr, compose, find, pathEq, path } from 'ramda';
import { Select } from 'antd';
import { TEAM_ROLES } from '../../../lib/rights';
import { ChevronBottom } from '../../../components';
import { useManageRole } from './ManageRole';

const useRole = (member) => {
  const [role, setRole] = useState(propOr('regular', 'role', member));
  return {
    role,
    setRole
  };
};

const ManageRoleDropdown = ({ userTeam, memberId }) => {
  const member = compose(
    find(pathEq(['user', 'id'], memberId)),
    propOr([], 'members')
  )(userTeam);

  const {
    role,
    setRole
  } = useRole(member);

  const { updateRole } = useManageRole(userTeam);

  const onChange = (value) => {
    setRole(value);
    updateRole(path(['user', 'id'], member), value);
  };

  return (
    <Select
      placeholder="Choisir un rôle"
      value={role}
      onChange={onChange}
      className="w-full"
      size="large"
      suffixIcon={<ChevronBottom />}
    >
      {map(({ label, value }) => {
        return (
          <Select.Option
            key={value}
            value={value}
          >
            {label}
          </Select.Option>
        );
      })(TEAM_ROLES)}
    </Select>
  );
};

export default ManageRoleDropdown;
