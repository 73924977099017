import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Popconfirm, Select } from 'antd';
import { always, assoc, cond, equals, isEmpty, isNil, map, path, pathOr, prop, reduce } from 'ramda';
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Components } from '@fasstech/flexiget';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { ChevronBottom, Section, Title } from '../../../../components';
import alignText from '../../../../lib/alignText';
import CreateStyleMutation from '../../../../_graphql/mutations/documents/CreateStyleMutation';
import UpdateStyleMutation from '../../../../_graphql/mutations/documents/UpdateStyleMutation';
import ColorBlock from '../../colors/components/ColorBlock';
import { errorMessage, successMessage } from '../../../../utils/messageMutation';
import DeleteStyleMutation from '../../../../_graphql/mutations/documents/DeleteStyleMutation';
import { fontOptions } from '../../common/FontOptions';
import useColors from '../../../../hooks/useColors';
import defaultColors from '../../../../utils/defaultColors';
import TextAttributesButton from './TextAttributesButton';
import StyleEditActions from './StyleEditActions';

const StyleCreationForm = ({ style, userIsValidator, displayBackBtn = true, closeModal }) => {
  const history = useHistory();

  const [showColorPicker, setShowColorPicker] = useState(false);

  const [name, setName] = useState(pathOr('', ['name'], style));
  const [fontFamily, setFontFamily] = useState(pathOr('Arial', ['styles', 0, 'value'], style));
  const [fontSize, setFontSize] = useState(pathOr('16', ['styles', 1, 'value'], style));
  const [color, setColor] = useState(pathOr('#000000', ['styles', 2, 'value'], style));
  const [fontWeight, setFontWeight] = useState(pathOr('normal', ['styles', 3, 'value'], style));
  const [textDecorationLine, setTextDecorationLine] = useState(pathOr('none', ['styles', 4, 'value'], style));
  const [fontStyle, setFontStyle] = useState(pathOr('normal', ['styles', 5, 'value'], style));
  const [textAlign, setTextAlign] = useState(pathOr('left', ['styles', 6, 'value'], style));

  const defaultStyles = useMemo(() => ([
    {
      format: 'font-family',
      value: fontFamily
    },
    {
      format: 'font-size',
      value: fontSize
    },
    {
      format: 'color',
      value: color
    },
    {
      format: 'font-weight',
      value: fontWeight
    },
    {
      format: 'text-decoration-line',
      value: textDecorationLine
    },
    {
      format: 'font-style',
      value: fontStyle
    },
    {
      format: 'text-align',
      value: textAlign
    }
  ]), [color, fontFamily, fontSize, fontWeight, textDecorationLine, fontStyle, textAlign]);

  const { colors } = useColors();
  const favoritesColors = [defaultColors, colors];

  const styleValues = useMemo(() => ({
    name: name,
    scope: 'global',
    styles: defaultStyles
  }), [defaultStyles, name]);

  const state = useMemo(() => ({
    loading: false,
    values: styleValues
  }), [styleValues]);

  const handleChangeName = useCallback((e) => setName(e.target.value), []);
  const handleChangeFontFamily = useCallback((value) => setFontFamily(value), []);
  const handleChangeFontSize = useCallback((e) => setFontSize(e.target.value), []);
  const handleChangeTextAlign = useCallback((value) => setTextAlign(value), []);
  const handleChangeColor = useCallback((color) => setColor(color), []);

  const styleId = useMemo(() => prop('id', style), [style]);
  const isValid = useMemo(() => !isEmpty(path(['values', 'name'], state)), [state]);
  const isEdit = useMemo(() => !isNil(styleId), [styleId]);

  const buildStyle = reduce((acc, style) => {
    let value = prop('value', style);

    if (prop('format', style) === 'font-size') {
      value = parseInt(value);
    }

    acc = assoc(prop('format', style), value)(acc);
    return acc;
  }, {});

  const onSave = () => {
    const callback = (ok, error, styleId) => {
      if (error && !ok) return errorMessage();
      const newStyleId = styleId;
      const action = isEdit ? 'mis à jour' : 'créé';
      successMessage('style', action);
      if (isNil(closeModal)) {
        isNotNilOrEmpty(newStyleId) && history.push(`/equipe/elements/styles/${newStyleId}/edition`);
      } else {
        closeModal(ok);
      }
    };

    if (isEdit) return UpdateStyleMutation({ styleId, style: prop('values', state) }, callback);
    return CreateStyleMutation({ style: prop('values', state) }, callback);
  };

  const onDelete = () => {
    const callback = (ok, error) => {
      if (error && !ok) return errorMessage();
      successMessage('style', 'supprimé');
      history.push('/equipe/elements/styles');
    };

    return DeleteStyleMutation({ styleId }, callback);
  };

  const title = cond([
    [always(isEdit), always(`Style : ${path(['values', 'name'], state)}`)],
    [always(!isEdit), always('Créer un style')]
  ])(state);

  const selectOptionNames = cond([
    [equals('left'), always('Gauche')],
    [equals('right'), always('Droite')],
    [equals('center'), always('Centré')],
    [equals('justify'), always('Justifié')]
  ]);

  const textAlignOptions = map(align => (
    <Select.Option
      key={align}
      value={align}
    >{selectOptionNames(align)}
    </Select.Option>
  ))(alignText);

  const buttonData = [
    {
      attribute: 'bold',
      icon: <BoldOutlined/>,
      setAttribute: setFontWeight,
      currentAttribute: fontWeight,
      neutralAttribute: 'normal'
    },
    {
      attribute: 'italic',
      icon: <ItalicOutlined/>,
      setAttribute: setFontStyle,
      currentAttribute: fontStyle,
      neutralAttribute: 'normal'
    },
    {
      attribute: 'underline',
      icon: <UnderlineOutlined/>,
      setAttribute: setTextDecorationLine,
      currentAttribute: textDecorationLine,
      neutralAttribute: 'none'
    }
  ];

  return (
    <div>
      <Title
        title={title}
        backTo={displayBackBtn ? '/equipe/elements/styles' : null}
      />

      {isEdit && (
        // eslint-disable-next-line no-restricted-syntax
        <StyleEditActions style={style} userIsValidator={userIsValidator}/>
      )}
      <Section>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <label className="mb-2 block font-bold">Nom du style</label>
            <Input value={name} onChange={handleChangeName} />
          </div>
          <div>
            <label className="mb-2 block font-bold">Attributs de caractère</label>
            <div className="flex flex-raw items-center">
              <div className="space-x-4">
                {map(TextAttributesButton, buttonData)}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-8">
          <div>
            <label className="mb-2 block font-bold">Police de texte</label>
            <Select
              value={path(['values', 'styles', 0, 'value'], state) || fontFamily}
              onChange={handleChangeFontFamily}
              className="w-full"
              suffixIcon={<ChevronBottom />}
            >
              {fontOptions}
            </Select>
          </div>
          <div>
            <label className="mb-2 block font-bold">Taille de la police</label>
            <Input type="number" value={fontSize} onChange={handleChangeFontSize} />
          </div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-8">
          <div>
            <label className="mb-2 block font-bold">Alignement</label>
            <Select
              value={textAlign}
              onChange={handleChangeTextAlign}
              className="w-full"
              suffixIcon={<ChevronBottom />}
            >
              {textAlignOptions}
            </Select>
          </div>
          <div>
            <label className="block font-bold">Couleur</label>
            <div className="relative w-72 h-5">
              <div
                className="flex items-center cursor-pointer h-12 w-12"
                onClick={() => setShowColorPicker(!showColorPicker)}
              >
                <ColorBlock color={color} />
              </div>
              {showColorPicker && (
                <Components.ColorPickerBody
                  color={color}
                  setColor={setColor}
                  handleColorChange={handleChangeColor}
                  setShowOptions={setShowColorPicker}
                  favoritesColors={favoritesColors}
                  isDraggable
                />
              )}
            </div>
          </div>
        </div>
      </Section>

      <Section title="Aperçu du style">
        <div className="bg-gray-50 rounded p-8 mt-4">
          {/* eslint-disable-next-line no-restricted-syntax */}
          <div style={buildStyle(path(['values', 'styles'], state))}>Aperçu du style</div>
        </div>
      </Section>
      <div className="flex justify-center space-x-8">
        {isEdit && (
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer ce style ?"
            onConfirm={onDelete}
          >
            <Button
              type="danger"
              className="h-45px text-sm"
              disabled={!isValid}
            >
              <span className="font-semibold">Supprimer le style</span>
            </Button>
          </Popconfirm>
        )}
        <Button
          type="primary"
          className="h-45px text-sm"
          disabled={!isValid}
          onClick={onSave}
        >
          <span className="font-semibold">{isEdit ? 'Enregistrer' : 'Créer le style'}</span>
        </Button>
      </div>
    </div>
  );
};

export default StyleCreationForm;
