import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QCommentsQuery = graphql`
  query QCommentsQuery($itemRootId: ID) {
    comments(itemRootId: $itemRootId) {
      id
      comments {
        id
        itemKey
        itemPath
        itemRootId
        status
        teamId
        widgetName
        widgetId
        messages {
          text
          userId
          userName
          when
        }
      }
    }
  }
`;

const QComments = ({
  children,
  childDataProp,
  args
}) => (
  <Query
    query={QCommentsQuery}
    caching={false}
    args={args}
  >
    {populateChildren('comments')(children, childDataProp)}
  </Query>
);

export default QComments;
