/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FinishedRemoveOfferSubscriptionVariables = {||};
export type FinishedRemoveOfferSubscriptionResponse = {|
  +finishedRemoveOfferSubscription: ?{|
    +id: ?string,
    +step: ?number,
    +documents: ?boolean,
    +rawDocuments: ?boolean,
    +widgets: ?boolean,
    +tags: ?boolean,
    +globalVariables: ?boolean,
    +frames: ?boolean,
  |}
|};
export type FinishedRemoveOfferSubscription = {|
  variables: FinishedRemoveOfferSubscriptionVariables,
  response: FinishedRemoveOfferSubscriptionResponse,
|};
*/


/*
subscription FinishedRemoveOfferSubscription {
  finishedRemoveOfferSubscription {
    id
    step
    documents
    rawDocuments
    widgets
    tags
    globalVariables
    frames
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RemoveOfferResult",
    "kind": "LinkedField",
    "name": "finishedRemoveOfferSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "step",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documents",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawDocuments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widgets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "globalVariables",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "frames",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FinishedRemoveOfferSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FinishedRemoveOfferSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ce9b226aea499b52f1fba5c44e2f03e3",
    "id": null,
    "metadata": {},
    "name": "FinishedRemoveOfferSubscription",
    "operationKind": "subscription",
    "text": "subscription FinishedRemoveOfferSubscription {\n  finishedRemoveOfferSubscription {\n    id\n    step\n    documents\n    rawDocuments\n    widgets\n    tags\n    globalVariables\n    frames\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '006a5379cc3841d84913347245dcad79';

module.exports = node;
