import { is } from 'ramda';
import React, { useEffect, useState } from 'react';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import processElements from '../core/processElements';
import resetElements from '../core/resetElements';
import useComments from './useComments';
import useEffectMounted from './useEffectMounted';

const useCommentsWrapper = ({
  children,
  comments,
  config,
  mode,
  handlers,
  itemRootId
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    addMessage,
    updateStatus,
    createComment,
    deleteComment,
    addMessageLoading,
    updateStatusLoading,
    deleteCommentLoading
  } = useComments({ handlers, itemRootId });

  useEffect(() => {
    processElements({
      config,
      comments,
      mode,
      createComment,
      itemRootId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, comments]);

  useEffectMounted((mounted) => {
    if (mounted && isNotNilOrEmpty(comments)) {
      setIsLoaded(false);
      resetElements(itemRootId, comments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, comments]);

  let formattedChildren;

  if (is(Function, children)) {
    formattedChildren = children({ setIsLoaded });
  } else {
    formattedChildren = React.cloneElement(children, { setIsLoaded });
  }

  return {
    addMessage,
    updateStatus,
    deleteComment,
    formattedChildren,
    addMessageLoading,
    updateStatusLoading,
    deleteCommentLoading
  };
};

export default useCommentsWrapper;
