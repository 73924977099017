import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { assocPath, isEmpty, path, prop } from 'ramda';
import AccountLayout from '../../layout/account/AccountLayout';
import { emailIsValid, passwordIsValid } from '../../lib/validation';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import SignUpMutation from '../../_graphql/mutations/SignUpMutation';
import { successNotification } from '../../utils/messageMutation';
import EmailPasswordForm from './components/EmailPasswordForm';

const useSignUp = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
    extra: {
      firstname: '',
      lastname: ''
    }
  });
  const [loading, setLoading] = useState(false);

  const setValue = (k, v) => setValues(assocPath(k.split('.'), v));

  const fieldIsDefined = k => !isEmpty(path(k.split('.'), values));

  const onSignUp = () => {
    setLoading(true);
    SignUpMutation(values, (ok, error) => {
      if (!ok || error) {
        setLoading(false);
        notification.error({ message: 'L\'inscription a échoué, veuillez réessayer.' });
      } else {
        successNotification('Inscription réussie !', 'Vous pouvez dès à présent vous connecter.');
        history.replace('/');
      }
    });
  };

  return {
    emailIsValid: emailIsValid(prop('email', values)),
    passwordIsValid: passwordIsValid(prop('password', values)),
    fieldIsDefined,
    loading,
    setValue,
    onSignUp
  };
};

const SignUp = () => {
  const {
    emailIsValid,
    passwordIsValid,
    loading,
    fieldIsDefined,
    setValue,
    onSignUp
  } = useSignUp();

  const buttonDisabled = !fieldIsDefined('extra.firstname') || !fieldIsDefined('extra.lastname') || !passwordIsValid || !emailIsValid;
  useKeyPressEnter(onSignUp, !buttonDisabled, { emailIsValid, passwordIsValid });

  return (
    <AccountLayout>
      <h3>Créez votre espace flexidocs</h3>

      <h4>Prénom</h4>
      <Input
        prefix={<UserOutlined/>}
        placeholder="Prénom"
        onChange={e => setValue('extra.firstname', e.target.value)}
      />

      <h4>Nom</h4>
      <Input
        prefix={<UserOutlined/>}
        placeholder="Nom"
        onChange={e => setValue('extra.lastname', e.target.value)}
      />

      <EmailPasswordForm
        setValue={setValue} fieldIsDefined={fieldIsDefined} emailIsValid={emailIsValid}
        passwordIsValid={passwordIsValid}
      />

      <br/>
      <br/>

      <Button
        type="primary"
        size="large"
        disabled={buttonDisabled}
        loading={loading}
        onClick={onSignUp}
      >
        Suivant
      </Button>

      <div className="account-extra">
        Vous avez déjà un compte ? &nbsp;
        <Link to="/account/signin">Connectez-vous</Link>
      </div>
    </AccountLayout>
  );
};

export default SignUp;
