import { isNil, map, is } from 'ramda';
import useMatchLocation from './useMatchLocation';

const useBreadcrumb = (routes, defaultRoute) => {
  const {
    getParams,
    matchLocation
  } = useMatchLocation();
  for (let i = 0; i < routes.length; i++) {
    const element = routes[i];
    if (!isNil(element) && matchLocation(element[0])) {
      const params = getParams(element[0]);
      return map(i => {
        if (is(Function, i)) return i(params);
        return i;
      })(element[1]);
    }
  }
  return [defaultRoute];
};

export default useBreadcrumb;
