import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isNil, compose, find, pathEq, propOr } from 'ramda';
import Title from '../../../components/Title';
import Section from '../../../components/Section';
import withUser, { useUserContext } from '../../../withUser';
import Loading from '../../../components/Loading';
import UpdateTeamUserRoleMutation from '../../../_graphql/mutations/teams/UpdateTeamUserRoleMutation';
import { errorNotification, successNotification } from '../../../utils/messageMutation';
import Member from './Member';

export const useManageRole = (userTeam) => {
  const history = useHistory();
  const { userRefetch } = useUserContext();

  const updateRole = (userId, role) => {
    UpdateTeamUserRoleMutation({
      teamId: userTeam.teamId, userId, role
    }, (ok, error) => {
      if (ok && !error) {
        successNotification('Le rôle de l\'utilisateur a été mis à jour.');
        userRefetch();
        history.push('/equipe');
      } else {
        errorNotification();
      }
    });
  };

  return {
    updateRole,
    history
  };
};

const ManageRole = ({
  memberId,
  userTeam
}) => {
  const { updateRole, history } = useManageRole(userTeam);

  if (isNil(memberId)) return null;

  const member = compose(
    find(pathEq(['user', 'id'], memberId)),
    propOr([], 'members')
  )(userTeam);

  if (isNil(member)) {
    return <Loading />;
  }

  return (
    <Section
      title="Gérer mon équipe"
      className="leading-10"
    >
      <img
        src="/icon/icon-fleche.svg"
        alt="back"
        className="absolute cursor-pointer top-[153px] left-[300px]"
        onClick={() => history.replace('/equipe')}
      />

      <Title
        title="Modification du rôle"
        icon="/icon/icon-equipe-dark.svg"
      />

      <Member
        member={member}
        updateRole={updateRole}
      />

    </Section>
  );
};

ManageRole.propTypes = { memberId: PropTypes.string.isRequired };

export default withUser(ManageRole);
