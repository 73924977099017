import React from 'react';

const ColorBlock = ({ color }) => (
  <div
    className="color-block rounded h-8 w-8 mr-2.5 border-2 border-solid border-[#E7EAEA]"
    // eslint-disable-next-line no-restricted-syntax
    style={{ background: color }}
  />
);

export default ColorBlock;
