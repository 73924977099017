import React from 'react';
import useDocument from '../hooks/useDocument';

const DocumentContext = React.createContext({});

export const useDocumentContext = () => React.useContext(DocumentContext);

export const DocumentProvider = ({
  document,
  options,
  children,
  comments,
  globalVariablesFetch,
  predefinedVariables,
  widgetsFetch,
  setRefetch
}) => {
  const context = useDocument(
    document,
    options,
    comments,
    globalVariablesFetch,
    predefinedVariables,
    widgetsFetch,
    setRefetch
  );
  return (
    <DocumentContext.Provider value={context}>
      {children}
    </DocumentContext.Provider>
  );
};

DocumentProvider.defaultProps = {
  document: {},
  options: {}
};
