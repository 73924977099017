import React from 'react';

const AddPlusIcon = () => (
  <svg
    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="16 8 24 24"
  >                     <title>2E310246-676D-4B58-8C05-DD9C774B134D</title>
    <defs>
      <circle
        id="path-1" cx="12" cy="12"
        r="12"
      />
      <filter
        x="-116.7%" y="-83.3%" width="333.3%"
        height="333.3%" filterUnits="objectBoundingBox" id="filter-2"
      >
        <feOffset
          dx="0" dy="8" in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.396078431   0 0 0 0 0.633673985   0 0 0 0 0.839215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g
      id="da" stroke="none" strokeWidth="1"
      fill="none" fillRule="evenodd"
    >
      <g id="mes-offres-/-création-offre-3" transform="translate(-732.000000, -705.000000)">
        <g id="Group-7-Copy" transform="translate(690.000000, 643.000000)">
          <g id="add-option" transform="translate(58.000000, 70.000000)">
            <g id="Oval">
              <use
                fill="black" fillOpacity="1" filter="url(#filter-2)"
                xlinkHref="#path-1"
              />
              <use fill="#0197DC" fillRule="evenodd" xlinkHref="#path-1" />
            </g>
            <path
              d="M10.2956522,16.8695652 C10.2956522,17.2729732 10.622679,17.6 11.026087,17.6 L12.973913,17.6 C13.377321,17.6 13.7043478,17.2729732 13.7043478,16.8695652 L13.7043478,13.826087 C13.7043478,13.7588523 13.7588523,13.7043478 13.826087,13.7043478 L16.8695652,13.7043478 C17.2729732,13.7043478 17.6,13.377321 17.6,12.973913 L17.6,11.026087 C17.6,10.622679 17.2729732,10.2956522 16.8695652,10.2956522 L13.826087,10.2956522 C13.7588523,10.2956522 13.7043478,10.2411477 13.7043478,10.173913 L13.7043478,7.13043478 C13.7043478,6.72702679 13.377321,6.4 12.973913,6.4 L11.026087,6.4 C10.622679,6.4 10.2956522,6.72702679 10.2956522,7.13043478 L10.2956522,10.173913 C10.2956522,10.2411477 10.2411477,10.2956522 10.173913,10.2956522 L7.13043478,10.2956522 C6.72702679,10.2956522 6.4,10.622679 6.4,11.026087 L6.4,12.973913 C6.4,13.377321 6.72702679,13.7043478 7.13043478,13.7043478 L10.173913,13.7043478 C10.2411477,13.7043478 10.2956522,13.7588523 10.2956522,13.826087 L10.2956522,16.8695652 Z" id="Shape" fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AddPlusIcon;
