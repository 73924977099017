import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateOfferFromExistingMutation($offerName: String!, $fromOfferId: ID!, $isCopyOffer: Boolean) {
    createOfferFromExisting(offerName: $offerName, fromOfferId: $fromOfferId, isCopyOffer: $isCopyOffer) {
      ok
      error
    }
  }
`;

export default ({ offerName, fromOfferId, isCopyOffer }, done) => {
  const variables = {
    offerName,
    fromOfferId,
    isCopyOffer
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createOfferFromExisting }) => basicMutationOnCompleted(createOfferFromExisting, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
