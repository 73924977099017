import { RotateRightOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import React from 'react';

const SelectOrientation = ({ defaultOrientation, changeOrientation, currentOrientation, hasLandscapeWidget, disabled }) => {
  const isDisabled = disabled || (hasLandscapeWidget && currentOrientation === 'landscape');

  return (
    <>
      <Tooltip title="Mise en page" color="#0197DC">
        <div className="inline-flex">
          <RotateRightOutlined
            className="flex relative text-sm text-center h-32px px-3 items-center text-black opacity-85 bg-gray-50 border border-solid border-gray-300 rounded-l transition-all duration-300"
          />

          <Select
            className="w-150px h-30px text-left bg-transparent border-0 outline-none transition-all duration-300 appearance-none"
            defaultValue={defaultOrientation}
            onChange={changeOrientation}
            disabled={isDisabled}
          >
            <Select.Option value="portrait">Portrait</Select.Option>
            <Select.Option value="landscape">Paysage</Select.Option>
          </Select>
        </div>
      </Tooltip>
    </>
  );
};

export default SelectOrientation;
