import React from 'react';

const TagIcon = () => (
  <svg
    version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>76B28FB9-198A-49E2-A498-6C2131196E7A</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1268 -978)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 800)">
          <g transform="translate(35 80)">
            <g transform="translate(874 90)">
              <g transform="translate(70 8)">
                <path d="m15.226 6.425-5.6499-5.65c-0.45-0.44964-1.2357-0.775-1.8678-0.775h-5.9578c-0.96499 0-1.7502 0.78536-1.7502 1.7504v5.9571c0 0.63393 0.32535 1.4196 0.77392 1.8686l5.6499 5.6536c0.51626 0.51321 1.1913 0.77036 1.8698 0.77036 0.67678 0 1.3539-0.25729 1.8689-0.7725l5.0642-5.0643c1.0311-1.0311 1.0311-2.7061-0.0010714-3.7382zm-1.2107 2.525-5.0642 5.0643c-0.36178 0.36286-0.95213 0.36214-1.3136 5.572e-4l-5.651-5.6506c-0.12714-0.12857-0.27178-0.475-0.27178-0.65714v-5.9568l0.035714-0.035714h5.9571c0.17971 0 0.52892 0.14454 0.65642 0.27179l5.6499 5.65c0.36214 0.33143 0.36214 0.95286 0.0014286 1.3136zm-9.1892-4.95c0 0.47357-0.38393 0.85714-0.85713 0.85714s-0.82535-0.38214-0.82535-0.85714 0.38393-0.85714 0.85713-0.85714 0.82535 0.38214 0.82535 0.85714z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TagIcon;
