import React from 'react';
import { isNil, prop } from 'ramda';
import { notEqual } from 'ramda-adjunct';
import classNames from 'classnames';

export const verifyRouteAccess = (userTeam, element, phrase, isWithoutBreadcrumb) => {
  if (isNil(element)) return null;
  const elementTeamId = prop('teamId', element) || prop('team', element);
  if (notEqual(prop('teamId', userTeam), elementTeamId)) {
    return (
      <div className={classNames({ 'pt-2.5 px-38px pb-4': isWithoutBreadcrumb })}><span>{`Vous n'avez pas accès à ${phrase}.`}</span></div>
    );
  }
  return null;
};
