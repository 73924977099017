import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';

const Title = ({ title, icon, iconSize = [], iconOffset, backTo, filter, infoTitle = '' }) => {
  const history = useHistory();

  return (
    <div className="flex items-center">
      {!isNil(icon) && (
        <img
          src={icon}
          alt={icon}
          className="mr-10px relative"
          // eslint-disable-next-line no-restricted-syntax
          style={{
            width: `${iconSize[0]}px`,
            height: `${iconSize[1]}px`,
            top: `${iconOffset}px`,
            filter
          }}
        />
      )}
      {!isNil(backTo) && (
        <ArrowLeftOutlined className="btn-return mr-5" onClick={() => history.push(backTo)} />
      )}
      <h4 className="font-bold text-lg uppercase mb-0">
        {title}&nbsp;
      </h4>
      {infoTitle && infoTitle()}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default Title;
