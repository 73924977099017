import { useClickAway } from 'ahooks';
import classNames from 'classnames';
import { all, assoc, assocPath, both, complement, compose, equals, filter, gt, is, map, path, prop, propEq, propOr, reduce, remove, update, when } from 'ramda';
import { notEqual } from 'ramda-adjunct';
import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { createNewElement, droppingItem, iStringToNumber } from '../helpers/elements';
import { editorWidth, getSizeElement, updateLayout, widthOneGrid } from '../helpers/widget';
import DroppedCard from './DroppedCard';
import './layout.css';

const DropZone = ({
  values,
  setState,
  setYScrollBarPosition,
  isDownNbColsGrid,
  widgetIsEditable,
  elements,
  optionsElement,
  widgetMinSize,
  customColors,
  customStyles,
  defaultFont,
  isFocus,
  setIsFocus,
  useCSSTransforms,
  setUseCSSTransforms,
  onCreateStyle
}) => {
  const ref = useRef(null);
  const [layout, setLayout] = useState([]);
  const [isHoveredOver, setIsHoveredOver] = useState([]);
  const { contents, nbColsGrid, heightRowsGrid, orientation } = values;

  const [{ isOver, canDrop, itemDrag }, drop] = useDrop(
    () => ({
      accept: 'CARD',
      drop: (element, monitor) => {
        const position = monitor.getClientOffset();
        const bounds = prop('current', ref).getBoundingClientRect();
        const fixedY = prop('y', position) - prop('y', bounds);
        const newElement = createNewElement(values, element, fixedY, elements, orientation);
        addNewElement(newElement);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        itemDrag: monitor.getItem()
      })
    }),
    [contents, orientation]
  );

  const isActive = isOver && canDrop;
  const widgetIsEmpty = !contents.length && !layout.length;

  if (!widgetIsEmpty) setYScrollBarPosition(propOr('', 'clientHeight', ref.current));

  useEffect(() => {
    setIsHoveredOver(contents.map(() => false));
    setIsFocus(contents.map(() => false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gt(contents.length, layout.length)) updateLayout(contents, nbColsGrid, heightRowsGrid, setLayout, widgetIsEditable, isDownNbColsGrid, elements, widgetMinSize, orientation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents.length]);

  useEffect(() => {
    updateLayout(contents, nbColsGrid, heightRowsGrid, setLayout, widgetIsEditable, isDownNbColsGrid, elements, widgetMinSize, orientation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbColsGrid, heightRowsGrid, orientation]);

  const addNewElement = (newElement) => {
    setState(assocPath(['values', 'contents'], [...contents, newElement]));
    setIsHoveredOver([...contents.map(() => false), true]);
    setIsFocus([...contents.map(() => false), true]);
    setUseCSSTransforms(false);
  };

  const handleDelete = (id) => {
    setState(
      assocPath(
        ['values', 'contents'],
        remove(id, 1, contents)
      )
    );
    setLayout([]);
    setIsHoveredOver(remove(id, 1));
    setIsFocus(remove(id, 1));
    setUseCSSTransforms(true);
  };

  const onLayoutChange = (l) => {
    l.map((item) => {
      const newPosition = {
        x: prop('x', item) * widthOneGrid(nbColsGrid, orientation),
        y: prop('y', item) * heightRowsGrid
      };
      const newSize = {
        width: prop('w', item) === nbColsGrid ? editorWidth(orientation) : widthOneGrid(nbColsGrid, orientation) * prop('w', item),
        height: prop('h', item) * heightRowsGrid
      };
      const isValidNumber = both(is(Number), complement(equals(NaN)));
      if (isValidNumber(iStringToNumber(item.i))) {
        setState(compose(
          assocPath(['values', 'contents', iStringToNumber(item.i), 'position'], newPosition),
          assocPath(['values', 'contents', iStringToNumber(item.i), 'size'], newSize)
        ));
      }
    });
    if (all(notEqual('dropping-item'))(map(prop('i'))(l))) {
      const newLayout = map((item) => ({ ...item, i: iStringToNumber(item.i) }))(l);
      setLayout(newLayout);
    }
  };

  const onDropItem = (newLayout, item) => {
    const fixedY = prop('y', item) * heightRowsGrid;
    const newElement = createNewElement(values, itemDrag, fixedY, elements);
    addNewElement(newElement);
    compose(
      map((l) => {
        const newPosition = { x: prop('x', l) * widthOneGrid(nbColsGrid, orientation), y: prop('y', l) * heightRowsGrid };
        const oldPosition = path(['contents', iStringToNumber(prop('i', l)), 'position'], values);
        if (notEqual(oldPosition, newPosition)) {
          setState(
            assocPath(['values', 'contents', iStringToNumber(prop('i', l)), 'position'], newPosition)
          );
        }
      }),
      filter(l => l !== item)
    )(newLayout);
  };

  useClickAway(() => {
    setIsFocus(map(() => false));
    setUseCSSTransforms(true);
  }, [
    ref,
    document.getElementById('style-bar'),
    ...document.getElementsByClassName('modal'),
    ...document.getElementsByClassName('ant-select-dropdown'),
    ...document.getElementsByClassName('anticon')
  ]);

  const onChangeContentHeight = (id) => (contentHeight) => {
    const newHeight = contentHeight / heightRowsGrid;
    setState(assocPath(['values', 'contents', id, 'size', 'height'], contentHeight));
    const newLayout = map((l) =>
      when(propEq('i', id), assoc('h', newHeight))(l))(layout);
    setLayout(newLayout);
  };

  const actualWidthWidget = reduce((maxWidth, element) => {
    const itemX = path(['position', 'x'], element);
    const itemWidth = getSizeElement('width', element);
    const widthElement = itemX + itemWidth;
    if (widthElement > maxWidth) {
      return widthElement;
    }

    return maxWidth;
  }, 0, contents);

  const getBorderFrameStyle = (position) => {
    const borderWidth = path(['frameStyle', 'borderWidth', position], values);
    const borderStyle = path(['frameStyle', 'borderStyle', position], values);
    const borderColor = path(['frameStyle', 'borderColor', position], values);
    return `${borderWidth}px ${borderStyle} ${borderColor}`;
  };

  const frameStyle = {
    width: `${actualWidthWidget}px`,
    backgroundColor: path(['frameStyle', 'backgroundColor'], values),
    borderRight: getBorderFrameStyle('right'),
    borderLeft: getBorderFrameStyle('left'),
    borderTop: getBorderFrameStyle('top'),
    borderBottom: getBorderFrameStyle('bottom'),
    borderRadius: `${path(['frameStyle', 'borderRadius', 'topLeft'], values)}px ${path(['frameStyle', 'borderRadius', 'topRight'], values)}px ${path(['frameStyle', 'borderRadius', 'bottomRight'], values)}px ${path(['frameStyle', 'borderRadius', 'bottomLeft'], values)}px`
  };
  return (
    <div ref={drop} role="DropZone" className={classNames('w-full h-full shadow-none', { 'bg-flexibranche-blue bg-opacity-20': isActive && !layout.length }, { 'py-0.5': !layout.length })}>
      <div ref={ref} className={classNames('w-full h-full shadow-none relative')}>
        {widgetIsEmpty && (
          <div className={classNames('flex justify-center m-1 p-2 border-2 border-dashed text-inherit', { 'text-flexibranche-blue border-transparent': isActive && !layout.length }, { 'border-flexibranche-blue text-flexibranche-blue': !isActive && canDrop }, { 'border-gray-200': !isActive && !canDrop })}>
            {!isActive ? 'Glisser un élément' : 'Vous pouvez lâcher votre élément'}
          </div>
        )}
        {/* eslint-disable-next-line no-restricted-syntax */}
        <div className="frame-style absolute top-0 left-0 h-full" style={frameStyle} />
        <GridLayout
          cols={nbColsGrid}
          rowHeight={heightRowsGrid}
          width={editorWidth(orientation)}
          autoSize
          measureBeforeMount={true}
          padding={[0, 0]}
          margin={[0, 0]}
          useCSSTransforms={useCSSTransforms}
          allowOverlap={false}
          className="relative transition-height duration-200 ease"
          layout={layout}
          onLayoutChange={onLayoutChange}
          draggableCancel=".not-draggable"
          draggableHandle=".item-grid"
          isDroppable={widgetIsEditable}
          onDrop={(l, item) => onDropItem(l, item)}
          droppingItem={droppingItem(nbColsGrid)}
        >
          {layout.map((l) => {
            const elementContent = path(['contents', prop('i', l)], values);
            if (equals('childrens', prop('type', elementContent))) elementContent.type = 'children';
            if (!elementContent) return null;
            const containerClassName = classNames(
              { 'outline-1 outline-flexibranche-lightblue outline not-draggable': isFocus[prop('i', l)] },
              { 'outline-1 outline-black outline': isHoveredOver[prop('i', l)] && !isFocus[prop('i', l)] },
              'item-grid z-auto'
            );
            return (
              <div
                key={prop('i', l)}
                data-grid={l}
                onMouseOver={() => setIsHoveredOver(update(prop('i', l), true))}
                onMouseOut={() => setIsHoveredOver(update(prop('i', l), false))}
                className={containerClassName}
                onClick={() => {
                  setIsFocus(compose(update(prop('i', l), true), map(() => false)));
                  setUseCSSTransforms(false);
                }}
              >
                <DroppedCard
                  widgetIsEditable={widgetIsEditable}
                  values={values}
                  setState={setState}
                  id={prop('i', l)}
                  type={propOr('', 'type', elementContent)}
                  handleDelete={handleDelete}
                  renderToolbar="#element-toolbar"
                  renderVariable="#element-variable-bar"
                  isFocus={isFocus[prop('i', l)]}
                  elements={elements}
                  optionsElement={optionsElement}
                  onChangeContentHeight={onChangeContentHeight(prop('i', l))}
                  defaultFont={defaultFont}
                  customColors={customColors}
                  customStyles={customStyles}
                  orientation={orientation}
                  onCreateStyle={onCreateStyle}
                />
              </div>
            );
          })}
        </GridLayout>
      </div>
    </div>
  );
};

export default DropZone;
