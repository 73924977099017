import { commitMutation,
  graphql } from 'react-relay';
import environment from '../..';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateReferentFrameMutation($ids: [ID]!) {
    changeReferentDocumentOnFrame(ids: $ids) {
      ok
      error
    }
  }
`;

export default ({ ids }, done) => {
  const variables = { ids };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ changeReferentDocumentOnFrame }) => basicMutationOnCompleted(changeReferentDocumentOnFrame, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
