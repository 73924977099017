import { commitMutation,
  graphql } from 'react-relay';
import environment from '../.';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
mutation SignUpMutation($email: String!, $password: String!, $extra: UserExtraInput!) {
  signUp(email: $email, password: $password, extra: $extra) { 
    ok
    error
  }
}
`;

export default ({ email, password, extra }, done) => {
  const variables = {
    email,
    password,
    extra
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ signUp }) => basicMutationOnCompleted(signUp, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
