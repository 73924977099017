import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { propOr, reject, find, propEq, path, isNil, assoc, prop, map, length } from 'ramda';
import { Input, Select, Button, Table, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import QGroup from '../../_graphql/queries/groups/QGroup';
import { ChevronBottom, Section, Title } from '../../components';
import withUser from '../../withUser';
import UpdateGroupMutation from '../../_graphql/mutations/groups/UpdateGroupMutation';
import RemoveGroupMemberMutation from '../../_graphql/mutations/groups/RemoveGroupMemberMutation';
import AddGroupMemberMutation from '../../_graphql/mutations/groups/AddGroupMemberMutation';
import { Trash } from '../../components/icons';
import { getEmail } from '../helpers/getEmail';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import AvatarEmail from './AvatarEmail';

const useEditGroup = ({ groupId, userTeam, group }) => {
  const [state, setState] = useState({
    nameLoading: false,
    addMemberLoading: false,
    name: propOr('', 'name', group),
    userToAdd: undefined
  });

  const groupMembers = propOr([], 'members', group);
  const teamMembers = propOr([], 'members', userTeam);

  const teamMembersNotInGroup = reject(member => {
    const groupMember = find(propEq('userId', path(['user', 'id'], member)))(groupMembers);
    return !isNil(groupMember);
  })(teamMembers);

  const getUserEmail = userId => getEmail(userId, teamMembers);

  const addMember = () => {
    setState(assoc('addMemberLoading', true));
    state.userToAdd.map(memberId => {
      AddGroupMemberMutation({ groupId, memberId }, (ok, error) => {
        setState(assoc('addMemberLoading', false));
        if (ok && !error) {
          setState(assoc('userToAdd', undefined));
          successMessage('utilisateur', 'ajouté au groupe', true);
        } else {
          errorMessage();
        }
      });
    });
  };

  const dropMember = (memberId) => {
    setState(assoc('dropMemberLoading', true));

    RemoveGroupMemberMutation({ groupId, memberId }, (ok, error) => {
      setState(assoc('dropMemberLoading', false));

      if (ok && !error) {
        successMessage('utilisateur', 'retiré au groupe', true);
      } else {
        errorMessage();
      }
    });
  };

  const saveName = (history) => {
    setState(assoc('nameLoading', true));
    UpdateGroupMutation({ groupId, name: prop('name', state) }, (ok, error) => {
      setState(assoc('nameLoading', false));

      if (ok && !error) {
        successMessage('groupe', 'mis à jour');
        history.push('/equipe');
      } else {
        errorMessage();
      }
    });
  };

  return {
    ...state,
    setState,
    addMember,
    dropMember,
    saveName,
    teamMembersNotInGroup,
    groupMembers,
    getUserEmail
  };
};

const EditGroup = ({
  group,
  userTeam,
  groupId,
  history
}) => {
  const {
    name,
    userToAdd,
    nameLoading,
    addMemberLoading,
    setState,
    addMember,
    dropMember,
    saveName,
    teamMembersNotInGroup,
    groupMembers,
    getUserEmail
  } = useEditGroup({
    groupId, userTeam, group
  });

  return (
    <>
      <label className="block mb-2 font-bold">Nom du groupe</label>
      <Input
        placeholder="Nom du groupe"
        value={name}
        onChange={e => setState(assoc('name', e.target.value))}
        className="w-full"
      />

      <div className="mt-8">
        <label className="block mb-2 font-bold">Utilisateurs du groupe</label>
        <Select
          className="w-full"
          placeholder="Ajouter un utilisateur à ce groupe"
          value={userToAdd}
          showArrow
          suffixIcon={<ChevronBottom />}
          mode="multiple"
          onChange={v => setState(assoc('userToAdd', v))}
        >
          {map(member => {
            const memberId = path(['user', 'id'])(member);

            return (
              <Select.Option
                key={memberId}
                value={memberId}
              >
                {path(['user', 'email'])(member)}
              </Select.Option>
            );
          })(teamMembersNotInGroup)}
        </Select>

        <Button
          onClick={addMember}
          loading={addMemberLoading}
          disabled={isNil(userToAdd)}
          className="mt-4"
          type="primary"
          icon={<PlusOutlined />}
        >
          {length(userToAdd) > 1 ? 'Ajouter ces utilisateurs au groupe' : 'Ajouter l\'utilisateur au groupe'}
        </Button>
      </div>

      <Table
        dataSource={groupMembers}
        pagination={false}
        rowKey="userId"
        className="mb-8 mt-10"
      >
        <Table.Column
          title="Email"
          dataIndex="userId"
          render={userId => {
            const email = getUserEmail(userId);
            return (
              <AvatarEmail email={email} />
            );
          }}
        />

        <Table.Column
          title="Actions"
          dataIndex="userId"
          render={(userId) => (
            <Popconfirm
              title="Êtes-vous sûr de vouloir retirer cet utilisateur du groupe ?"
              onConfirm={() => dropMember(userId)}
            >
              <Trash />
            </Popconfirm>
          )}
        />

      </Table>

      <Button
        loading={nameLoading}
        onClick={() => saveName(history)}
        type="primary"
      >
        Sauvegarder le groupe
      </Button>
    </>
  );
};

const EditGroupWrapper = ({
  userTeam,
  userIsAdmin
}) => {
  const { groupId } = useParams();
  const history = useHistory();

  if (!userIsAdmin) {
    return null;
  }

  return (
    <>
      <Title title="Gestion du groupe" />
      <Section>
        <QGroup args={{ groupId }}>
          {group => {
            if (isNil(group)) {
              return null;
            }

            return (
              <EditGroup
                group={group}
                userTeam={userTeam}
                groupId={groupId}
                history={history}
              />
            );
          }}
        </QGroup>
      </Section>
    </>
  );
};

export default withUser(EditGroupWrapper);
