/* eslint-disable react/display-name */
import { CloseOutlined, DeleteOutlined, FolderAddOutlined, FolderOutlined, FormOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, Space, Tooltip, message } from 'antd';
import { always, any, cond, equals, filter, forEach, has, ifElse, includes, length, map, not, path, pathOr, prop, propEq, propOr, T } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty, notEqual } from 'ramda-adjunct';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useDocumentsTable from '../../hooks/useDocumentsTable';
import { deleteMutationMessage } from '../../utils/messageMutation';
import DeleteWidgetsMutation from '../../_graphql/mutations/documents/DeleteWidgetsMutation';
import { useTreeContext } from './treeContext';

const TreeRow = memo(({ data, ElementRow, setElements, setRefetchWidgets }) => {
  const { addTree, updateTree, removeTree, expandedKeys, setIsLoadingOnDelete, reFetchFolder } = useTreeContext();
  const { deleteDocuments, deleteRawDocuments } = useDocumentsTable();

  const [showAction, setShowAction] = useState(false);
  const [elementsTree, setElementsTree] = useState([]);

  const refEditable = useRef(null);
  const refRow = useRef(null);
  const mouseenter = useCallback(() => setShowAction(true), []);
  const mouseout = useCallback(() => setShowAction(false), []);
  const element = prop('element', data);
  const children = prop('children', data);
  const isFolder = propOr(false, 'isFolder', element);
  const allowEdit = propOr(false, 'allowEdit', element);
  const { elmId: elementId, name, treeId, allowAdd, allowDelete } = element;
  const hasParentFolder = isNotNilOrEmpty(prop('parent', data));

  const getElements = (children) => {
    let elements = [];

    forEach(child => {
      const element = prop('element', child);
      const getProperty = (property) => path(['element', property], child);

      if (element) {
        const getType = (child) => {
          const isDocument = isNotNilOrEmpty(path(['element', 'pages'], child));
          const isFolder = getProperty('isFolder');
          const isWidget = has('contents', propOr({}, 'element', child));

          return cond([
            [() => isDocument, always('document')],
            [() => isFolder, always('folder')],
            [() => isWidget, always('widget')],
            [T, always('rawDocument')]
          ])();
        };

        elements.push({
          type: getType(child),
          elmId: getProperty('elmId'),
          treeId: getProperty('treeId'),
          parentId: getProperty('parent'),
          isUsed: getProperty('isUsed') || false,
          name: getProperty('name'),
          rootTreeId: hasParentFolder && children[0].parent.id
        });
      }
      const grandChild = pathOr([], ['children'], child);
      if (grandChild && grandChild.length > 0) {
        const childElements = getElements(grandChild);
        elements = elements.concat(childElements);
      }
    })(children);
    return elements;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setElementsTree(getElements(children)), [data]);

  const isUsedWidgets = any(propEq('isUsed', true))(elementsTree);

  const handleDeleteElement = (elements) => {
    setIsLoadingOnDelete(true);
    const documentsIds = [];
    const rawDocumentsIds = [];
    const widgetsIds = [];
    const foldersIds = [];

    forEach((element) => {
      const elmId = propOr('', 'elmId', element);
      const treeId = propOr('', 'treeId', element);
      cond([
        [equals('document'), () => {
          documentsIds.push(elmId);
          foldersIds.push(treeId);
        }],
        [equals('rawDocument'), () => {
          rawDocumentsIds.push(elmId);
          foldersIds.push(treeId);
        }],
        [equals('widget'), () => {
          widgetsIds.push(elmId);
          foldersIds.push(treeId);
        }
        ],
        [equals('folder'), () => foldersIds.push(treeId)]
      ])(prop('type', element));
      if (element.parentId && !foldersIds.includes(element.parentId)) {
        foldersIds.push(element.parentId);
      }
    })(elements);

    const updateElements = (type) => () => {
      const ids = map(propOr('', 'elmId'))(elements);
      const newElements = filter((value) => not(includes(prop('elmId', value), ids)))(elements);
      setElements(newElements);
      const msg = ifElse(
        equals('widget'),
        always('Les widgets'),
        always('Les documents')
      )(type);

      message.success(`${msg} liés au dossier ont été supprimés`);
      if (equals('widget', type)) setRefetchWidgets(true);
    };

    const callback = (ok) => ok && updateElements('document')();
    const infoMsg = (nb, type) => `Suppression de ${nb} ${type} en cours, veuillez patienter...`;

    const handleDeletion = (ids, type, deleteFunc) => new Promise((resolve) => {
      if (isNotNilOrEmpty(ids)) {
        message.info(infoMsg(length(ids), type));
        deleteFunc(ids, (ok) => {
          if (ok) callback(ok);
        });
      }
      resolve();
    });

    const handleWidgetsDeletion = () => new Promise((resolve) => {
      if (isNotNilOrEmpty(widgetsIds)) {
        message.info(infoMsg(length(widgetsIds), 'widgets'));
        DeleteWidgetsMutation(
          { widgetsIds: widgetsIds },
          (ok, error) => {
            resolve();
            if (ok) {
              deleteMutationMessage(ok, error, 'widget', updateElements('widget'), false, false, true);
            }
          }
        );
      }
    });

    const handleFoldersDeletion = () => new Promise((resolve) => {
      if (isNotNilOrEmpty(foldersIds)) removeTree(foldersIds, data);
      resolve();
    });

    const deleteAll = async () => {
      try {
        await Promise.all([
          handleFoldersDeletion(),
          handleDeletion(documentsIds, 'documents', deleteDocuments),
          handleDeletion(rawDocumentsIds, 'documents importés', deleteRawDocuments),
          handleWidgetsDeletion()
        ]);

        reFetchFolder();
        if (isNotNilOrEmpty(documentsIds) || isNotNilOrEmpty(widgetsIds)) setRefetchWidgets(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingOnDelete(false);
      }
    };
    deleteAll();
  };

  const getElementRow = useMemo(
    () =>
      React.cloneElement(ElementRow, {
        folder: data,
        elementId,
        refRow,
        showAction
      }),
    [ElementRow, data, elementId, showAction]
  );

  useEffect(() => {
    const current = prop('current', refRow);
    current.addEventListener('mouseover', mouseenter);
    current.addEventListener('mouseleave', mouseout);
    return () => {
      current.removeEventListener('mouseover', mouseenter);
      current.removeEventListener('mouseleave', mouseout);
    };
  }, [mouseenter, mouseout]);

  const handleBlur = () => {
    const text = refEditable.current.innerText;
    if (notEqual(name, text)) updateTree(treeId, expandedKeys)({ name: text });
  };

  return (
    <Row gutter={4} ref={refRow} title="">
      {!isFolder ? (
        getElementRow
      ) : (
        <>
          <Col>
            <FolderOutlined />
          </Col>

          <Col ref={refRow}>
            <span
              ref={refEditable}
              className="p-1"
              suppressContentEditableWarning={true}
              contentEditable={allowEdit}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              spellCheck={false}
            >
              {name}
            </span>
          </Col>

          <Col className={showAction ? 'opacity-100' : 'opacity-0'}>
            <Space>
              {hasParentFolder && allowEdit && (
                <Tooltip
                  title="Retirer le dossier de l'arborescence"
                  color="#0197DC"
                >
                  <CloseOutlined
                    onClick={() => updateTree(element.treeId, null, true)({ parent: null })}
                    className="btn-remove-folder-root text-black hover:text-flexibranche-darkgray"
                  />
                </Tooltip>
              )}
              {allowEdit && (
                <Tooltip title="Éditer" color="#0197DC">
                  <FormOutlined
                    onClick={() => {
                      refEditable.current.focus();
                    }}
                    className="btn-edit-folder hover:text-flexibranche-darkgray"
                  />
                </Tooltip>
              )}
              {allowAdd && (
                <Tooltip title="Ajouter un dossier" color="#0197DC">
                  <FolderAddOutlined
                    onClick={() => addTree(treeId, expandedKeys)()}
                    className="btn-add-folder hover:text-flexibranche-darkgray"
                  />
                </Tooltip>
              )}
              {allowDelete && (
                <Popconfirm
                  placement="topRight"
                  title="Êtes-vous sûr de vouloir supprimer ce dossier et son contenu ?"
                  onConfirm={() => handleDeleteElement(elementsTree)}
                  okText="Oui"
                  cancelText="Non"
                  disabled={isUsedWidgets || isNilOrEmpty(children)}
                >
                  <Tooltip
                    title={
                      isUsedWidgets
                        ? 'Ce dossier contient un widget utilisé dans un document, vous ne pouvez pas le supprimer.'
                        : 'Supprimer le dossier'
                    }
                    color="#0197DC"
                    onClick={() => isNilOrEmpty(children) && removeTree(treeId, data)}
                  >
                    <DeleteOutlined
                      className="btn-remove-folder hover:text-red-300"
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </Space>
          </Col>
        </>
      )}
    </Row>
  );
});

export default TreeRow;
