import { TeamOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { prop } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AccountLayout from '../../../layout/account/AccountLayout';
import { errorNotification, successNotification } from '../../../utils/messageMutation';
import withUser from '../../../withUser';
import CreateTeamMutation from '../../../_graphql/mutations/teams/CreateTeamMutation';

const useCreateTeam = (user) => {
  const [teamName, setTeamName] = useState('');
  const history = useHistory();

  const onCreateTeam = teamName => {
    CreateTeamMutation({ name: teamName, userId: prop('userId', user) }, (ok, error) => {
      if (ok && !error) {
        successNotification('L\'équipe a été créée !');
        history.push('/equipe');
      } else {
        errorNotification();
      }
    });
  };

  return {
    teamName,
    setTeamName,
    onCreateTeam
  };
};

const NewTeam = ({
  user
}) => {
  const {
    teamName,
    setTeamName,
    onCreateTeam
  } = useCreateTeam(user);

  return (
    <AccountLayout
      img="/image/illu-equipe@3x.png"
      imgStyle={{
        width: '433px',
        top: '173px',
        right: '47px'
      }}
    >

      <h3>Donnez un nom à votre équipe</h3>

      <h4>{'Nom de l\'équipe'}</h4>

      <Input
        placeholder="Nom de l'équipe"
        onChange={e => setTeamName(e.target.value)}
        prefix={<TeamOutlined className="w-5"/>}
        className="w-300px"
      />

      <br/>
      <br/>

      <Button
        className="btn-create-team"
        onClick={() => onCreateTeam(teamName)}
        type="primary"
        disabled={isNilOrEmpty(teamName)}
      >
        Suivant
      </Button>

    </AccountLayout>
  );
};

export default withUser(NewTeam);
