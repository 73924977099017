import { addIndex, reduce, propSatisfies, compose, propOr, defaultTo, find, propEq, prop, pathOr } from 'ramda';
import { isNilOrEmpty, isNotEmpty } from 'ramda-adjunct';
import conditions from './conditions';

export const reduceIndexed = addIndex(reduce);

export const propsAreDefined = (props) => (object) => reduce((acc, prop) => {
  return acc && !propSatisfies(isNilOrEmpty, prop)(object);
}, true)(props);

export const conditionIsWithValue = condition => compose(
  propOr(false, 'withValue'),
  defaultTo({}),
  find(propEq('key', condition))
)(conditions);

const useRules = (state) => {
  const getRulesAreValid = reduce((acc, rule) => {
    return acc && propsAreDefined(['action'])(rule);
  }, true);

  const getConditionsAreValid = reduceIndexed((acc, condition, conditionIndex) => {
    let isValid = propsAreDefined(['condition', 'variable'])(condition);
    if (conditionIndex !== 0) isValid = isValid && propsAreDefined(['operator'])(condition);
    if (conditionIsWithValue(prop('condition', condition))) isValid = isValid && propsAreDefined(['value'])(condition);
    return acc && isValid;
  }, true);

  const contentsRuleValid = (rules) => reduce((accRule, rule) => {
    const conditions = propOr([], 'conditions', rule);
    return accRule && isNotEmpty(conditions) && getConditionsAreValid(conditions);
  }, true)(rules);

  const allRulesAreValid = compose(
    reduce((accSection, page) => {
      const rulesPage = propOr([], 'rules', page);
      const validRulesPage = getRulesAreValid(rulesPage) && contentsRuleValid(rulesPage);

      const getValidRulesType = (type) => reduce((accWidgetRow, widgetRow) => {
        return accWidgetRow && reduce((accWidget, widget) => {
          const rules = propOr([], 'rules', widget);
          return accWidget && getRulesAreValid(rules) && contentsRuleValid(rules);
        }, true)(propOr([], 'widgets', widgetRow));
      }, true)(propOr([], type, page));

      const validRulesWidgets = getValidRulesType('widgets');
      const validRulesHeader = getValidRulesType('header');
      const validRulesFooter = getValidRulesType('footer');
      return accSection && validRulesPage && validRulesWidgets && validRulesHeader && validRulesFooter;
    }, true),
    pathOr([], ['values', 'pages'])
  )(state);

  return {
    getRulesAreValid,
    getConditionsAreValid,
    allRulesAreValid
  };
};

export default useRules;
