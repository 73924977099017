import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation RemoveVersionMutation($versionId: ID!) {
    removeVersion(versionId: $versionId) {
      ok
      error
    }
  }
`;

export default ({ versionId, itemId }, done) => {
  const variables = { versionId };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { removeVersion: { ok, error } }) => {
        updaterBasicDelete(proxyStore, ok, error, versionId);
        updaterBasicDelete(proxyStore, ok, error, itemId);
      },
      onCompleted: ({ removeVersion }) => basicMutationOnCompleted(removeVersion, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
