import React, { useState } from 'react';
import { assoc, map, either, isNil, isEmpty, compose, prop, defaultTo, find, both, propEq, propOr } from 'ramda';
import { Select } from 'antd';
import withUser from '../../../withUser';
import { Section, ChevronBottom } from '../../../components';
import QGroups from '../../../_graphql/queries/groups/QGroups';
import UpdateOrAddGroupRolesMutation from '../../../_graphql/mutations/groups/UpdateOrAddGroupRolesMutation';
import { errorMessage, successMessage } from '../../../utils/messageMutation';

const useEditOfferValidator = (offerId, currentGroupId) => {
  const [state, setState] = useState({
    groupId: currentGroupId,
    loading: false
  });

  const onUpdateOfferValidator = (groupId) => {
    const formerGroupId = state.groupId;

    setState({
      groupId,
      loading: true
    });

    UpdateOrAddGroupRolesMutation({
      groupId, formerGroupId, itemId: offerId, itemType: 'offer', roles: ['offerValidator']
    }, (ok, error) => {
      setState(assoc('loading', false));

      if (ok && !error) {
        successMessage('groupe valideur de l\'offre', 'mis à jour');
      } else {
        setState(assoc('groupId', formerGroupId));
        errorMessage();
      }
    });
  };

  return {
    ...state,
    onUpdateOfferValidator
  };
};

const EditOfferValidator = ({
  offerId,
  currentGroupId,
  groups,
  userCanEditOffer
}) => {
  const {
    groupId,
    loading,
    onUpdateOfferValidator
  } = useEditOfferValidator(offerId, currentGroupId);

  return (
    <Section
      title="Valideur de l'offre"
    >
      <div className="mb-2">Sélectionner le groupe en charge de la validation de cette offre :</div>

      <Select
        placeholder="Groupe valideur"
        value={groupId}
        onChange={onUpdateOfferValidator}
        className="w-full"
        loading={loading}
        size="large"
        suffixIcon={<ChevronBottom />}
        disabled={!userCanEditOffer}
      >
        {map(group => {
          const { name, id } = group;

          return (
            <Select.Option
              value={id}
              key={id}
            >
              {name}
            </Select.Option>
          );
        })(groups)}
      </Select>
    </Section>
  );
};

const EditOfferValidatorWrapper = ({
  offerId,
  userIsAdmin,
  userCanEditOffer
}) => {
  if (!userIsAdmin) {
    return null;
  }

  return (
    <QGroups>
      {groups => {
        if (either(isNil, isEmpty)(groups)) {
          return null;
        }

        const currentGroupId = compose(
          prop('id'),
          defaultTo({}),
          find(
            compose(
              find(
                both(
                  propEq('itemType', 'offer'),
                  propEq('itemId', offerId)
                )
              ),
              propOr([], 'roles')
            )
          )
        )(groups);

        return (
          <EditOfferValidator
            offerId={offerId}
            groups={groups}
            currentGroupId={currentGroupId}
            userCanEditOffer={userCanEditOffer}
          />
        );
      }}
    </QGroups>
  );
};

export default withUser(EditOfferValidatorWrapper);
