import { compose, defaultTo, equals, filter, flatten, includes, is, keys, map, mergeAll, pick, prop, values } from 'ramda';
// FLEXIGET DEFAULT
import { Elements as ElementsFlexigetDefault,
  minSizeElement as minSizeElementFlexigetDefault,
  optionsElement as optionsElementFlexigetDefault,
  getElement as getElementByVersionFlexigetDefault } from '@fasstech/flexiget';
// FLEXIGET MME
import { correspondenceVariableLabel,
  Elements as ElementsFlexigetMME,
  minSizeElement as minSizeElementFlexigetMME,
  optionsElement as optionsElementFlexigetMME } from '@fasstech/flexiget-mme';
// FLEXIGET April
import { correspondenceVariableLabel as correspondenceVariableLabelFlexigetApril,
  Elements as ElementsFlexigetApril,
  minSizeElement as minSizeElementFlexigetApril,
  optionsElement as optionsElementFlexigetApril,
  getElement as getElementByVersionFlexigetApril } from '@fasstech/flexiget-april';

const ElementsFlexigets = {
  flexigetDefault: ElementsFlexigetDefault,
  flexigetMME: ElementsFlexigetMME,
  flexigetApril: ElementsFlexigetApril
};

const optionsElement = {
  flexigetDefault: optionsElementFlexigetDefault,
  flexigetMME: optionsElementFlexigetMME,
  flexigetApril: optionsElementFlexigetApril
};

const minSizeElement = {
  flexigetDefault: minSizeElementFlexigetDefault,
  flexigetMME: minSizeElementFlexigetMME,
  flexigetApril: minSizeElementFlexigetApril
};

const getFlexigetOptionsElement = (type) => {
  const types = is(Array) ? type : [type];
  const optionsElementKey = keys(optionsElement);

  const typeFilteredKey = map(type => filter(includes(type))(optionsElementKey))(types);

  return compose(
    flatten,
    values,
    pick(flatten(typeFilteredKey)),
    defaultTo([prop('flexigetDefault', optionsElement)])
  )(optionsElement);
};

const getFlexigetElements = (type) => {
  const types = is(Array) ? type : [type];
  const ElementsFlexigetsKeys = keys(ElementsFlexigets);

  const typeFilteredKey = map(type => filter(includes(type))(ElementsFlexigetsKeys))(types);

  return compose(
    mergeAll,
    flatten,
    values,
    pick(flatten(typeFilteredKey))
  )(ElementsFlexigets);
};

const getFlexigetSizeElements = (type) => {
  const types = is(Array) ? type : [type];
  const minSizeElementKeys = keys(minSizeElement);

  const typeFilteredKey = map(type => filter(includes(type))(minSizeElementKeys))(types);
  const valuesMinSize = compose(
    values,
    pick(flatten(typeFilteredKey))
  )(minSizeElement);

  const minWidthElementOnPx = compose(
    mergeAll,
    map(prop('minWidthElementOnPx'))
  )(valuesMinSize);

  return { minWidthElementOnPx };
};

const getElementByVersion = (type, version) => {
  if (equals(type, 'mandatApril')) {
    return getElementByVersionFlexigetApril(type, version);
  } else {
    return getElementByVersionFlexigetDefault(type, version);
  }
};

export {
  correspondenceVariableLabel,
  correspondenceVariableLabelFlexigetApril,
  ElementsFlexigets,
  optionsElement,
  minSizeElement,
  getFlexigetOptionsElement,
  getFlexigetElements,
  getFlexigetSizeElements,
  getElementByVersion
};
