import React from 'react';

const CrossIcon = () => (
  <svg
    version="1.1" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>57D2C0F4-9C8F-4826-B26C-7979C3B8D161</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1007 -266)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(941 180)">
          <g transform="translate(0 36)">
            <g transform="translate(5 38)">
              <path d="m70.705 20.294c0.39059 0.39059 0.39059 1.0234 0 1.414-0.19373 0.1953-0.44996 0.29216-0.70619 0.29216s-0.51184-0.097649-0.70682-0.29295l-3.2929-3.2911-3.2926 3.2904c-0.1953 0.19686-0.45121 0.29373-0.70713 0.29373s-0.51152-0.096867-0.70698-0.29373c-0.39059-0.39059-0.39059-1.0234 0-1.414l3.2935-3.2935-3.2935-3.2919c-0.39059-0.39059-0.39059-1.0234 0-1.414 0.39059-0.39059 1.0234-0.39059 1.414 0l3.2927 3.2951 3.2935-3.2935c0.39059-0.39059 1.0234-0.39059 1.414 0 0.39059 0.39059 0.39059 1.0234 0 1.414l-3.2935 3.2935 3.2919 3.2919z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CrossIcon;
