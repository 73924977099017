import { isNil } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation SaveElementReviewMutation($elementReviewId: ID, $itemRootId: String!, $itemId: String!, $groupId: String!, $status: ElementReviewStatusEnum!, $itemType: String) {
    saveElementReview(elementReviewId: $elementReviewId, itemRootId: $itemRootId, itemId: $itemId, groupId: $groupId, status: $status, itemType: $itemType) {
      ok
      error
      elementReview {
        id
      }
    }
  }
`;

export default ({
  elementReviewId,
  itemRootId,
  itemId,
  groupId,
  status,
  itemType
}, done) => {
  const variables = {
    elementReviewId,
    itemRootId,
    itemId,
    groupId,
    status,
    itemType
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { saveElementReview: { ok, error, elementReview } }) => {
        if (ok && !error) {
          if (!isNil(elementReviewId)) {
            const storedElementReview = proxyStore.get(elementReviewId);

            if (!isNil(storedElementReview)) {
              storedElementReview.setValue(status, 'status');
            }
          } else if (!isNil(elementReview) && !isNil(elementReview.id)) {
            location.reload();
          }
        }
      },
      onCompleted: ({ saveElementReview }) => basicMutationOnCompleted(saveElementReview, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
