/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type QTagQueryVariables = {|
  tagId: string
|};
export type QTagQueryResponse = {|
  +tag: ?{|
    +id: ?string,
    +teamId: ?string,
    +name: ?string,
    +color: ?string,
    +itemType: ?string,
    +itemsIds: ?$ReadOnlyArray<?string>,
    +scope: ?DocumentScopeEnum,
    +offerId: ?string,
    +rules: ?$ReadOnlyArray<?{|
      +itemId: ?string,
      +rules: ?$ReadOnlyArray<?{|
        +action: ?DocumentRuleActionEnum,
        +conditions: ?$ReadOnlyArray<?{|
          +condition: ?DocumentRuleConditionEnum,
          +variable: ?string,
          +value: ?string,
          +operator: ?DocumentRuleOperatorEnum,
        |}>,
      |}>,
    |}>,
  |}
|};
export type QTagQuery = {|
  variables: QTagQueryVariables,
  response: QTagQueryResponse,
|};
*/


/*
query QTagQuery(
  $tagId: ID!
) {
  tag(tagId: $tagId) {
    id
    teamId
    name
    color
    itemType
    itemsIds
    scope
    offerId
    rules {
      itemId
      rules {
        action
        conditions {
          condition
          variable
          value
          operator
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tagId",
        "variableName": "tagId"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "tag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemsIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scope",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentTagRule",
        "kind": "LinkedField",
        "name": "rules",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentRule",
            "kind": "LinkedField",
            "name": "rules",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentRuleCondition",
                "kind": "LinkedField",
                "name": "conditions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "condition",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operator",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QTagQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QTagQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a8ad0966f72eafdbf56a10569c6b99c0",
    "id": null,
    "metadata": {},
    "name": "QTagQuery",
    "operationKind": "query",
    "text": "query QTagQuery(\n  $tagId: ID!\n) {\n  tag(tagId: $tagId) {\n    id\n    teamId\n    name\n    color\n    itemType\n    itemsIds\n    scope\n    offerId\n    rules {\n      itemId\n      rules {\n        action\n        conditions {\n          condition\n          variable\n          value\n          operator\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77de313786a473921b008d8f3babb3fe';

module.exports = node;
