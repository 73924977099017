import React, { useEffect, useRef, useState } from 'react';
import { Button, Empty, Input, Modal, Select, Table, Checkbox, Popconfirm } from 'antd';
import { any, compose, defaultTo, equals, filter, find, findIndex, flatten, gt, head, includes, isEmpty, last, length, map, not, path, pathEq, pathOr, pluck, prop, propEq, propOr, sortBy } from 'ramda';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DownOutlined, FilterOutlined, SearchOutlined, SwapOutlined } from '@ant-design/icons';
import { isNotEmpty, isNotNil, isNotNilOrEmpty } from 'ramda-adjunct';
import classNames from 'classnames';
import { renderDate } from '../docs/components/helpers/dates';
import { Section } from '../../components';
import useFrame from '../../hooks/useFrame';
import { getReferentsOnList } from '../helpers/referent';
import { sortDateDESC } from '../helpers/sortDate';
import usePagination from '../../hooks/usePagination';
import StatusComparison from './StatusComparison';
import ComparisonDetails from './ComparisonDetails';
import FrameDropdown from './components/FrameDropdown';
import FrameCommentInput from './components/FrameCommentInput';

const TableList = ({
  selected,
  setSelected,
  setRefetch,
  hasComparisonInProgress,
  offer,
  offerId,
  setInfosFrameId
}) => {
  const { onUpdate } = useFrame({});
  const { onChangePagination, pagination } = usePagination({
    itemsNumber: length(offer), type: 'frame', pageSizeKey: 'framesList', offerId
  });
  const searchInputRef = useRef(null);
  const currentDataSourceRef = useRef(offer);

  useEffect(() => {
    currentDataSourceRef.current = offer;
  }, [offer]);

  const referents = filter(({ isReferent }) => isReferent)(currentDataSourceRef.current);
  const areAllChecked = length(referents) === length(selected);
  const isIndeterminate = length(selected) > 0 && length(selected) < length(referents);

  const getStatusComparison = (lastComparison) => propOr('', 'globalStatusResult', lastComparison);

  const getColumnSearchProps = (columnKey, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="p-2 inline-flex justify-center items-center space-x-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInputRef}
          placeholder={placeholder}
          value={head(selectedKeys)}
          onChange={(e) => {
            const value = path(['target', 'value'], e);
            const selected = value ? [value] : [];
            setSelectedKeys(selected);
          }}
          onPressEnter={() => confirm()}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          className="!bg-flexidocs-turquoise !border-flexidocs-turquoise w-30px"
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={`${filtered ? '!text-flexidocs-turquoise' : ''}`} />
    ),
    onFilter: (value, record) =>
      record[columnKey]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) setTimeout(() => searchInputRef.current.select(), 100);
    }
  });

  const renderValueOnTable = (value) => <span>{value}</span>;
  const baseTable = (key, width) => ({
    dataIndex: key,
    key,
    width
  });

  const handleCommentChange = (frameId, e) => {
    const comment = pathOr('', ['target', 'value'], e);
    onUpdate(setRefetch, frameId, { comment });
  };

  const handleCheckAll = (e) => {
    const check = e.target.checked;
    setSelected(check ? map(prop('id'))(referents) : []);
  };

  const handleOnChangeTable = (pagination, filters, sorter, extra) => {
    const newDataSource = prop('currentDataSource', extra);
    const newDataSourceIds = map(prop('id'))(newDataSource);
    const idsToKeep = filter((id) => includes(id, newDataSourceIds))(selected);
    if (not(equals(selected, idsToKeep))) {
      setSelected(idsToKeep);
    }
    currentDataSourceRef.current = newDataSource;
    onChangePagination(pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: 'Statut',
      ...baseTable('comparisons', 70),
      onFilter: (value, record) => {
        const lastComparison = head(sortDateDESC(prop('comparisons', record)));
        const statusComparison = getStatusComparison(lastComparison);
        return equals(statusComparison, value);
      },
      filters: [
        {
          text: 'En cours',
          value: 'waiting'
        },
        {
          text: 'Échoué',
          value: 'failed'
        },
        {
          text: 'Erreur',
          value: 'error'
        },
        {
          text: 'Neutre',
          value: ''
        },
        {
          text: 'Valide',
          value: 'passed'
        }
      ],
      render: (comparisons) => {
        const lastComparison = head(sortDateDESC(comparisons));
        const statusComparison = getStatusComparison(lastComparison);
        return <StatusComparison status={statusComparison} />;
      },
      filterIcon: (filtered) => <FilterOutlined className={`${filtered ? '!text-flexidocs-turquoise' : ''}`} />,
      sorter: (first, second) => {
        const getStatus = compose(
          getStatusComparison,
          head,
          sortDateDESC,
          prop('comparisons')
        );

        const firstStatus = getStatus(first);
        const secondStatus = getStatus(second);

        return firstStatus.localeCompare(secondStatus);
      }
    },
    {
      title: 'Version',
      ...baseTable('version', 110),
      ...getColumnSearchProps('version', 'Rechercher une version'),
      render: renderValueOnTable,
      filterIcon: (filtered) => <FilterOutlined className={`${filtered ? '!text-flexidocs-turquoise' : ''}`} />,
      sorter: (a, b) =>
        prop('version', a)
          .localeCompare(prop('version', b))
    },
    {
      title: 'Libellé',
      defaultSortOrder: 'ascend',
      ...baseTable('name', 360),
      ...getColumnSearchProps('name', 'Rechercher un libellé'),
      sorter: (a, b) =>
        prop('name', a)
          .toLowerCase()
          .localeCompare(prop('name', b).toLowerCase()),
      render: (name, record) => hasComparisonInProgress ? <span className="cursor-not-allowed">{name}</span> : <Link to={`/trames/${prop('id', record)}/informations/`}>{name}</Link>
    },
    {
      title: 'Tag lié',
      ...baseTable('bundleTag', 300),
      ...getColumnSearchProps('bundleTag', 'Rechercher un tag'),
      sorter: (a, b) => prop('bundleTag', a).localeCompare(prop('bundleTag', b)),
      render: renderValueOnTable
    },
    {
      title: 'Dates',
      ...baseTable('', 200),
      sorter: (first, second) => {
        const getLastDate = compose(
          moment,
          prop('when'),
          last,
          sortBy(compose(Date.parse, prop('when'))),
          prop('comparisons')
        );

        const firstLastDate = getLastDate(first);
        const secondLastDate = getLastDate(second);

        return firstLastDate.diff(secondLastDate);
      },
      render: ({ isReferent, dates, comparisons }) => {
        const lastDateComparison = compose(
          prop('when'),
          last,
          sortBy(compose(Date.parse, prop('when')))
        )(comparisons);
        return (
          <div className="flex flex-col">
            {renderValueOnTable(renderDate(prop('lastUpdate', dates), `Date de ${isReferent ? 'référence' : 'création'}`))}
            {renderValueOnTable(renderDate(lastDateComparison, 'Date de comparaison', <SwapOutlined className="!text-flexidocs-turquoise" />))}
          </div>
        );
      }
    },
    {
      title: 'Commentaire',
      ...baseTable('comment', 270),
      render: (comment, frame) => {
        const frameId = prop('id', frame);
        return (
          <FrameCommentInput
            comment={comment}
            frameId={frameId}
            handleCommentChange={handleCommentChange}
          />
        );
      }
    },
    {
      title: '',
      ...baseTable('', 50),
      render: ({ id, comparisons }) => (
        <FrameDropdown
          hasComparisonInProgress={hasComparisonInProgress}
          setInfosFrameId={setInfosFrameId}
          comparisons={comparisons}
          id={id}
        />
      )
    }
  ];

  const columnTitle = (
    <Checkbox
      indeterminate={isIndeterminate}
      onChange={handleCheckAll}
      checked={areAllChecked}
    />
  );

  return (
    <Table
      columns={columns}
      dataSource={offer}
      rowKey="id"
      pagination={pagination}
      onChange={handleOnChangeTable}
      rowSelection={{
        hideSelectAll: true,
        selectedRowKeys: selected,
        onChange: (selectedRowKeys) => {
          const idsFramesOffer = map(prop('id'))(currentDataSourceRef.current);
          const idToAdd = filter((id) => !includes(id, selected))(selectedRowKeys);
          const idsToKeep = filter((id) => !includes(id, idsFramesOffer) || includes(id, selectedRowKeys))(selected);
          const selectedIds = [...idsToKeep, ...idToAdd];
          setSelected(selectedIds);
        },
        getCheckboxProps: ({ isReferent, name }) => ({
          disabled: !isReferent,
          name
        }),
        columnTitle
      }}
    />
  );
};

const FramesList = ({
  frames,
  onCompare,
  loadingOnCompare,
  loadingOnCompareOnRow,
  setRefetch
}) => {
  const [selected, setSelected] = useState([]);
  const { onDelete, changeReferentDocumentOnFrame } = useFrame({});
  const hasComparisonInProgress = loadingOnCompare || loadingOnCompareOnRow;
  const [frameId, setInfosFrameId] = useState(null);
  const [keyOfferSelected, setKeyOfferSelected] = useState(defaultTo(0)(sessionStorage.getItem('frameSelectedOffer')));
  const [actionSelected, setActionSelected] = useState(null);

  useEffect(() => {
    if (actionSelected) {
      setActionSelected(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const framesByOffer = frames.reduce((acc, pdf) => {
    const offerName = pathOr('', ['offerName'], pdf);
    const index = findIndex(pathEq([0, 'offerName'], offerName))(acc);
    if (index === -1) {
      acc.push([pdf]);
    } else {
      acc[index].push(pdf);
    }
    return acc;
  }, []);

  const offerId = path([keyOfferSelected, 0, 'offerId'], framesByOffer);

  const getLastComparisonFromFrame = () => {
    const infosFrameSelected = find(propEq('id', frameId))(frames);
    return last(prop('comparisons', infosFrameSelected));
  };

  const referentsOffer = isNotEmpty(framesByOffer) ? getReferentsOnList(defaultTo([])(framesByOffer[keyOfferSelected])) : [];

  const listComparisonsStatus = compose(
    pluck('globalStatusResult'),
    flatten,
    map((pdf) => last(prop('comparisons', pdf))),
    filter((pdf) => gt(length(prop('comparisons', pdf)), 0))
  )(referentsOffer);

  const hasFailedComparisons = any(equals('failed'))(listComparisonsStatus);

  const resetAction = () => {
    setSelected([]);
    setActionSelected(null);
  };

  const doAction = (action) => {
    setActionSelected(action);
    if (action === 'defineRef') {
      changeReferentDocumentOnFrame(selected, setRefetch);
    } else if (action === 'compare') {
      onCompare('selected', selected);
    } else if (action === 'delete') {
      return;
    }
    resetAction();
  };

  const onChangeOffer = (index) => {
    sessionStorage.setItem('frameSelectedOffer', index);
    setKeyOfferSelected(index);
  };

  return (
    <div>
      {isNotNil(frameId) && (
        <Modal
          width="90%"
          visible={isNotNil(frameId)}
          onCancel={() => setInfosFrameId(null)}
          footer={null}
        >
          <ComparisonDetails comparison={getLastComparisonFromFrame()}/>
        </Modal>
      )}
      {isNotEmpty(framesByOffer) ? (
        <>
          {length(framesByOffer) > 1 && (
            <>
              <p className="!mb-1">Choisir une offre</p>
              <Select
                placeholder="Sélectionner une offre"
                onChange={onChangeOffer}
                value={path([keyOfferSelected, 0, 'offerName'], framesByOffer)}
                size="small"
                className="w-1/5"
                disabled={hasComparisonInProgress}
                suffixIcon={<DownOutlined className="!text-flexidocs-turquoise"/>}
              >
                {framesByOffer.map((offer, id) => (
                  <Select.Option key={id} value={id}>{path([0, 'offerName'], offer)}</Select.Option>
                ))}
              </Select>
            </>
          )}
          <div className="info-pdf">
            <Section
              className="mt-8"
              title={path([0, 'offerName'], framesByOffer[keyOfferSelected])}
              optionTitle={(
                isNotNilOrEmpty(referentsOffer) && isNotNilOrEmpty(listComparisonsStatus)) && (
                <StatusComparison
                  status={hasFailedComparisons ? 'failed' : 'passed'}
                  tooltip={
                    hasFailedComparisons
                      ? 'Au moins une des dernières comparaisons a échouée.'
                      : 'Les comparaisons sont conformes.'
                  }
                />
              )}
              actions={(
                <div className="flex flex-col">
                  <p className="!mb-1">Actions</p>
                  <Popconfirm
                    visible={equals(actionSelected, 'delete')}
                    placement="top"
                    title={`Êtes-vous sûr de vouloir supprimer ce${length(selected) > 1 ? 's' : 'tte'} trame${length(selected) > 1 ? 's' : ''} ?`}
                    onConfirm={() => {
                      onDelete(selected, setRefetch);
                      resetAction();
                    }}
                    okText="Oui"
                    cancelText="Non"
                    onCancel={() => setActionSelected(null)}
                  >
                    <Select
                      placeholder="Choisir une action à réaliser"
                      defaultValue={null}
                      onChange={doAction}
                      value={actionSelected}
                      size="small"
                      className="w-60"
                      disabled={isEmpty(selected) || hasComparisonInProgress}
                      loading={hasComparisonInProgress}
                      options={[
                        { value: 'defineRef', label: 'Définir en tant que référent' },
                        { value: 'compare', label: 'Comparer' },
                        { value: 'delete', label: 'Supprimer' }
                      ]}
                      suffixIcon={<DownOutlined className={classNames({ '!text-flexidocs-turquoise': !(isEmpty(selected) || hasComparisonInProgress) })}/>}
                    />
                  </Popconfirm>
                </div>
              )}
            >
              <TableList
                selected={selected}
                setSelected={setSelected}
                setRefetch={setRefetch}
                hasComparisonInProgress={hasComparisonInProgress}
                offer={framesByOffer[keyOfferSelected]}
                setInfosFrameId={setInfosFrameId}
                offerId={offerId}
              />
            </Section>
          </div>
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default FramesList;
