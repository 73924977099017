import { isNil } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateReviewCommentsMutation($versionId: ID!, $comments: String!) {
    updateReviewComments(versionId: $versionId, comments: $comments) {
      ok
      error
    }
  }
`;

export default ({ versionId, comments }, done) => {
  const variables = {
    versionId,
    comments
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { updateReviewComments }) => {
        const { ok, error } = updateReviewComments;

        if (ok && !error) {
          const reviewComments = proxyStore.get(`reviewComment-${versionId}`);

          if (!isNil(reviewComments)) {
            reviewComments.setValue(comments, 'comments');
          }
        }
      },
      onCompleted: ({ updateReviewComments }) => basicMutationOnCompleted(updateReviewComments, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
