import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateGlobalVariableMutation($globalVariableId: ID!, $globalVariable: GlobalVariableInput!) {
    updateGlobalVariable(globalVariableId: $globalVariableId, globalVariable: $globalVariable) {
      ok
      error
    }
  }
`;

export default ({
  globalVariableId,
  globalVariable
}, done) => {
  const variables = {
    globalVariableId,
    globalVariable
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateGlobalVariable }) => basicMutationOnCompleted(updateGlobalVariable, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
