import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QGlobalVariableQuery = graphql`
  query QGlobalVariableQuery($globalVariableId: ID!) {
    globalVariable(globalVariableId: $globalVariableId) {
      id
      name
      type
      scope
      offerId
      isUsed
      teamId
    }
  }
`;

const QGlobalVariable = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QGlobalVariableQuery}
    caching={false}
  >
    {populateChildren('globalVariable')(children, childDataProp)}
  </Query>
);

export default QGlobalVariable;
