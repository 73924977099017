import { compose, propOr, when, isNil, always, find, propEq } from 'ramda';

const statusList = [{
  status: 'draft',
  label: 'En édition'
}, {
  status: 'release',
  label: 'Publiée'
}, {
  status: 'review',
  label: 'En revue'
}, {
  status: 'archived',
  label: 'Archivée'
}];

const getStatusLabel = status => compose(
  propOr(status, 'label'),
  when(isNil, always({})),
  find(propEq('status', status))
)(statusList);

export {
  statusList,
  getStatusLabel
};
