import React from 'react';
import { useParams } from 'react-router-dom';
import QTag from '../../_graphql/queries/documents/QTag';
import { SCOPE_OFFER } from '../../_CONST';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';
import TagForm from './components/tag/TagForm';

const PreviewTagDocument = ({ userTeam }) => {
  const { offerId, tagId } = useParams();
  return (
    <QTag args={{ tagId }}>
      {tag => {
        const accessDenied = verifyRouteAccess(userTeam, tag, 'ce tag');
        if (accessDenied) return accessDenied;
        return (
          <TagForm
            scope={SCOPE_OFFER}
            offerId={offerId}
            tagId={tagId}
            tag={tag}
            preview
          />
        );
      }
      }
    </QTag>
  )
  ;
};

export default withUser(PreviewTagDocument);
