import React from 'react';

const BgFillIcon = () => (
  <svg
    version="1.1" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>8DDBE6CC-C3B1-4536-B44B-DEF53E398526</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-989 -618)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 347)">
          <g transform="translate(35 254)">
            <g transform="translate(345)">
              <g transform="translate(285)">
                <g transform="translate(35 17)">
                  <path d="m15.706 6.7812-6.5157-6.4884c-0.1875-0.1875-0.41563-0.29284-0.68126-0.29284-0.25391 0-0.5125 0.097531-0.70407 0.29284l-2.7272 2.7228-2.8003-2.7966c-0.29247-0.29209-0.76626-0.29191-1.0584 3.5938e-4 -0.29228 0.29228-0.29228 0.76625 0 1.0584l2.7994 2.8003-3.1397 3.1406c-1.1719 1.1681-1.1719 3.0703 0 4.2438l3.6594 3.6594c0.58657 0.58438 1.3553 0.87812 2.121 0.87812 0.76938 0 1.5353-0.29297 2.1213-0.87875l6.9251-6.925c0.39125-0.39312 0.39125-1.0244 6.25e-4 -1.415zm-2.925 2.2188h-11.238c0.062501-0.26953 0.19531-0.51938 0.39438-0.7225l3.1407-3.1369 2.1838 2.1838c0.29297 0.29297 0.76938 0.29297 1.0625 0 0.29313-0.29297 0.29297-0.76938 0-1.0625l-2.1838-2.1838 2.3672-2.3712 5.7844 5.7844-1.511 1.5088zm3.6375 1.6375c-0.1971-0.30803-0.64313-0.30803-0.84032 0-0.58469 0.91562-1.5785 2.5844-1.5785 3.3625 0 1.1062 0.89376 2 2 2s2-0.89375 2-1.9991c0-0.77906-0.99376-2.4478-1.5813-3.3634z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BgFillIcon;
