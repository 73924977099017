import { graphql, requestSubscription } from 'react-relay';
import { pick, map, defaultTo } from 'ramda';

export const FinishedComparisonsSubscriptionQuery = graphql`
  subscription FinishedComparisonsSubscription {
    finishedComparisonsSubscription {
      frameId
      status
      comparisonId
      comparisonsDone
      errorCount
      failedCount
      passedCount
      comparisonDetails {
        id
        diffPngsIds
        status
        referentPdfId
        actualPdfId
        fileName
      }
    }
  }
`;

export default (environment) => {
  return requestSubscription(
    environment,
    {
      subscription: FinishedComparisonsSubscriptionQuery,
      variables: {},
      updater: (
        store,
        { finishedComparisonsSubscription }
      ) => {
        const { frameId, status, comparisonId, comparisonsDone, comparisonDetails, errorCount, failedCount, passedCount } = pick(['status', 'frameId', 'comparisonId', 'comparisonDetails', 'errorCount', 'failedCount', 'passedCount'], finishedComparisonsSubscription);
        const document = store.get(frameId);
        const comparisons = document.getLinkedRecords('comparisons') || [];
        const record = store.get(comparisonId);

        if (record) {
          const filtered = map(c => {
            const dataId = c.getDataID();
            if (dataId === comparisonId) {
              c.setValue(status, 'globalStatusResult');
              c.setValue(comparisonsDone, 'comparisonsDone');
              const comparisonDetailsRecords = defaultTo([], comparisonDetails).map((cd) => {
                const comparisonDetailsRecord = store.get(cd.id);
                comparisonDetailsRecord.setValue(cd.status, 'status');
                comparisonDetailsRecord.setValue(cd.errorCount, 'errorCount');
                comparisonDetailsRecord.setValue(cd.failedCount, 'failedCount');
                comparisonDetailsRecord.setValue(cd.passedCount, 'passedCount');
                comparisonDetailsRecord.setValue(cd.diffPngsIds, 'diffPngsIds');
                comparisonDetailsRecord.setValue(cd.referentPdfId, 'referentPdfId');
                comparisonDetailsRecord.setValue(cd.actualPdfId, 'actualPdfId');
                comparisonDetailsRecord.setValue(cd.fileName, 'fileName');
                return comparisonDetailsRecord;
              });
              c.setLinkedRecords(comparisonDetailsRecords, 'comparisonDetails');
            }
            return c;
          }, comparisons);
          document.setLinkedRecords(filtered, 'comparisons');
        } else {
          const comparison = store.create(comparisonId, 'ComparisonGeneratePdfResult');
          comparison.setValue(comparisonId, 'id');
          comparison.setValue((new Date()).toString(), 'when');
          comparison.setValue(status, 'globalStatusResult');
          comparison.setValue(errorCount, 'errorCount');
          comparison.setValue(failedCount, 'failedCount');
          comparison.setValue(passedCount, 'passedCount');
          comparison.setValue(comparisonsDone, 'comparisonsDone');
          document.setLinkedRecords([comparison, ...comparisons], 'comparisons');
        }
      },
      onCompleted: () => {},
      onError: error => {
        console.error('SUBSCRIPTION FINISHED COMPARISONS ERROR:', error.message);
      }
    }
  );
};
