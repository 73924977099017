import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../.';
import { populateChildren } from './toolbox';

const QOffersQuery = graphql`
  query QOffersQuery($filter: OffersFilterInput) {
    offers(filter: $filter) {
      id
      offerName
      offerId
      dates {
        creation
        lastUpdate
      } 
      version {
        major
        minor
        status
        itemId
        itemRootId
        versionId
      }
    }
  }
`;

const QOffers = ({
  children,
  childDataProp,
  args
}) => (
  <Query
    query={QOffersQuery}
    caching={false}
    args={args}
  >
    {populateChildren('offers')(children, childDataProp)}
  </Query>
);

QOffers.defaultProps = { args: { filter: null } };

export default QOffers;
