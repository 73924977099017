import { both, propEq, prop, propSatisfies, compose, equals, join } from 'ramda';

export const getElement = (itemKey, itemPath, comment) => both(
  propEq('itemKey', prop('value', itemKey)),
  propSatisfies(
    compose(
      equals(prop('value', itemPath)),
      join(',')
    ),
    'itemPath'
  )
)(comment);
