import React, { useContext } from 'react';
import { propEq, compose, reject, isNil, defaultTo } from 'ramda';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import QStyles from '../../../_graphql/queries/documents/QStyles';
import { Title, Actions } from '../../../components';
import VersionsTree from '../../../components/VersionTree/VersionsTree';
import { associateVersions } from '../../../lib/versions';
import withUser, { UserContext } from '../../../withUser';

const Styles = () => {
  const history = useHistory();
  const { userTeam } = useContext(UserContext);
  const isAdmin = propEq('role', 'admin')(userTeam);

  return (
    <>
      <Actions
        title={<Title title="Les styles de mon équipe" backTo="/equipe" />}
        buttons={(
          <Button
            onClick={() => history.push('/equipe/elements/styles/creer')}
            size="large"
            type="primary"
          >
            Créer un style
          </Button>
        )}
      />

      <div className="mt-6">
        <QStyles args={{ scope: 'global' }}>
          {styles => {
            styles = compose(
              reject(propEq('predefined', true)),
              reject(isNil),
              defaultTo([])
            )(styles);

            return (
              <>
                <VersionsTree
                  items={associateVersions(styles, 'version.itemRootId')}
                  editLinkPattern="/equipe/elements/styles/:itemId/edition"
                  suppressionLabel="Voulez-vous vraiment supprimer ce style ?"
                  archiveLabel="Voulez-vous vraiment archiver ce style ?"
                  userCanEdit={isAdmin}
                />
              </>
            );
          }}
        </QStyles>
      </div>
    </>
  );
};

export default withUser(Styles);
