import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteWidgetsMutation($widgetsIds: [ID]!) {
    deleteWidgets(widgetsIds: $widgetsIds) {
      ok
      error
    }
  }
`;

export default ({
  widgetsIds
}, done) => {
  const variables = { widgetsIds };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteWidgets: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, widgetsIds),
      onCompleted: ({ deleteWidgets }) => basicMutationOnCompleted(deleteWidgets, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
