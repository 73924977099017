import React from 'react';
import { useParams } from 'react-router-dom';
import { propOr } from 'ramda';
import QWidget from '../../_graphql/queries/documents/QWidget';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';
import WidgetForm from './components/widget/WidgetForm';

const EditWidget = ({ userTeam }) => {
  const { widgetId } = useParams();

  return (
    <QWidget args={{ widgetId }}>
      {widget => {
        const accessDenied = verifyRouteAccess(userTeam, widget, 'ce widget');
        if (accessDenied) return accessDenied;

        return (
          <WidgetForm
            widget={widget}
            scope={propOr('offer', 'scope')(widget)}
            offerId={widget.offerId}
          />
        );
      }}

    </QWidget>
  );
};

export default withUser(EditWidget);
