import { always, compose, defaultTo, filter, ifElse, path, prop, propEq } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

const useApiKey = (teams, userTeam) => {
  const filteredList = compose(
    filter(propEq('id', prop('teamId', userTeam))),
    defaultTo([])
  )(teams);
  const apiKeyUserTeam = prop('apiKey', userTeam);
  const teamApiKey = ifElse(
    isNotNilOrEmpty,
    always(apiKeyUserTeam),
    always(path([0, 'apiKey'])(filteredList))
  )(apiKeyUserTeam);

  return { teamApiKey };
};

export default useApiKey;
