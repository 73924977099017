import React from 'react';
import { EyeOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useDocumentContext } from '../../../../context/Document';

const EyeIcon = () => <EyeOutlined/>;
const SaveIcon = () => <ThunderboltOutlined/>;
const Tp = ({
  title,
  children
}) => <Tooltip placement="left" title={title}>{children}</Tooltip>;

const DocumentActions = ({ userIsAdmin }) => {
  const {
    isEdit,
    previewDocumentHtml,
    previewHtmlLoading,
    onSave,
    loading,
    nameIsDefined
  } = useDocumentContext();

  const titleSave = isEdit ? 'Sauvegarder le document' : 'Créer le document';
  return (
    <div className="preview-actions-container">
      <div className="preview-actions-content">
        <div className="preview-actions-btn">
          <Tp title="Prévisualiser">
            <Button
              onClick={previewDocumentHtml}
              loading={previewHtmlLoading}
              icon={<EyeIcon/>}
            >
              Prévisualiser
            </Button>
          </Tp>
        </div>
        {userIsAdmin && (
          <div className="preview-actions-btn">
            <Tp title={titleSave}>
              <Button
                type="primary"
                onClick={onSave}
                disabled={!nameIsDefined}
                loading={loading}
                icon={<SaveIcon/>}
              >
                {titleSave}
              </Button>
            </Tp>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentActions;
