import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import SignOutMutation from '../../_graphql/mutations/SignOutMutation';

const SignOut = () => {
  const history = useHistory();
  const onSignOut = () => {
    SignOutMutation(() => {
      history.push('/account/signin');
    });
  };
  return (
    <Button
      onClick={onSignOut}
    >
      Me déconnecter
    </Button>
  );
};
export default SignOut;
