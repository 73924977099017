import { graphql,
  requestSubscription } from 'react-relay';

export const FinishedRemoveOfferSubscriptionQuery = graphql`
  subscription FinishedRemoveOfferSubscription {
    finishedRemoveOfferSubscription {
      id
      step
      documents
      rawDocuments
      widgets
      tags
      globalVariables
      frames
    }
  }
`;

export default (environment) => {
  return requestSubscription(
    environment,
    {
      subscription: FinishedRemoveOfferSubscriptionQuery,
      variables: {},
      updater: (
        store,
        { finishedRemoveOfferSubscription }
      ) => {
        const { id, step, documents, rawDocuments, widgets, tags, globalVariables, frames } = finishedRemoveOfferSubscription;
        let record = store.get(id);

        if (!record) {
          record = store.create(id, 'removeOffer');
        }

        record.setValue(step, 'step');
        record.setValue(documents, 'documents');
        record.setValue(rawDocuments, 'rawDocuments');
        record.setValue(widgets, 'widgets');
        record.setValue(tags, 'tags');
        record.setValue(globalVariables, 'globalVariables');
        record.setValue(frames, 'frames');
      },
      onCompleted: () => {},
      onError: error => {
        console.error('SUBSCRIPTION FINISHED REMOVE OFFER ERROR:', error.message);
      }
    }
  );
};
