import { forEach, isNil, prop } from 'ramda';
import attributesNames from '../data/attributesNames';
import getElements from './getElements';
import getComment from './getComment';

const resetElements = (itemRootId, comments) => {
  const elements = getElements(itemRootId);

  forEach(element => {
    const comment = getComment(element, comments);

    if (isNil(comment)) {
      element.style.backgroundColor = null;
    }

    const itemProcessedAttribute = document.createAttribute(prop('itemProcessed', attributesNames));
    itemProcessedAttribute.value = 'false';

    prop('attributes', element).setNamedItem(itemProcessedAttribute);
  })(elements);
};

export default resetElements;
