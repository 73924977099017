import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation RemoveGroupMutation($groupId: ID!) {
    removeGroup(groupId: $groupId) {
      ok
      error
    }
  }
`;

export default ({ groupId }, done) => {
  const variables = { groupId };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { removeGroup: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, groupId),
      onCompleted: ({ removeGroup }) => {
        const { ok, error, id } = removeGroup;
        done(ok, error, id);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
