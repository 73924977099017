import React from 'react';
import { omit, isNil } from 'ramda';

const Button = (props) => {
  const {
    children,
    className = '',
    size = 'default',
    type = 'default',
    icon,
    disabled
  } = props;

  props = omit([
    'children',
    'className',
    'loading',
    'type',
    'icon'
  ])(props);

  let bgColor = 'bg-flexibranche-lightblue';
  let cursor = 'cursor-pointer';

  if (type === 'error') {
    bgColor = 'bg-red-500';
  } else if (type === 'success') {
    bgColor = 'bg-green-400';
  } else if (type === 'simple') {
    bgColor = 'bg-gray-300';
  }

  if (disabled) {
    bgColor = 'bg-gray-400';
    cursor = 'cursor-default';
  }

  let builtClassName = 'text-white p-3 rounded-lg text-sm';

  if (size === 'large') {
    builtClassName = 'text-white p-4 rounded-lg text-lg font-bold';
  }

  if (size === 'small') {
    builtClassName = 'text-white p-2 rounded-lg text-xs';
  }

  let iconElement;

  if (!isNil(icon)) {
    iconElement = React.cloneElement(icon, {
      className: 'mr-5px align-text-top',
      style: { verticalAlign: 'text-top' }
    });
  }

  return (
    <button
      className={`border-none ${cursor} ${bgColor} ${builtClassName} ${className}`}
      {...props}
    >
      {iconElement}
      {children}
    </button>
  );
};

export default Button;
