import { Alert } from 'antd';
import React from 'react';

const DocumentAlerts = ({ pagesNumberGoesBeyonds, textPagesGoesBeyonds, widthRowGoesBeyondsPage }) => {
  return (
    <Alert
      type="warning"
      showIcon
      closable
      description={`Les widgets dépassent ${
        pagesNumberGoesBeyonds.length > 1
          ? 'des pages'
          : 'de la page'
      } ${textPagesGoesBeyonds}. ${
        !widthRowGoesBeyondsPage
          ? 'Acceptez le saut de page automatique, définissez un saut de page manuel entre 2 widgets ou mettez à jour la page pour y remédier.'
          : 'Vérifiez les lignes où il y a plusieurs widgets pour y remédier.'
      }`}
    />
  );
};

export default DocumentAlerts;
