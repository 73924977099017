import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateColorMutation($colorId: ID!, $color: ColorInput!) {
    updateColor(colorId: $colorId, color: $color) {
      ok
      error
    }
  }
`;

export default ({
  colorId,
  color
}, done) => {
  const variables = {
    colorId,
    color
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateColor }) => basicMutationOnCompleted(updateColor, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
