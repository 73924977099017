import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateTagMutation($tagId: ID!, $tag: TagInput!) {
    updateTag(tagId: $tagId, tag: $tag) {
      ok
      error
    }
  }
`;

export default ({
  tagId,
  tag
}, done) => {
  const variables = {
    tagId,
    tag
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateTag }) => basicMutationOnCompleted(updateTag, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
