import React from 'react';
import styled from 'styled-components';
import { either, isNil, prop, isEmpty } from 'ramda';
import withUser from '../../withUser';
import { TeamTop } from '../../pages/team/components';

const StyledTop = styled.div`
  width: 100%;
  height: 66px;
  background-color : white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 0;
  box-shadow: 2px 1px 8px 0 rgba(0, 0, 0, 0.08);
`;

const Top = ({
  user,
  onSignOut
}) => {
  if (either(isNil, isEmpty)(user) || isNil(prop('email', user))) {
    return null;
  }

  return (
    <StyledTop>
      <TeamTop/>

      <div className="flex flex-1 justify-end items-center font-bold">
        {user.email}

        <div
          className="rounded-2xl h-10 w-10 flex items-center justify-center ml-4 cursor-pointer bg-gray-100"
          onClick={onSignOut}
        >
          <img
            className="icon-signout"
            src="/icon/icon-arrow-right-from-bracket.svg"
          />
        </div>
      </div>
    </StyledTop>
  );
};

export default withUser(Top);
