import { append, isNil, prop } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation AddCommentMessageMutation($commentId: ID!, $message: String!) {
    addCommentMessage(commentId: $commentId, message: $message) {
      ok
      error
      message {
        text
        userId
        userName
        when
      }
    }
  }
`;

export default ({
  commentId,
  message
}, done) => {
  const variables = {
    commentId,
    message
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { addCommentMessage: { ok, error, message } }) => {
        if (ok && !error && !isNil(message)) {
          const storedComment = proxyStore.get(commentId);

          if (!isNil(storedComment)) {
            let messages = storedComment.getLinkedRecords('messages');

            const newMessage = proxyStore.create(prop('when', message), 'CommentMessage');

            newMessage.setValue(prop('when', message), 'when');
            newMessage.setValue(prop('userId', message), 'userId');
            newMessage.setValue(prop('userName', message), 'userName');
            newMessage.setValue(prop('text', message), 'text');

            messages = append(newMessage, messages);

            storedComment.setLinkedRecords(messages, 'messages');
          }
        }
      },
      onCompleted: ({ addCommentMessage }) => basicMutationOnCompleted(addCommentMessage, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
