import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import { compose, equals, find, flatten, includes, isEmpty, length, map, path, prop, propEq, uniq } from 'ramda';
import { isNotEmpty, isNotNil } from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo } from 'react';
import Loading from '../Loading';
import { useTreeContext } from './treeContext';
import { hasParent, updateKeys } from './treeHelper';
import TreeRow from './TreeRow';

const Trees = ({ ElementRow, setElements, setTreeProps, setRefetchWidgets, elements, userIsAdmin, isPublished = false }) => {
  const { trees, addTree, updateTree, getElement, args, expandedKeys, onExpand, isLoadingOnDelete, autoExpandParent, setCheckedKeys, checkedKeys, removeTree } = useTreeContext();

  useEffect(() => {
    if (setTreeProps) setTreeProps({ addTree, expandedKeys });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrop = useCallback(({ node, dragNode, dragNodesKeys }) => {
    if (propEq('allowAppendChildren', false, node) || propEq('allowDrag', false, dragNode)) return;

    const keysToMove = isNotEmpty(checkedKeys) ? checkedKeys : dragNodesKeys;
    const keyEndElement = prop('key', node);
    const elementEnd = getElement(node);
    const nodes = [];

    /**
     * If the key is included in the `keysToMove` array, the node is pushed
     * to the `nodes` array. If the node has children, the function is called recursively with the children
     * and a new key.
     */
    const loop = (data, key) => {
      for (let i = 0; i < data.length; i++) {
        const iKey = path([i, 'key'], data);
        const children = path([i, 'children'], data);
        if (includes(iKey, keysToMove)) {
          nodes.push(data[i]);
        }
        if (children) {
          const newKey = key ? `${key}-${i}` : `${i}`;
          loop(children, newKey);
        }
      }
    };
    map((tree) => loop(trees, prop('key', tree)))(trees);
    const dragNodeElements = compose(
      map(getElement),
      flatten,
      uniq
    )(nodes);

    const updatedKeys = updateKeys(expandedKeys, keysToMove, 'add');

    map((dragNodeElement) => {
      const isFolder = prop('isFolder', dragNodeElement);
      /**
       * adds a new node to the end of a specified parent element in
       * a tree structure. If the parent element is not specified, the node is added to the root of the tree.
       */
      const addNodeOnElementEnd = (parentId) => addTree(parentId, updatedKeys)({
        name: prop('name', dragNodeElement),
        element: {
          elmId: prop('elmId', dragNodeElement),
          type: prop('type', args)
        }
      });

      if (length(keyEndElement) < length(prop('key', dragNodeElement))) {
        if (!isFolder) {
          removeTree(prop('treeId', dragNodeElement), dragNodeElement, !isFolder);
        } else {
          updateTree(prop('treeId', dragNodeElement), updatedKeys, false)({ parent: null });
        }
        const elementEndIsFolder = prop('isFolder', elementEnd);
        if (elementEndIsFolder && prop('parent', dragNodeElement) !== prop('id', elementEnd)) {
          const parentIsDrag = find((node) => equals(prop('id', node), prop('parent', dragNodeElement)), dragNodeElements);
          const parentId = isNotNil(parentIsDrag) ? prop('parent', dragNodeElement) : prop('id', elementEnd);
          addNodeOnElementEnd(parentId);
        }
      } else if (hasParent(dragNodeElement) || isFolder) {
        updateTree(prop('treeId', dragNodeElement), updatedKeys, false)({ parent: prop('treeId', elementEnd) });
      } else if (prop('isFolder', elementEnd)) {
        addNodeOnElementEnd(prop('treeId', elementEnd));
      }
    })(dragNodeElements);
    setCheckedKeys([]);
  }, [addTree, args, expandedKeys, getElement, updateTree, removeTree, checkedKeys, trees, setCheckedKeys]);

  const treeData = useMemo(() => [...trees], [trees]);

  const mayBeEdited = userIsAdmin && !isPublished;
  const mayBeChecked = mayBeEdited && propEq('type', 'WIDGET', args);

  if (isLoadingOnDelete) return <Loading />;
  if (isEmpty(elements)) return <span>Aucune donnée.</span>;
  return (
    <Tree
      treeData={treeData}
      showLine={{ showLeafIcon: false }}
      selectable={false}
      showIcon
      onDrop={handleDrop}
      draggable={mayBeEdited}
      checkable={mayBeChecked}
      switcherIcon={<DownOutlined />}
      titleRender={(data, idx) => (
        <TreeRow
          key={idx} data={data} setElements={setElements}
          ElementRow={ElementRow} setRefetchWidgets={setRefetchWidgets}
        />
      )}
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={setCheckedKeys}
      checkedKeys={checkedKeys}
    />
  );
};

export default Trees;
