import React from 'react';
import { applySpec, propOr, prop, defaultTo, propSatisfies, isNil } from 'ramda';
import QElementReview from '../../_graphql/queries/reviews/QElementReview';
import Button from '../Button';
import SaveElementReviewMutation from '../../_graphql/mutations/reviews/SaveElementReviewMutation';
import { errorMessage, successMessage } from '../../utils/messageMutation';

const useElementReviewButtons = ({ itemRootId, itemId, elementReview, itemType }) => {
  const {
    status,
    groupId,
    elementReviewId
  } = applySpec({
    status: propOr('pending', 'status'),
    groupId: prop('groupId'),
    elementReviewId: prop('id')
  })(elementReview);

  const onUpdateStatus = status => () => {
    SaveElementReviewMutation({
      elementReviewId, itemRootId, itemId, groupId, status, itemType
    }, (ok, error) => {
      if (ok && !error) {
        successMessage('statut de validation', 'mis à jour');
      } else {
        errorMessage();
      }
    });
  };

  return {
    status,
    onUpdateStatus
  };
};

const ElementReviewButtons = ({ itemRootId, itemId, elementReview, itemType }) => {
  const {
    status,
    onUpdateStatus
  } = useElementReviewButtons({
    itemRootId, itemId, elementReview, itemType
  });

  return (
    <div className="items-center flex space-x-1 justify-center mb-2">
      {status !== 'accepted' && (
        <Button
          type="success"
          onClick={onUpdateStatus('accepted')}
        >
          Valider
        </Button>
      )}

      {status !== 'pending' && (
        <Button
          onClick={onUpdateStatus('pending')}
        >
          En attente
        </Button>
      )}

      {status !== 'rejected' && (
        <Button
          type="error"
          onClick={onUpdateStatus('rejected')}
        >
          Rejeter
        </Button>
      )}
    </div>
  );
};

const ElementReviewButtonsWrapper = ({ itemRootId, itemId, itemType }) => {
  return (
    <QElementReview
      args={{
        itemRootId, itemId, itemType
      }}
    >
      {elementReview => {
        elementReview = defaultTo({ status: 'pending' }, elementReview);

        if (propSatisfies(isNil, 'groupId')(elementReview)) {
          return null;
        }

        return (
          <ElementReviewButtons
            elementReview={elementReview}
            itemRootId={itemRootId}
            itemId={itemId}
            itemType={itemType}
          />
        );
      }}
    </QElementReview>
  );
};

export default ElementReviewButtonsWrapper;
