import React from 'react';

export const defs = (
  <defs>
    <filter
      id="a" x="-11.5%" y="-128.9%"
      width="123%" height="357.8%"
    >
      <feOffset
        dx="0" dy="10" in="SourceAlpha"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10" />
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.396078431   0 0 0 0 0.633673985   0 0 0 0 0.839215686  0 0 0 0.2 0" />
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
);
