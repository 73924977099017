import React, { useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd';
import { equals, filter, length, prop, propEq } from 'ramda';
import { EyeOutlined, FileSyncOutlined } from '@ant-design/icons';
import { renderDate } from '../docs/components/helpers/dates';
import { Section } from '../../components';
import colors from '../../theme';
import useCompare from '../../hooks/useCompare';
import { sortDateDESC } from '../helpers/sortDate';
import usePagination from '../../hooks/usePagination';
import ComparisonDetails from './ComparisonDetails';
import StatusComparison from './StatusComparison';

const HistoryComparisonsTable = ({ values }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idComparison, setIdComparison] = useState(null);
  const { loadingOnCompareOnSelected: loadingOnCompare, onCompare } = useCompare();
  const { comparisons, isReferent, id } = values;
  const listComparisons = sortDateDESC(comparisons);
  const { onChangePagination, pagination } = usePagination({ itemsNumber: length(comparisons), type: 'comparison' });

  return (
    <Section
      className="section-history-comparison shadow-none mt-8"
      title="Historique des comparaisons"
      actions={(
        <Button
          type="primary"
          onClick={() => onCompare('selected', [id])}
          disabled={!isReferent || loadingOnCompare}
          loading={loadingOnCompare}
          icon={<FileSyncOutlined />}
          className="btn-compare-frame"
        >
          Comparer
        </Button>
      )}
    >
      <Table
        size="small"
        dataSource={listComparisons}
        rowKey="id"
        pagination={pagination}
        onChange={onChangePagination}
      >
        <Table.Column
          title="Date de comparaison"
          width={200}
          render={({ when, globalStatusResult }) => {
            const date = renderDate(when);
            return (
              <p>{equals('waiting', globalStatusResult) ? 'En cours...' : date}</p>
            );
          }}
        />

        <Table.Column
          title="Statut global"
          dataIndex="globalStatusResult"
          width={90}
          render={(globalStatusResult) => {
            return (
              <StatusComparison status={globalStatusResult} />
            );
          }}
        />

        <Table.Column
          title="Documents identiques"
          width={300}
          render={({ comparisonDetails, globalStatusResult }) => {
            const nbDocPassed = comparisonDetails && length(filter(propEq('status', 'passed'))(comparisonDetails));
            if (equals('waiting', globalStatusResult)) {
              return <p>-</p>;
            } else {
              return (
                <p>{nbDocPassed} / {length(comparisonDetails)}</p>
              );
            }
          }}
        />

        <Table.Column
          title=""
          width={300}
          render={({ globalStatusResult }, _record, index) => {
            if (equals('waiting', globalStatusResult)) return;
            return (
              <div className="flex justify-end gap-2 text-lg">
                <Tooltip
                  title="Voir la comparaison"
                  color={prop('primaryColorLight', colors)}
                >
                  <EyeOutlined
                    onClick={() => {
                      setIsOpenModal(true);
                      setIdComparison(index);
                    }}
                    className="btn-get-comparison grey"
                  />
                </Tooltip>
              </div>
            );
          }}
        />
      </Table>
      {isOpenModal && (
        <Modal
          width="90%"
          visible={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          footer={null}
        >
          <ComparisonDetails comparison={listComparisons[idComparison]}/>
        </Modal>
      )}
    </Section>
  );
};

export default HistoryComparisonsTable;
