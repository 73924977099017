import React, { useState } from 'react';
import { Input, Select, Button, Radio } from 'antd';
import { addIndex, map, compose, propOr, defaultTo, find, propEq, prop, cond, always, T, uniqBy, filter, allPass, not, applySpec, flip, contains, assocPath, dissocPath, nth, drop, assoc, over, lensProp, append, when } from 'ramda';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import { isNotNil, isNotEmpty } from 'ramda-adjunct';
import conditions from './conditions';
import actions from './actions';
import operators from './operators';

const mapIndexed = addIndex(map);

const conditionIsWithValue = condition => compose(
  propOr(false, 'withValue'),
  defaultTo({}),
  find(propEq('key', condition))
)(conditions);

const CustomSelect = ({
  placeholder,
  value,
  onChange,
  options,
  disabled
}) => {
  return (
    <Select
      showSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      size="small"
      className="w-3/4"
      disabled={disabled}
    >
      {map(({ key, label }) => {
        return (
          <Select.Option
            key={key}
            value={key}
          >
            {label}
          </Select.Option>
        );
      })(options)}
    </Select>
  );
};

const DisplayRadio = ({ value }) => map(({ key, label }) => {
  return (
    <Radio
      key={key}
      value={key}
    >
      <span className="font-semibold">{label}</span>
    </Radio>
  );
})(value);

const Condition = ({
  condition,
  conditionIndex,
  onUpdate,
  variables,
  preview,
  userIsAdmin,
  setIsOpenCreateGlobalVariableModal
}) => {
  const [value, setValue] = useState(prop('value', condition));
  const isFirst = conditionIndex === 0;
  const conditionVariable = prop('variable', condition);
  const variable = find(propEq('key', conditionVariable), variables);
  const conditionsOptions = when(
    () => isNotNil(conditionVariable),
    cond([
      [propEq('type', 'number'), always(conditions)],
      [T, always(filter(
        allPass([
          compose(
            not,
            propEq('key', 'isGreaterThan')
          ),
          compose(
            not,
            propEq('key', 'isLessThan')
          )
        ]),
        conditions
      ))]
    ])
  )(variable);

  const PREDEFINED_VARIABLES = ['_pageCourante', '_pageTotal', '_pageCouranteSection', '_pageTotalSection'];

  const optionsWithoutDuplicateKeys = compose(
    uniqBy(prop('key')),
    map(
      applySpec({
        key: prop('key'),
        label: prop('key')
      })
    ),
    filter(
      compose(not, flip(contains)(PREDEFINED_VARIABLES), prop('key'))
    )
  )(variables);
  const disableFields = preview || !userIsAdmin;

  return (
    <>
      {!isFirst && (
        <div className="flex items-center justify-between mt-2">
          <Radio.Group
            onChange={e => onUpdate(assocPath(['conditions', conditionIndex, 'operator'], e.target.value))}
            value={prop('operator', condition)}
            className="radio-widget-rule"
            disabled={disableFields}
          >
            <DisplayRadio value={operators} />
          </Radio.Group>
        </div>
      )}
      <div className="flex items-center justify-between my-2">
        {(isFirst || isNotNil(prop('operator', condition))) && (
          <>
            <CustomSelect
              placeholder="Sélectionner une variable"
              value={conditionVariable}
              onChange={v => onUpdate(assocPath(['conditions', conditionIndex, 'variable'], v))}
              options={optionsWithoutDuplicateKeys}
              disabled={disableFields}
            />
            {userIsAdmin && (
              <>
                <Tooltip
                  title="Créer une variable globale"
                  color="#0197DC"
                >
                  <PlusCircleOutlined
                    className="create-global-variable-button"
                    onClick={() => setIsOpenCreateGlobalVariableModal({ onUpdate, conditionIndex })}
                  />
                </Tooltip>
                <Tooltip
                  title="Supprimer la condition"
                  color="#0197DC"
                >
                  <button onClick={() => onUpdate(dissocPath(['conditions', conditionIndex]))} disabled={preview} >
                    <DeleteOutlined />
                  </button>
                </Tooltip>
              </>
            )}
          </>
        )}
      </div>

      {isNotNil(conditionVariable) && (
        <CustomSelect
          placeholder="Sélectionner une condition"
          value={prop('condition', condition)}
          onChange={v => onUpdate(assocPath(['conditions', conditionIndex, 'condition'], v))}
          options={conditionsOptions}
          disabled={disableFields}
        />
      )}

      {isNotNil(prop('condition', condition)) && conditionIsWithValue(condition.condition) && (
        <Input
          className="!w-3/4 mt-2"
          placeholder="Saisir la valeur"
          value={value}
          onChange={e => setValue(e.target.value)}
          size="small"
          disabled={disableFields}
          onBlur={(e) => onUpdate(assocPath(['conditions', conditionIndex, 'value'], e.target.value))}
        />
      )}
    </>
  );
};

const Rule = ({
  variables,
  onUpdate,
  onRemove,
  rule,
  ruleIndex,
  getConditionsAreValid,
  preview,
  userIsAdmin,
  setIsOpenCreateGlobalVariableModal
}) => {
  const conditions = propOr([], 'conditions', rule);
  const firstCondition = compose(
    defaultTo({}),
    nth(0)
  )(conditions);
  const extraConditions = drop(1, conditions);
  const canEdit = userIsAdmin && !preview;

  return (
    <div>
      <div className="flex items-center justify-between">
        <Radio.Group
          onChange={e => onUpdate(assoc('action', e.target.value))}
          value={prop('action', rule)}
          className="radio-widget-rule"
          disabled={!userIsAdmin || preview}
        >
          <DisplayRadio value={actions} />
        </Radio.Group>

        {canEdit && (
          <Tooltip
            title="Supprimer la règle"
            color="#0197DC"
          >
            <DeleteOutlined
              className="text-red-500"
              onClick={onRemove}
            />
          </Tooltip>
        )}
      </div>

      {isNotNil(prop('action', rule)) && (
        <Condition
          conditionIndex={0}
          condition={firstCondition}
          onUpdate={onUpdate}
          variables={variables}
          preview={preview}
          userIsAdmin={userIsAdmin}
          setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
        />
      )}

      {mapIndexed((condition, conditionIndex) => {
        return (
          <Condition
            key={`rule-${ruleIndex}-condition-${conditionIndex + 1}`}
            conditionIndex={conditionIndex + 1}
            condition={condition}
            onUpdate={onUpdate}
            variables={variables}
            preview={preview}
            userIsAdmin={userIsAdmin}
            setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
          />
        );
      })(extraConditions)}

      {isNotEmpty(conditions) && getConditionsAreValid(conditions) && canEdit && (
        <Button
          size="small"
          className="small-white-button mt-2"
          icon={<PlusCircleOutlined/>}
          onClick={() => onUpdate(over(
            lensProp('conditions'),
            append({})
          ))}
          disabled={!getConditionsAreValid(propOr([], 'conditions', rule))}
        >
          Ajouter une condition
        </Button>
      )}

    </div>
  );
};

const DocumentRules = ({
  variables,
  rules,
  rulesAreValid,
  getConditionsAreValid,
  onAdd,
  onRemove,
  onUpdate,
  preview,
  classNameButton,
  userIsAdmin,
  setIsOpenCreateGlobalVariableModal
}) => {
  const hasRightToAddRule = userIsAdmin && rulesAreValid && !preview;

  return (
    <div className="w-80">
      {isNotEmpty(rules) && (
        <div className="flex mt-2 mb-2">
          <div className="border-4 border-gray-300 rounded-sm mr-2.5"/>
          <div className="flex flex-col gap-y-4 w-full">
            {mapIndexed((rule, index) => (
              <Rule
                rule={rule}
                variables={variables}
                onUpdate={onUpdate(index)}
                onRemove={onRemove(index)}
                key={`rule${index}`}
                ruleIndex={index}
                getConditionsAreValid={getConditionsAreValid}
                preview={preview}
                userIsAdmin={userIsAdmin}
                setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
              />
            ))(rules)}
          </div>
        </div>
      )}

      {hasRightToAddRule && (
        <Button
          size="small"
          className={`add-rules-button small-white-button mt-2 ${classNameButton}`}
          disabled={!rulesAreValid}
          onClick={onAdd}
          icon={<PlusCircleOutlined/>}
        >
          Ajouter une règle
        </Button>
      )}
    </div>
  );
};

export default DocumentRules;
