import React from 'react';
import { useParams } from 'react-router-dom';
import { propOr } from 'ramda';
import { QOffer } from '../../_graphql/queries';
import { Section, Title } from '../../components';
import VersionHistory from '../../components/VersionHistory';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';

const OfferHistory = ({ userTeam }) => {
  const { offerId } = useParams();

  return (
    <QOffer args={{ offerId }}>
      {offer => {
        const accessDenied = verifyRouteAccess(userTeam, offer, 'l\'historique de cette offre');
        if (accessDenied) return accessDenied;
        return (
          <>
            <Title
              title="Historique de l'offre"
              backTo={`/offre/${offerId}/edition`}
            />
            <Section
              title={`« ${propOr('', 'offerName', offer)} »`}
            >
              <div className="timeline_container">
                <VersionHistory
                  itemId={offerId}
                />
              </div>
            </Section>
          </>
        );
      }}
    </QOffer>
  );
};

export default withUser(OfferHistory);
