import React from 'react';
import { Avatar, Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { propOr, compose, pathOr, toUpper, take, isNil, prop, path, propEq } from 'ramda';
import ManageRoleDropdown from './ManageRoleDropdown';
import RemoveUserFromTeam from './RemoveUserFromTeam';

const getEmail = propOr('', 'email');

const columns = ({
  onRemove,
  userIsAdmin,
  userId,
  userTeam
}) => [{
  title: 'Collaborateur',
  dataIndex: 'user',
  defaultSortOrder: 'ascend',
  width: 500,
  sorter: (a, b) => {
    const getEmail = pathOr('', ['user', 'email']);
    return getEmail(a).localeCompare(getEmail(b));
  },
  // eslint-disable-next-line react/display-name
  render: user => {
    const firstChar = compose(
      toUpper,
      take(1),
      getEmail
    )(user);

    return (
      <>
        <Avatar className="!mr-2">{firstChar}</Avatar>
        <span>{getEmail(user)}</span>
      </>
    );
  }
},
{
  title: 'Statut',
  dataIndex: 'dates',
  defaultSortOrder: 'descend',
  sorter: (a, b) => {
    const joinDate = date => path(['dates', 'join'], date);
    return joinDate(a).localeCompare(joinDate(b));
  },
  width: 600,
  // eslint-disable-next-line react/display-name
  render: dates => {
    const joinDate = prop('join', dates);
    if (isNil(joinDate)) return null;

    return (
      <span className="flex font-thin">
        <img src="/icon/icon-calendar-check.svg" alt="calendrier" className="mr-2" />
        Inscrit le {moment(joinDate).format('DD/MM/YYYY')}
      </span>
    );
  }
},
{
  title: 'Rôle',
  dataIndex: 'user',
  width: 400,
  // eslint-disable-next-line react/display-name
  render: user => (!userIsAdmin || propEq('id', userId, user)) ? null : <ManageRoleDropdown userTeam={userTeam} memberId={prop('id', user)} />
},
{
  title: '',
  dataIndex: 'user',
  className: 'delete-member',
  width: 50,
  // eslint-disable-next-line react/display-name
  render: user => (!userIsAdmin || propEq('id', userId, user)) ? null : (
    <RemoveUserFromTeam
      memberId={prop('id', user)} onRemove={onRemove}
    />
  )
}];

const MembersTable = ({
  members,
  userTeam,
  onRemoveUser,
  userIsAdmin,
  userId
}) => {
  return (
    <Table
      className="team-member-table"
      rowKey="id"
      columns={columns({
        onRemove: onRemoveUser,
        userIsAdmin,
        userId,
        userTeam
      })}
      dataSource={members}
      size="small"
      pagination={false}
    />
  );
};

MembersTable.propTypes = {
  members: PropTypes.array.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired
};

export default MembersTable;
