import React from 'react';
import { Section } from '../../../components';
import TeamInvite from './TeamInvite';

const Activity = () => (
  <Section title="Mes invitations">
    <TeamInvite />
  </Section>
);

export default Activity;
