import React from 'react';

const EditIcon = () => (
  <svg
    version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em"
  >
    <title>A50C231A-D6A5-48E0-ADFA-9AB96830A943</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1198 -978)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 800)">
          <g transform="translate(35 80)">
            <g transform="translate(874 90)">
              <g transform="translate(0 8)">
                <path d="m15.491 1.5384-1.0259-1.0256c-0.3432-0.34184-0.79018-0.51281-1.2403-0.51281-0.44791 0-0.89583 0.17091-1.2378 0.51281l-6.7515 6.7528c-0.26256 0.23438-0.39697 0.53125-0.47198 0.85312l-0.7533 3.3562c-0.056263 0.27812 0.15941 0.525 0.40009 0.525 0.028632 0 0.057888-0.0028687 0.087426-0.0087906 0 0 2.314-0.49094 3.357-0.73625 0.3157-0.074281 0.59795-0.23309 0.82706-0.46219l6.7828-6.7813c0.71088-0.6834 0.70776-1.7896 0.02635-2.4731zm-1.0596 1.4116-6.7828 6.7812c-0.034383 0.034375-0.068766 0.053125-0.1094 0.0625-0.42822 0.10084-1.0831 0.24553-1.6973 0.37938l0.3879-1.725c0.0090646-0.045 0.030945-0.08875 0.065327-0.12l6.7547-6.7531c0.059388-0.062188 0.12815-0.075 0.17504-0.075s0.11612 0.012694 0.1766 0.073188l1.0259 1.0259c0.10096 0.099 0.10096 0.25306 0.0040634 0.35088zm-1.1784 6.05c-0.41416 0-0.75017 0.33594-0.75017 0.75v4c0 0.41344-0.33695 0.75-0.75017 0.75h-9.5022c-0.41322 0-0.75017-0.33656-0.75017-0.75v-9.5c0-0.41344 0.33695-0.75 0.75017-0.75h4.501c0.41416 0 0.75017-0.33594 0.75017-0.75s-0.33445-0.75-0.75017-0.75l-4.5292-3.125e-4c-1.2125 0-2.2224 1.0094-2.2224 2.2222v9.5c0 1.2688 1.0099 2.2781 2.2224 2.2781h9.474c1.2406 0 2.2224-1.0094 2.2224-2.25l0.084394-4c0-0.4125-0.33445-0.75-0.75017-0.75z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EditIcon;
