import { filter, isNil } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../.';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
mutation RemoveUserFromTeamMutation($teamId: ID!, $userId: ID!) {
  removeUserFromTeam(teamId: $teamId, userId: $userId) {
    ok
    error
  }
}
`;

export default ({ teamId, userId }, done) => {
  const variables = {
    userId,
    teamId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { removeUserFromTeam: { ok } }) => {
        if (ok) {
          const team = proxyStore.get(teamId);
          const linkedMembers = team.getLinkedRecords('members');

          if (!isNil(linkedMembers)) {
            const members = filter(member => {
              const user = member.getLinkedRecord('user');
              const id = user.getValue('id');

              return id !== userId;
            })(linkedMembers);

            team.setLinkedRecords(members, 'members');
          }
        }
      },
      onCompleted: ({ removeUserFromTeam }) => basicMutationOnCompleted(removeUserFromTeam, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
